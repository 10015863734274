import {Suspense} from 'react';
import {ConfigProvider, Layout, Spin} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import "./common/style/vars.scss";
import './App.scss';
// import AppRouter from './router/index.bak';
import { RouterProvider } from 'react-router-dom';
import AppRouter from './router';
import Theme from './theme/themeConfig';

function App () {
  return (
    <ConfigProvider locale={zhCN} theme={Theme}>
      <Layout style={{height: '100vh', width: '100vw'}}>
         <Suspense fallback={<Spin />}>
          {/* @ts-ignore */}
          <RouterProvider router={AppRouter}>
          </RouterProvider>
         </Suspense>
      </Layout>
    </ConfigProvider>
  );
}

export default App;
