import {FormMode, isNewFormMode} from "@src/bizForm/formMode";
import {IAnyStateTreeNode} from "mobx-state-tree";
import {v4 as uuidv4} from "uuid";
const toString = Object.prototype.toString;

export const getNode = (target: IAnyStateTreeNode) => {
  return target.$treenode as any;
};

export const getNodeByPath = (target: IAnyStateTreeNode, path: string) => {
  if (!path) {
    return getNode(target);
  }
  const pathArr = path.split(".");
  let node = getNode(target);
  for (let i = 0; i < pathArr.length; i++) {
    if (toString.call(node) !== "[object Object]") {
      return undefined;
    }
    if (typeof node.getChildNode === "function") {
      node = node.getChildNode(pathArr[i]);
    } else {
      return undefined;
    }
  }

  return node;
};

// initState 对应key
export const $FORM_STATE_KEY = "$FORM_STATE_KEY";
// MST path分隔符
export const MST_PATH_DIVIDER = "/";

export const $FORM_VALIDATE_KEY = "$FORM_VALIDATE_KEY";

export const wrapItemWithId = (initValue?: Record<string, any>): Record<string, any> => {
  return {
    ...initValue,
    _id: uuidv4(),
  };
};

export const genItemFieldPath = (itemPath: string, _id: string, path: string) => `${itemPath}.${_id}.${path}`;

export const getItemEditFlag = (formMode: FormMode, id: string): FormMode => {
  if (isNewFormMode(formMode)) {
    return formMode
  }

  if (id) {
    return FormMode.View;
  }

  return FormMode.Create;
}