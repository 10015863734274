import IconFont from "@src/components/Iconfont";
import {Button, Dropdown, MenuProps} from "antd";
import React from "react";
import styles from "./style.module.scss";
import { BizAction } from "./ListRowActionBar";
import { BizFormItemPresenter } from "@src/bizForm/bizFormItemPresenter";

interface FormRowActionBarProps {
  itemPresenter: BizFormItemPresenter<any>;
  data: any;
}

export class FormRowActionBar extends React.Component<FormRowActionBarProps> {
  render() {
    const {itemPresenter, data} = this.props;
    const actions = itemPresenter?.config.formItemActionManager.execute(data);
    let displayActions = actions;
    let moreActions: MenuProps["items"] = [];
    if (actions?.length > 3) {
      displayActions = actions.slice(0, 3);
      moreActions = actions
        .slice(3)
        .map((action) => ({
          key: action.key,
          label: <BizAction key={action.key} presenter={itemPresenter} data={data} {...action} />,
        }));
    }
    return (
      <div className={styles["actionbar"]}>
        {displayActions?.map((action) => (
          <BizAction key={action.key} presenter={itemPresenter} data={data} {...action} />
        ))}
        {moreActions?.length ? (
          <Dropdown
            menu={{
              items: moreActions,
            }}
          >
            <Button type="link" size="small">
              <IconFont type="icon-more_light"></IconFont>
            </Button>
          </Dropdown>
        ) : null}
      </div>
    );
  }
}
