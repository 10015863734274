import * as monaco from "monaco-editor";

export const editorInsertContent = (editor: monaco.editor.IStandaloneCodeEditor, content: string) => {
  // editorView.dispatch({
  //   changes: {
  //     from: editorView.state.selection.ranges[0].from,
  //     // to: 1,
  //     insert: content,
  //   },
  //   //   selection: {anchor: 0},
  // });

  const p = editor.getPosition();
  editor.executeEdits("", [
    {
      range: new monaco.Range(p.lineNumber, p.column, p.lineNumber, p.column),
      text: content,
    },
  ]);
};
