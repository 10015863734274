import EN_Article from "@packages/gen/types/entities/Article";
import {IBizAction} from "@src/bizAction/interface";
import {BizListPresenter} from "@src/bizList/bizListPresenter";
import {BizListConfig} from "@src/bizList/interface";
import {ArticleFormPresenter} from "./articleFormPresenter";

export class ArticleListPresenter<T = EN_Article> extends BizListPresenter<T> {
  public bizFormPresenter = ArticleFormPresenter;

  public listBizConfig(config: BizListConfig<T>): BizListConfig<T> {
    config.column = {};

    config.listRowActionManager.use((actions: Array<IBizAction>, data: any): Array<IBizAction> => {
      // if (data.id === 'db56a548-b6f8-4bd4-8886-051dcde9858b') {
      //   return [];
      // }
      return actions;
    });
    return config;
  }
}
