import {Observer} from "mobx-react";
import {MutilSelectTable, SingleSelectTable} from "../SelectTable";
import {IMetadataReferOptions} from "@src/interfaces/metadata";

interface IRefer {
  entityName: string; // 业务对象名称
  objectTypeId?: string; // 查询 entityName 数据中objectTypeId的数据
  value: any; // 传入的值
  onChange: (value: any) => void; // 选择数据时触发
  // queryFields: Array<string>;
  referOptions: IMetadataReferOptions;
  className?: string;
  isMutil?: boolean; //多选
  id?: string;
  disabled?: boolean;
  onCreate?: () => void;
}

/**
 * 根据entityName自动查詢对应的数据
 * 系统里可以作为refer的数据都应该有id、title字段。ReferSelect组件读取对应的属性即可
 * refer只显示entity本身的字段，子表的字段不会显示
 * ReferSelect应作为受控组件使用
 * @param props
 * @returns
 */
function Refer(props: IRefer) {
  const { isMutil} = props;


  const onChange = (value: any) => {
    props?.onChange(value);
  };

  // const SelectTable = isMutil ? MutilSelectTable : SingleSelectTable;
  const SelectTable =  SingleSelectTable;

  return (
    <Observer>
      {() => (
        <SelectTable
          // loading={dataloader.loading}
          id={props.id}
          entityName={props.entityName}
          objectTypeId={props.objectTypeId}
          disabled={props.disabled}
          value={props.value}
          onChange={onChange}
          referOptions={props.referOptions}
          className={props.className}
          onCreate={props.onCreate}
        />
      )}
    </Observer>
  );
}

export default Refer;
