import {BizFormPresenter} from "@src/bizForm/bizFormPresenter";
import {FormMode} from "@src/bizForm/formMode";
import {BizListPresenter} from "@src/bizList/bizListPresenter";
import {RouteUtils} from "@src/router/routeUtils";

/**
 * 列表行使用
 */
export const CreateAction = {
  key: "create",
  label: "新增",
  options: {
    onClick: (presenter: BizListPresenter, data?: any) => {
      RouteUtils.toForm({
        entityName: presenter.entityName,
        objectTypeId: presenter.objectTypeId,
        mode: FormMode.Create,
      });
    },
  },
};
