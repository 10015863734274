
// InterviewQuestion 

// 题目ID 
export const F_InterviewQuestion_id = "id"; 

// 题目标题 
export const F_InterviewQuestion_name = "name"; 

// 业务类型 
export const F_InterviewQuestion_bizTypeId = "bizTypeId"; 

// 分类 
export const F_InterviewQuestion_categoryId = "categoryId"; 

// 难度 
export const F_InterviewQuestion_difficulty = "difficulty"; 

// 内容 
export const F_InterviewQuestion_content = "content"; 

// 答案 
export const F_InterviewQuestion_answer = "answer"; 

// 标签 
export const F_InterviewQuestion_tags = "tags"; 

// 是否公开 
export const F_InterviewQuestion_isPublic = "isPublic"; 

// 点赞次数 
export const F_InterviewQuestion_likeCount = "likeCount"; 

// 查看次数 
export const F_InterviewQuestion_viewCount = "viewCount"; 

// 是否删除 
export const F_InterviewQuestion_isDeleted = "isDeleted"; 

// 更新时间 
export const F_InterviewQuestion_updateTime = "updateTime"; 

// 创建时间 
export const F_InterviewQuestion_createTime = "createTime"; 

// 最近编辑人 
export const F_InterviewQuestion_operatorId = "operatorId"; 

// 创建者 
export const F_InterviewQuestion_creatorId = "creatorId"; 

// 租户 
export const F_InterviewQuestion_tenantId = "tenantId"; 

