/**
 * Section主要用于分区，用户可以选择将不同的字段放在不同的区块中
 */
import {BizFormPresenter} from "@src/bizForm/bizFormPresenter";
import {matchComponent} from "@src/bizForm/components";
import {EntityFieldViewer} from "@src/bizForm/components/EntityFieldViewer";
import {FormMode} from "@src/bizForm/formMode";
import BizSection from "@src/components/BizSection";
import {ITemplateColumn} from "@src/interfaces/template";
import {TemplateSection} from "@src/pages/devtool/bizForm/form/template";
import {Form} from "antd";
import {observer} from "mobx-react";
import {FormFieldElement, FormFieldElementProps} from "../FormFieldElement";
import {Component} from "react";
import {IconAdd} from "@src/icons";
import {toJS} from "mobx";
import Input from "@src/components/Input";
import {metadata} from "@src/metadata/metadata";
import BizTable, {BizColumnProps} from "@src/components/BizTable";
import {FormRowActionBar} from "@src/bizAction/ActionBar/FormRowActionBar";
import style from "./style.module.scss";


interface ISection {
  template: TemplateSection;
  presenter: BizFormPresenter;
  mode: FormMode;
  // itemPath: string;
}

@observer
export default class ChildSection extends Component<ISection> {
  getFieldProps = (itemPath: string, row: any, path: string) => {
    const {
      presenter,
      presenter: {model, form},
      mode,
    } = this.props;
    const field = model.getChildField(itemPath, path);
    const options: FormFieldElementProps = {
      key: `${itemPath}_${row._id}_${path}`,
      path: path,
      model: field,
      field: form.selectItemById(itemPath, row._id)?.select(path),
      mode: mode,
      presenter,
    };
    if (field) {
      if (field.isEnum) {
        options.enumType = field.enumType;
      }
      if (field.isRefer) {
        options.referType = field.referType;
      }
    }

    return options;
  };

  colRender = (col: ITemplateColumn, row: any, index: number) => {
    const {mode, presenter, template} = this.props;
    if (mode === FormMode.View) {
      const itemEntityName = metadata.getEntity(presenter.entityName).getField(template.path).referType;
      return (
        <FormFieldElement
          {...this.getFieldProps(template.path, row, col.path)}
          isItemField
          itemPath={template.path}
          itemId={row._id}
          className={style["item-field-view"]}
        >
          {(props: any) => (
            <EntityFieldViewer
              {...props}
              entityName={itemEntityName}
              bizType={props.model.bizType}
              isRefer={props.model.isRefer}
            />
          )}
        </FormFieldElement>
      );
    }
    return (
      <FormFieldElement
        {...this.getFieldProps(template.path, row, col.path)}
        isItemField
        itemPath={template.path}
        itemId={row._id}
      >
        {(props: any) => matchComponent(props)}
      </FormFieldElement>
    );
  };

  addRow = (row: any) => {
    const {template, presenter} = this.props;
    presenter.form.createItem(template.path, row._id);
  };

  columns = (): BizColumnProps<any>[] => {
    const {
      template,
      presenter: {model, mode, bizFromItermPresenterIntances},
    } = this.props;
    const cols: BizColumnProps<any>[] = template.columns.map((col) => {
      const fieldModel = model.getChildField(template.path, col.path);
      return {
        title: fieldModel.label,
        // key: `${template.path}_${}`,
        dataIndex: fieldModel.path,
        render: (text: any, row: any, index: number) => this.colRender(col, row, index),
      };
    });

    //hidden
    if (mode !== FormMode.View) {
      cols.unshift({
        dataIndex: "ordinal",
        fixed: "left",
        fixedWidth: true,
        width: 32,
        render: (text, row, index: number) => {
          return (
            <div>
              <Form.Item name={[template.path, "_id"]} initialValue={row._id} style={{display: "none"}}>
                <Input readOnly />
              </Form.Item>
              <span>
                <IconAdd className={style["add-row-icon"]} onClick={() => this.addRow(row)} />
              </span>
            </div>
          );
        },
      });
    }

    // action
    cols.push({
      title: "操作",
      dataIndex: "itemActions",
      fixed: "right",
      fixedWidth: true,
      width: 200,
      render: (_, record) => (
        <FormRowActionBar itemPresenter={bizFromItermPresenterIntances[template.path]} data={record} />
      ),
    });

    return cols;
  };

  render() {
    const {template, presenter} = this.props;
    const tableId = presenter.entityName + "-" + template.path;
    console.log(toJS(presenter.form.mstInstance.getItems(template.path)));

    return (
      <BizSection
        className={style["biz-form_section"]}
        leftElement={<span className={style["section_title"]}>{template.title}</span>}
        content={
          <BizTable
            className={style["biz-form_section_table"]}
            columns={this.columns()}
            size="small"
            pagination={false}
            dataSource={toJS(presenter.form.mstInstance.getItems(template.path))}
            rowKey="_id"
            bordered
            tableId={tableId}
            scroll={{
              x: true,
              // x: 1000,
              // 分页、查询后滚动到列表顶部
              // scrollToFirstRowOnChange: true,
            }}
          />
        }
      />
    );
  }
}
