import EN_InterviewQuestion from "@packages/gen/types/entities/InterviewQuestion";
import {IDisposer} from "@src/MST-Form/interface";
import {MSTForm} from "@src/MST-Form/MSTForm";
import {autobind} from "core-decorators";
import {BizFormPresenter} from "../../bizForm/bizFormPresenter";
import { F_InterviewQuestion_difficulty } from "@src/packages/gen/constants/entities/InterviewQuestion";
import ENUM_InterviewQuestionDifficulty from "@src/packages/gen/types/enums/InterviewQuestionDifficulty";
import { FormMode } from "@src/bizForm/formMode";

@autobind
export class InterviewQuestionFormPresenter<T = EN_InterviewQuestion> extends BizFormPresenter<T> {
  public onFormCreated(form: MSTForm<T>, disposers: IDisposer[]) {
    // console.log(form.getFormData())
    // disposers.push(
    //     reaction(
    //         () => form.select("category").value,
    //         (value) => {
    //             form.select("name").disabled = true;
    //         }
    //     )
    // )
    if (this.mode === FormMode.Create) {
      form.select(F_InterviewQuestion_difficulty).value = ENUM_InterviewQuestionDifficulty.Easy;
    }
  }

  // public setInitFormData(): void {
  //   if (isNewFormMode(this.mode)) {
  //     this.initFormData = {
  //       categoryId: "1bdee5c4-3827-468e-a16a-0658f2dda198",
  //     };
  //   }
  // }
}