import {fetchFormAction} from "@src/http/formData";

export default class FormUtils {
  // 将字符串首字母转为小写
  static FormAction(entityName: string, action: string, data: any, extension = {}) {
    return fetchFormAction({
      entityName,
      action,
      data,
      extension,
    });
  }

  static async FormActionDelete(entityName: string, id: string, extension = {}) {
    const result = await FormUtils.FormAction(entityName, "delete", id, extension);
    return result;
  }
}
