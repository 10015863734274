import {FC, useCallback, useContext, useMemo, useState} from "react";
import {RouterTabsContext} from "./context";
import {RouterTab} from "./types";
import classNames from "classnames";
import {IconClose} from "@src/icons";
import {Content} from "antd/lib/layout/layout";
import {Dropdown, MenuProps} from "antd";
import styles from "./style.module.scss";

const Item: FC<{tab: RouterTab}> = (props) => {
  const context = useContext(RouterTabsContext);

  const [openMenu, setOpenMenu] = useState(false);

  const handleChange = useCallback(() => {
    context.onChange(props.tab);
  }, [props]);

  const handleDelete = useCallback(
    (e: any) => {
      e.stopPropagation();
      context.onDelete(props.tab, {autoShowNext: true});
    },
    [props, context],
  );
  const handleContextMenu = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setOpenMenu(true);
  }, []);

  const handleContextMenuOpenChange = useCallback((open: boolean) => {
    if (!open) {
      setOpenMenu(false);
    }
  }, []);

  const handleDeleteTab = useCallback(() => {
    context.onDelete(props.tab, {autoShowNext: true});
  }, [props, context]);

  const handleDeleteAllTab = useCallback(() => {
    context.onDeleteAll();
  }, [context]);

  const items = useMemo((): MenuProps["items"] => {
    return [
      {
        key: 1,
        label: <span onClick={handleDeleteTab}>关闭标签</span>,
      },
      {
        key: 2,
        label: <span onClick={handleDeleteAllTab}>关闭所有标签</span>,
      },
    ];
  }, [handleDeleteTab, handleDeleteAllTab]);

  return (
    <div
      className={classNames(styles["route-tab"], {
        [styles["route-tab_active"]]: context.activeTab?.id === props.tab.id,
      })}
      onClick={handleChange}
      onContextMenu={handleContextMenu}
    >
      <Dropdown menu={{items}} placement="bottomLeft" open={openMenu} onOpenChange={handleContextMenuOpenChange}>
        <span className={styles["route-tab-title"]}>{props.tab.title}</span>
      </Dropdown>

      {props.tab.path !== "/" ? <IconClose className={styles["route-tab-icon"]} onClick={handleDelete} /> : null}
    </div>
  );
};

const RouteTabsList = () => {
  const context = useContext(RouterTabsContext);

  return (
    <>
      <div className={styles["route-tabs-wrapper"]}>
        <div className={styles["route-tabs"]}>
          {context.tabs.map((tab) => (
            <Item key={tab.id} tab={tab}></Item>
          ))}
        </div>
      </div>
      {context.tabs.map((tab) => (
        <Content
          key={tab.id}
          className="layout-content"
          style={{display: context.activeTab?.id === tab.id ? "block" : "none"}}
        >
          {tab.node}
        </Content>
      ))}
    </>
  );
};

export default RouteTabsList;
