import {BizFormPresenter} from "@src/bizForm/bizFormPresenter";
import {Observer} from "mobx-react";
import {useMemo} from "react";
import {BizFormField} from "@src/bizForm/core/model/bizFormField";
import {MSTFormField} from "@src/MST-Form/MSTFormField";
import Refer from "../Refer";
import {RouteUtils} from "@src/router/routeUtils";
import {FormMode} from "@src/bizForm/formMode";
import {toJS} from "mobx";
import {genReferOptions} from "@src/pages/devtool/bizForm/form/metadata";

interface IReferSelect {
  entityName: string; // 业务对象名称
  value: any; // 传入的值
  presenter: BizFormPresenter;
  onChange: (value: any) => void; // 选择数据时触发
  model: BizFormField;
  field: MSTFormField;
  disabled?: boolean;
  isMutil?: boolean; //多选
  id?: string;
}

/**
 * 根据entityName自动查询对应的数据
 * 系统里可以作为refer的数据都应该有id、title字段。ReferSelect组件读取对应的属性即可
 * refer只显示entity本身的字段，子表的字段不会显示
 * ReferSelect应作为受控组件使用
 * @param props
 * @returns
 */
function ReferSelect(props: IReferSelect) {
  const {presenter, model, field, value} = props;
  const entityName = model.referType;
  const logicPath = field.path;

  const referOptions = useMemo(
    () => toJS(presenter.metadata?.getReferOptions?.(logicPath)) || genReferOptions(entityName),
    [presenter, logicPath],
  );

  const onChange = (value: any) => {
    props?.onChange(value);
  };

  const onCreate = () => {
    RouteUtils.toForm({
      entityName: entityName,
      objectTypeId: presenter.entityName,
      mode: FormMode.Create,
    });
  };

  return (
    <Observer>
      {() => (
        <Refer
          id={props.id}
          entityName={entityName}
          objectTypeId={presenter.entityName}
          referOptions={referOptions}
          value={props.value}
          onChange={onChange}
          disabled={props.disabled}
          onCreate={onCreate}
        />
      )}
    </Observer>
  );
}

export default ReferSelect;
