import {getQuerySchemaInput} from "@src/bizForm/components/QueryValueType";
import {ValueType} from "@src/bizForm/components/QueryValueType/valueTypeMaps";
import {LifecycleEvent} from "@src/bizForm/lifecycleEvent";
import {ModuleNames} from "@src/bizForm/modules/moduleNames";
import {IQuerySchemaItem} from "@src/interfaces/queryScheme";
import {FieldPathUtils} from "@src/utils/fieldPath";
import {ValueTypeUtils} from "@src/utils/valueTypeUtils";
import {Col, Form, Row, Input, Button} from "antd";
import {observer} from "mobx-react";
import React from "react";
import {BizListPresenter} from "../bizListPresenter";
import style from "./style.module.scss";

export interface IQuickQuery {
  presenter: BizListPresenter;
}

// 快捷查询
@observer
export class QuickQuery extends React.Component<IQuickQuery> {
  private getQueryItems = () => {
    const {presenter} = this.props;
    return presenter.querySchema?.items.filter((item) => item.isQuickQuery) || [];
  };

  private query = () => {
    this.props.presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.resetPageNoQueryList);
  };

  private reset = () => {
    this.props.presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.resetQueryListSolutionValues);
  };

  private getBizType = (item: IQuerySchemaItem) => {
    const pathInfo = FieldPathUtils.getPathInfo(this.props.presenter.entityName, item.path);
    return pathInfo.bizType;
  };

  getEnumType = (item: IQuerySchemaItem) => {
    const pathInfo = FieldPathUtils.getPathInfo(this.props.presenter.entityName, item.path);
    if (pathInfo.bizType === "enum") {
      return pathInfo.enumType;
    }
    return undefined;
  };

  render() {
    const itemCount = this.getQueryItems().length;
    if (!itemCount) {
      return null;
    }
    const operationSace = 24 - (itemCount % 4) * 6;
    return (
      <div className={style["quick-query"]}>
        <Form layout="vertical">
          <Row gutter={[16, 16]}>
            {this.renderItems()}
            <Col span={operationSace}>
              <div className={style["quick-query-item"]}>
                <div className={style["quick-query-actions"]}>
                  <Button type="primary" htmlType="submit" onClick={this.query} size="small">
                    查询
                  </Button>
                  <Button onClick={this.reset} size="small">重置</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }

  renderItems = () => {
    const {presenter} = this.props;
    const items = this.getQueryItems();
    return items.map((item) => {
      const querySolutionItem = presenter.querySolution.getByPath(item.path);
      const bizType = this.getBizType(item);
      const Comp = getQuerySchemaInput(bizType, querySolutionItem.valueType) as any;
      const value = ValueTypeUtils.isMutil(querySolutionItem.valueType)
        ? querySolutionItem.values
        : querySolutionItem.values[0];
      const props = {
        value: value,
        fieldName: item.path,
        entityName: presenter.entityName,
        querySchemaItems: items,
        onChange: querySolutionItem.updateValues,
        isMutil: [ValueType.in, ValueType.nin].includes(querySolutionItem.valueType),
        enumName: this.getEnumType(item),
        // objectTypeId: presenter.entityName
      };
      
      return (
        <Col span={6} key={item.path}>
          <div className={style["quick-query-item"]}>
            <Form.Item label={item.displayName}>
              <Comp {...props} />
            </Form.Item>
          </div>
        </Col>
      );
    });
  };
}
