import {IDisposer, MSTFieldWriteableProps, Optional} from './interface';
import {MSTForm} from './MSTForm';
import {v4 as uuidv4} from 'uuid';
import {IStateTreeNode} from 'mobx-state-tree';
import {$FORM_STATE_KEY} from './MSTForm-utils';
import {makeAutoObservable, observable, reaction, untracked} from 'mobx';
import lodashGet from 'lodash/get';
// 参数可选项
export interface MSTFormFieldOptions {
  initState: Optional<MSTFieldWriteableProps>;
}

export interface MSTFormFieldParams {
  path: string;
  form: MSTForm<any>;
  node: IStateTreeNode;
  options: MSTFormFieldOptions;
  initSnapshot?: any;
}

export class MSTFormField {
  public readonly form: MSTForm<any>;

  public readonly id: string; // 使用uuid

  public readonly path: string; // 对应的节点

  public node: IStateTreeNode | any;

  private readonly options: MSTFormFieldOptions;
  private readonly initSnapshot: any;

  private disposers: Array<IDisposer> = [];

  constructor (readonly params: MSTFormFieldParams) {
    makeAutoObservable(this);
    this.id = uuidv4();
    this.path = params.path;
    this.form = params.form;
    this.options = params.options;
    this.node = params.node;
    this.initSnapshot = params.initSnapshot;
    this.node[ $FORM_STATE_KEY ] = observable.map(new Map<string, boolean>());

    this.initValidators();
  }

  // 析构函数
  public destructor () {
    this.disposers.forEach((disposer) => disposer()); // 销毁所有的监听器
  }

  initValidators = () => {
    this.disposers.push(
      // 添加校验触发器
      reaction(
        () => this.value,
        (value) => {
          this.validate();
        },
      ),
    );
  };

  public getStateMap () {
    if (!this.node[ $FORM_STATE_KEY ]) {
      this.node[ $FORM_STATE_KEY ] = observable.map(new Map<string, boolean>());
    }

    return this.node[ $FORM_STATE_KEY ] || {};
  }

  public getState (propName: string, defaultValue?: boolean) {
    const stateMap = untracked(() => this.getStateMap());
    const key = `state-${ propName }`;
    if (stateMap.has(key)) {
      return stateMap.get(key);
    } else {
      if (this.options.initState) {
        if (typeof lodashGet(this.options.initState, propName) === 'undefined') {
          return defaultValue;
        }
        return lodashGet(this.options.initState, propName);
      }
    }
  }

  public setState (propName: string, value: boolean) {
    const stateMap = this.getStateMap();
    const key = `state-${ propName }`;
    return stateMap.set(key, value);
  }

  public get label () {
    return '';
  }

  public get required () {
    return this.getState('required', false);
  }

  public set required (required: boolean) {
    this.setState('required', required);
  }

  public get disabled () {
    return this.getState('disabled', false);
  }

  public set disabled (disabled: boolean) {
    this.setState('disabled', disabled);
  }

  public get value () {
    return this.form.getValue(this.path);
  }

  public set value (val: any) {
    this.form.setValue(this.path, val);
  }

  // value 改变的方法
  public onChange (value: any) {
    // this.value = value;
  }

  public get focused () {
    return this.getState('focused', false);
  }

  public set focused (focused: boolean) {
    this.setState('focused', focused);
  }
  // 判断值是否改变
  public get changed () {
    return this.initSnapshot !== this.value;
  }

  public validate () {
    return this.form.valiator.validateField(this.path);
  }

  public get errors () {
    return this.form.valiator.getMessageBypath(this.path);
  }
}
