import {Modal} from "antd";
import {FC, useCallback, useMemo, useState} from "react";
import {LInkArticleModalProps} from "./types";
import Refer from "@src/bizForm/components/Refer";
import { genReferOptions } from "@src/pages/devtool/bizForm/form/metadata";
import EN_Article from "@src/packages/gen/types/entities/Article";
import { ENUM_Entity_Article } from "@src/packages/gen/constants/enums/entityName";

const LInkArticleModal: FC<LInkArticleModalProps> = (props) => {

    const [article, setArticle] = useState<EN_Article>();

    const onOk = useCallback(() =>{
        props.onOk(article)
    }, [article]);

    const referOptions = useMemo(
      () => genReferOptions(ENUM_Entity_Article),
      [],
    );
  
    
  return (
    <Modal open={props.open} onCancel={props.onCancel} onOk={onOk} title={"选择文章"}>
      <Refer
        entityName={ENUM_Entity_Article}
        referOptions={referOptions}
        value={article}
        onChange={(article: EN_Article) => setArticle(article)}
      />
    </Modal>
  );
};

export default LInkArticleModal;

// https://yanthink.github.io/pingfan.ts/#/markdown-editor