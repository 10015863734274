import {DatePicker as AntDatePicker} from 'antd';
import dayjs from 'dayjs';
import {IBizFormComponentOProps} from '../interface';
import styles from './style.module.scss';

export interface IDatatimePicker extends IBizFormComponentOProps {}
/**
 * datetime目前应该只能读不能编辑，因为通常是编辑日期、时间
 * @param props 日期组件
 * @returns
 */
export const DatetimePicker = (props: IBizFormComponentOProps) => {
  return (
    <AntDatePicker
      className={styles[ 'datetime-picker' ]}
      showTime
      value={props.value ? dayjs(props.value) : undefined}
      onChange={(value) => props.onChange(value ? value.valueOf() : undefined)}
      disabled={props.disabled}
      placeholder={''}
    />
  );
};
