import { IBizAction } from "@src/bizAction/interface";
import {LifecycleEvent} from "@src/bizForm/lifecycleEvent";
import {ModuleNames} from "@src/bizForm/modules/interface";
import {BizListPresenter} from "@src/bizList/bizListPresenter";
import {recommendFavicon} from "@src/http/favicon";
import {message} from "antd";
/**
 * 列表行使用
 */
export const RecommendFavicon: IBizAction = {
  key: "recommendFavicon",
  label: "推荐/取消推荐",
  options: {
    confirm: true,
    onClick: (presenter: BizListPresenter, data?: any) => {
      const newValue = !data.recommended;
      recommendFavicon({
        id: data.id,
        recommended: newValue,
      }).then((response) => {
        if (response.success) {
          message.success("更新成功");
          presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.queryListData);
        } else {
          message.error("更新失败");
        }
      });
    },
  },
};
