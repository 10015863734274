import {BackendDescription, BackendDescriptionField} from './interface';
import {metadata, Metadata} from './metadata';
import _get from 'lodash/get';
import _clone from 'lodash/cloneDeep';
import _, {result} from 'lodash';
import {StringUtils} from '@src/utils/stringUtils';
import {IMetadata, IMetadataField} from '@src/interfaces/metadata';
export interface IEntityField {
  // name: string;
  // title: string;
  // bizType: string;
  // isPriamry: boolean;
  // isRequired: boolean;
  // isReadonly: boolean;
  // referType?: string;
  // sourceType?: string;
  // isSystem: boolean;
  // isRefer?: boolean;
}

export class EntityField {
  constructor (
    private backendDescription: BackendDescriptionField, // private metadata: IMetadataField
  ) {}

  get name () {
    return this.backendDescription.name;
  }

  // 别名
  get aliasName () {
    if (this.isRefer) {
      return _get(this.backendDescription.referInfo, 'aliasName', this.name);
    }
    return this.name;
  }

  get title () {
    return this.backendDescription.title;
  }

  get bizType () {
    // if (this.isRefer) {
    //     return 'refer';
    // }

    return this.backendDescription.bizType;
  }

  get referType () {
    if (this.isRefer || this.isItems) {
      return _get(this.backendDescription.referInfo, 'referEntityName', null);
    }

    return null;
  }

  get enumType () {
    if (this.bizType === 'enum') {
      return _get(this.backendDescription.referInfo, 'referEntityName', null);
    }
    return null;
  }

  get isRefer () {
    return this.bizType === 'refer';
  }
  get isEnum () {
    return this.bizType === 'enum';
  }

  get isPriamry () {
    return this.backendDescription.isPriamry;
  }

  get isSystemField () {
    return this.backendDescription.isSystemField;
  }

  get type () {
    return this.backendDescription.type;
  }

  get isDetail () {
    return _get(this.backendDescription, 'isDetail', false);
  }

  get associationField () {
    return this.backendDescription.associationField;
  }

  get isChildren () {
    return _get(this.backendDescription.referInfo, 'isChildren', false);
  }

  get backendDescriptionInfo () {
    return _clone(this.backendDescription);
  }

  get isMutil () {
    return this.backendDescription.isMutil;
  }

  get isItems () {
    // 是否为明细表
    return this.bizType === "items"; 
  }
}

export class Entity {
  name: string;
  title: string;
  type: string;
  isBaseData?: boolean; // 是否为基础数据
  // key/value信息
  fields: {[key in string]: EntityField} = {};
  aliasFields: {[key in string]: EntityField} = {};
  metadata: IMetadata;

  getField (fieldName: string) {
    // 如果从fields上拿不到数据， 但从aliasFields上可以拿到的话，说明是外键字段
    return this.fields[ fieldName ] || this.aliasFields[ fieldName ] || null;
  }

  // 获取属性最后一个节点对应的信息
  getFieldLastNode (fieldName: string) {
    const fieldnNameArr = fieldName.split('.');
    const result = [];
    let entity = this as Entity;
    while (fieldnNameArr.length) {
      const field = entity.getField(fieldnNameArr[ 0 ]);
      if (field) {
        if (field.isRefer) {
          entity = metadata.getEntity(field.referType);
        }
        result.push(field);
        fieldnNameArr.shift();
      } else {
        // 路径无效
        return null;
      }
    }

    return new EntityField({
      ...result[ result.length - 1 ].backendDescriptionInfo,
      title: result.reduce((prev, current) => (prev ? prev + '/' + current.title : current.title), '')
    });
  }
}

export function entityFactory (backendDescription: BackendDescription) {
  const entity = new Entity();
  entity.name = backendDescription.name;
  entity.title = backendDescription.title;
  entity.type = backendDescription.type;
  entity.isBaseData = backendDescription.isBaseData;
  // entity.metadata = backendDescription;

  //1: 通过description描述文件构建网状的实体模型，只取description文件上有的属性
  // 在显示数据时应该根据metadata来
  backendDescription.fields.forEach((field) => {
    const entityField = new EntityField(field);
    entity.fields[ field.name ] = entityField;
    entity.aliasFields[ entityField.aliasName ] = entityField;
  });

  return entity;
}
