import { bizFormRoute } from "../bizFormRouter";
import { bizListRouter } from "../bizListRouter";
import { ENUM_Entity_Author } from "@packages/gen/constants/enums/entityName";
import { AuthorFormPresenter, AuthorListPresenter } from "@src/pages/author";

export default [
  ...bizFormRoute({
    entityName: ENUM_Entity_Author,
    createPresenter: (options: any) => {
      // const { ProductFormPresenter } = await import(
      //   "@src/pages/product/productFormPresenter"
      // );
      // return new ProductFormPresenter(options);

      return new AuthorFormPresenter(options);
    },
  }),
  ...bizListRouter({
    entityName: ENUM_Entity_Author,
    createPresenter: (options: any) => {
      return new AuthorListPresenter(options);
    },
  })
];
