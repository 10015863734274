import React from "react";
import { BizFormPresenter } from "../bizFormPresenter";
import { LifecycleEvent } from "../lifecycleEvent";
import { ModuleNames } from "../modules/interface";
import { MasterFrom } from "./form-zone/masterForm";

interface IBizFormBody {
  // template: Template;
  presenter: BizFormPresenter;
}

export class BizFormBody extends React.Component<IBizFormBody> {

  componentDidMount() {
    this.props.presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.onFormCreated);
  }

  render() {
    return this.renderContent();
  }

  renderContent = () => {
    const {
      presenter: { template },
      presenter,
    } = this.props;
    if (!template) {
      return null;
    }
    return <MasterFrom presenter={presenter} />;
  };
}
