import EN_Tenant from "@packages/gen/types/entities/Tenant";
import {autobind} from "core-decorators";
import {BizFormPresenter} from "../../bizForm/bizFormPresenter";
import {BizFormConfig} from "@src/bizForm/interface";
import {IBizAction} from "@src/bizAction/interface";

@autobind
export class TenantFormPresenter<T = EN_Tenant> extends BizFormPresenter<T> {
  public bizFormConfig(config: BizFormConfig<T>): BizFormConfig<T> {
    config.formActionManager.use((actions: Array<IBizAction>, data: any): Array<IBizAction> => {
      return actions.filter((action) => action.key !== "gotoList");
    });

    return config;
  }
}
