import BizForm from '@src/bizForm/bizForm';
import {BizFormPresenter} from '@src/bizForm/bizFormPresenter';
import {FormMode} from '@src/bizForm/formMode';
import {BizListPresenter} from '@src/bizList/bizListPresenter';
import {Modals} from '@src/components/Modals';
import {Modal, message} from 'antd';
import React from 'react';

export interface IViewModal {
  bizFormPresenter: typeof BizFormPresenter;
  entityName: string;
  id: string;
  onOk: () => void;
  onCancel: () => void;
}
export class ViewModal extends React.Component<IViewModal> {
  private presenter: BizFormPresenter;
  private prevHistoryUrl: string;

  constructor (props: IViewModal) {
    super(props);
    this.prevHistoryUrl = window.location.pathname;
    window.history.replaceState(null, '', `/${ this.props.entityName }/${ FormMode.View }/${ this.props.id }`);
    const FormPresenter = props.bizFormPresenter;
    this.presenter = new FormPresenter({
      entityName: this.props.entityName,
      mode: FormMode.View
    });
  }

  componentWillUnmount () {}

  private resetPathname = () => {
    window.history.replaceState(null, '', this.prevHistoryUrl);
  };

  private onCancel = () => {
    this.resetPathname();
    // eslint-disable-next-line no-unused-expressions
    this.props.onCancel && this.props.onCancel();
  };

  render () {
    return (
      <Modal
        title={this.presenter.initFormData.name}
        {...this.props}
        width={'90%'}
        footer={null}
        onCancel={this.onCancel}
      >
        <div style={{padding: '24px'}}>
          <BizForm presenter={this.presenter} mode={FormMode.View} id={this.props.id} />
        </div>
      </Modal>
    );
  }
}

/**
 * 列表行使用
 */
export const ViewAction = {
  key: 'view',
  label: '查看',
  options: {
    onClick: (presenter: BizListPresenter, data: any) => {
      if (!presenter.bizFormPresenter) {
        message.error("请设置bizFormPresenter");
        return;
      }
      Modals.show(ViewModal, {
        bizFormPresenter: presenter.bizFormPresenter,
        entityName: presenter.entityName,
        id: data.id
      });
    }
  }
};
