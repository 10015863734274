import {autobind} from "core-decorators";
import {metadata} from "@src/metadata/metadata";
import {BizFormPresenter} from "../bizFormPresenter";
import {LifecycleEvent} from "../lifecycleEvent";
import {BaseModule} from "./baseModule";
import {EventModule} from "./eventModule";
import {FormAction, FormSaveParams, ModuleNames} from "./interface";
import {ModulePropertyWrapper, ModuleWrapper} from "./moduleManager";
import {postFormData} from "@src/http/formData";
import {LoadingStatus} from "@src/components/LoadingContainer";
import {FormItemEditFlag, isNewFormMode} from "../formMode";
import _get from "lodash/get";
import {toJS} from "mobx";
import {FORM_EDIT_FALG_FIELD} from "@src/common/constants/mst";
// 整个页面的初始化模块
@autobind
@ModuleWrapper(ModuleNames.FormModule)
export class FormModule extends BaseModule {
  @ModulePropertyWrapper(ModuleNames.Presenter) private presenter: BizFormPresenter;
  @ModulePropertyWrapper(ModuleNames.EventModule) private eventModule: EventModule;

  public apply() {
    this.eventModule.on(LifecycleEvent.onSave, this.save);
  }

  // 加载tempalte、metadata、formData数据
  private async save() {
    if (this.presenter.form.validate()) {
      // 创建表单时全量提交数据
      let formData = isNewFormMode(this.presenter.mode)
        ? this.presenter.form.getFormData()
        : this.presenter.form.getChangeData();

      // 处理明细行数据
      // 1: 删除的行如果没有id就抛弃，否则传递id到后端
      // 2: 其他类型过滤掉_id数据
      const entity = metadata.getEntity(this.presenter.entityName);
      const masterForm: Record<string, any> = {};
      for (const [key, value] of Object.entries(formData)) {
        const filed = entity.getField(key);
        if (!filed) {
          continue;
        }
        if (filed.isItems && value) {
          masterForm[key] = (value as unknown as any[])
            .filter((item: any) => item[FORM_EDIT_FALG_FIELD] !== FormItemEditFlag.None)
            .filter((item: any) => !(item[FORM_EDIT_FALG_FIELD] === FormItemEditFlag.Delete && !item.id))
            .map((item: any) => {
              const {_id, ...rest} = item;
              if (item[FORM_EDIT_FALG_FIELD] === FormItemEditFlag.Delete) {
                return {
                  [FORM_EDIT_FALG_FIELD]: rest[FORM_EDIT_FALG_FIELD],
                  id: rest.id || undefined,
                };
              }
              return {
                ...rest,
              };
            });

          continue;
        }

        masterForm[key] = value;
      }

      // formData = {
      //   ...formData,
      //   ...items,
      // }

      const data = this.getPostData(masterForm);
      data.id = this.presenter.id;

      const params: FormSaveParams = {
        entityName: this.presenter.entityName,
        action: FormAction.Save,
        mode: this.presenter.mode.toLowerCase(),
        data,
      };

      this.presenter.loadingStatus = LoadingStatus.Loading;
      try {
        const result = await postFormData(params);
        if (result.success) {
          this.presenter.loadingStatus = LoadingStatus.Complete;
          // 触发数据完成后的操作，一般进行页面跳转，跳转到查看页面
          this.eventModule.dispatch(LifecycleEvent.afterSave, result.data["id"]);
        } else {
          this.presenter.loadingStatus = LoadingStatus.Failed;
        }
      } catch (err) {
        this.presenter.loadingStatus = LoadingStatus.Failed;
      }
    } else {
      const errorMessages = toJS(this.presenter.form.valiator.errorMessages);
      // 滚动到报错的元素位置，errorMessages是一个Map，所以可能滚动到的不是第一个报错的元素，后面修改validator的错误手机方式一起改掉
      const name = [...errorMessages.keys()][0];
      // scrollIntoView 滚动到元素所在位置
      // MDN https://developer.mozilla.org/zh-CN/docs/Web/API/Element/scrollIntoView
      document.getElementById(name)?.scrollIntoView({behavior: "smooth"});
    }
  }

  // 处理要保存到服务器上的数据，进行数据的增量保存，没修改过的不保存
  private getPostData(data: any) {
    const result: any = {};
    const entity = metadata.getEntity(this.presenter.entityName);
    [...Reflect.ownKeys(data)].forEach((path) => {
      const entityField = entity.getField(path as string);
      if (entityField) {
        if (entityField.isRefer) {
          // 外建只保存id
          result[entityField.name] = _get(data[path], "id", undefined);
        } else {
          result[entityField.name] = data[path];
        }
      }
    });

    return result;
  }
}
