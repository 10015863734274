import React from "react";
import {Route, RouteObject} from "react-router-dom";
const Login = React.lazy(() => import("@src/pages/auth/login"));
const Registry = React.lazy(() => import("@src/pages/auth/registry"));
const GithubCallback = React.lazy(() => import("@src/pages/auth/login/github"));
// const Somo = React.lazy(() => import("@src/pages/somo"));

const NoSiderRoutes: RouteObject[] = [
  // <Route  key="/login" path="/login" Component={Login}></Route>,
  // <Route  key="/registry" path="/registry" Component={Registry}></Route>,
  // <Route  key="/github/callback" path="/github/callback" Component={GithubCallback}></Route>,
  {
    path: "/login",
    Component: Login,
    handle: {
      title: "登陆"
    }
  },
  {
    path: "/registry",
    Component: Registry,
    handle: {
      title: "注册"
    }
  },
  {
    path: "/github/callback",
    Component: GithubCallback,
    handle: {
      title: "github登陆跳转页"
    }
  },
];
export default NoSiderRoutes;
