import EN_Favicon from "@packages/gen/types/entities/Favicon";
import { IBizAction } from "@src/bizAction/interface";
import { BizListPresenter } from "@src/bizList/bizListPresenter";
import { BizListConfig } from "@src/bizList/interface";
import { UpdateAllFavicons } from "./listBatchActions/updateAllFavicons";
import { UpdateFavicons } from "./listBatchActions/updateFavicons";
import { OpenFavicon } from "./listRowActions/openFavicon";
import { FaviconFormPresenter } from "./faviconFormPresenter";
import { RecommendFavicon } from "./listRowActions/recommendFavicon";

export class FaviconListPresenter<T extends EN_Favicon> extends BizListPresenter<T> {
  public bizFormPresenter = FaviconFormPresenter;

  public listBizConfig(config: BizListConfig<T>): BizListConfig<T> {
    config.listActionManager.use((actions: Array<IBizAction>, data: any): Array<IBizAction> => {
      return [...actions, UpdateFavicons, UpdateAllFavicons];
    });

    config.listRowActionManager.use((actions: Array<IBizAction>, data: T): Array<IBizAction> => {
      const result = [...actions];
      result.splice(1, 0, OpenFavicon, {
        ...RecommendFavicon,
        label: data.recommended ? "取消推荐" : "推荐",
      });
      return result;
    });

    return config;
  }
}
