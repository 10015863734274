import {FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {RouterTabsContext} from "./context";
import {RouterTab} from "./types";
import {v4 as uuidv4} from "uuid";
import {RouteUtils} from "@src/router/routeUtils";
import AppRouter from "@src/router";

const RouteTabs: FC<{children: ReactNode}> = (props) => {
  const [tabs, setTabs] = useState<RouterTab[]>([]);
  const [activeTab, setActiveTab] = useState<RouterTab>();
  const [nextIndex, setNextIndex] = useState(-1); // 标识下一个tab插入的位置
  const _sub = useRef<any>((_: any) => {});

  const onChange = useCallback(
    (tab: RouterTab) => {
      setActiveTab(tab);
    },
    [tabs],
  );

  // 新增tab，并设置为active状态
  const onCreate = useCallback(
    (path: string, title: string, node: React.ReactNode) => {
      const newTab: RouterTab = {
        id: uuidv4(),
        path,
        title,
        node,
        href: location.href.replace(location.origin, ""),
      };

      if (nextIndex !== -1) {
        tabs.splice(nextIndex, 0, newTab);
      } else {
        tabs.push(newTab);
      }
      setTabs([...tabs]);
      setNextIndex(-1);
      setActiveTab(newTab);
    },
    [tabs],
  );

  // 删除某个tab
  // autoShowNext: 是否自动打开下一个tab页签
  const onDelete = useCallback(
    (tab: RouterTab, opts?: {autoShowNext?: boolean}) => {
      if (tab) {
        const tabIndex = tabs.findIndex((item) => item.id === tab.id);
        if (tabIndex === -1) {
          return;
        }
        if (activeTab?.id !== tab.id) {
          tabs.splice(tabIndex, 1);
          setTabs([...tabs]);
          return;
        }
        if (activeTab?.id === tab.id) {
          // 如果删除了当前页面tab，则定位到后一个，如果已经是最后一个，那就向前定位
          const newActiceTab = tabs[tabIndex + 1] || tabs[tabIndex - 1];
          tabs.splice(tabIndex, 1);
          setTabs([...tabs]);
          if (opts?.autoShowNext && newActiceTab) {
            setActiveTab(newActiceTab);
          }
        }
      }
    },
    [tabs, activeTab],
  );

  const onDeleteAll = useCallback(() => {
    setTabs([]);
    setTimeout(() => RouteUtils.to("/"));
  }, [tabs]);

  useEffect(() => {
    AppRouter.subscribe((state: any) => {
      _sub.current?.(state);
    });
  }, []);

  useEffect(() => {
    _sub.current = (state: any) => {
      if (state.historyAction === "REPLACE") {
        if (activeTab) {
          // 找到activeTab的index
          const index = tabs.findIndex((tab) => tab.id === activeTab.id);
          onDelete(activeTab);
          setNextIndex(index);
        }
      }
    };
  }, [onDelete, activeTab, tabs]);

  const value = useMemo(() => {
    return {
      tabs,
      activeTab,
      onChange,
      onDelete,
      onCreate,
      onDeleteAll,
    };
  }, [tabs, activeTab, onChange, onDelete, onCreate, onDeleteAll]);

  useEffect(() => {
    if (activeTab && activeTab.href !== location.pathname + location.search) {
      RouteUtils.to(activeTab.href);
    }
  }, [activeTab]);

  return <RouterTabsContext.Provider value={value}>{props.children}</RouterTabsContext.Provider>;
};

export default RouteTabs;
