import React from "react";
import _get from "lodash/get";
import { RouteUtils } from "@src/router/routeUtils";
import { FormMode } from "@src/bizForm/formMode";
import { metadata } from "@src/metadata/metadata";
import { ENUM_Entity_ObjectType } from "@packages/gen/constants/enums/entityName";

export interface IReferViewer {
  value: { id: string; name: string;[key: string]: any };
  referType?: string;
  entityName?: string;
}
/**
 * 文本的查看模式内容组件
 */
export const ReferViewer = (props: IReferViewer): JSX.Element => {
  const handleLink = () => {
    if (props.referType) {
      if (props.referType ===ENUM_Entity_ObjectType) {
        RouteUtils.toList({
          entityName: props.value?.id,
        });
      } else {
        RouteUtils.toForm({
          entityName: props.referType,
          id: props.value.id,
          mode: FormMode.View,
        });
      }
    }
  };


  return <a onClick={handleLink}>{_get(props.value, "name")}</a>;
};
