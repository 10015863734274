import EN_Operator from '@packages/gen/types/entities/Operator';
import {IBizAction} from '@src/bizAction/interface';
import {BizListPresenter} from '@src/bizList/bizListPresenter';
import {BizListConfig} from '@src/bizList/interface';
import { OperatorFormPresenter } from './operatorFormPresenter';

export class OperatorListPresenter<T = EN_Operator> extends BizListPresenter<T> {
  public bizFormPresenter = OperatorFormPresenter;
  public listBizConfig (config: BizListConfig<T>): BizListConfig<T> {
    // config.column = {
    //   name: {
    //     cellRender: (val) => {
    //       return <span style={{color: 'red'}}>{val}</span>;
    //     }
    //   }
    // };

    config.listRowActionManager.use((actions: Array<IBizAction>, data: any): Array<IBizAction> => {
      // if (data.id === 'db56a548-b6f8-4bd4-8886-051dcde9858b') {
      //   return [];
      // }
      return actions;
    });
    return config;
  }
}
