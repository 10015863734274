
// TinyCode 

// id 
export const F_TinyCode_id = "id"; 

// 标题 
export const F_TinyCode_name = "name"; 

// javascript 
export const F_TinyCode_javascript = "javascript"; 

// script 
export const F_TinyCode_script = "script"; 

// html 
export const F_TinyCode_html = "html"; 

// css 
export const F_TinyCode_css = "css"; 

// scriptType 
export const F_TinyCode_scriptType = "scriptType"; 

// cssType 
export const F_TinyCode_cssType = "cssType"; 

// htmlType 
export const F_TinyCode_htmlType = "htmlType"; 

// 是否删除 
export const F_TinyCode_isDeleted = "isDeleted"; 

// 更新时间 
export const F_TinyCode_updateTime = "updateTime"; 

// 创建时间 
export const F_TinyCode_createTime = "createTime"; 

// 最近编辑人 
export const F_TinyCode_operatorId = "operatorId"; 

// 创建者 
export const F_TinyCode_creatorId = "creatorId"; 

// 租户 
export const F_TinyCode_tenantId = "tenantId"; 

