import { IconTupian} from "@src/icons";
import {EditorTool} from "../types";
import {Modals} from "@src/components/Modals";
import UploadImageModal from "./UploadImageModal";
import { editorInsertContent } from "../../utils";

const config: EditorTool = {
  id: "uploadImage",
  icon: <IconTupian />,
  onClick: (options) => {
    Modals.show(UploadImageModal, {
      onOk: (src: string) => {
        if (src) {
            editorInsertContent(options.editor, `![${src}](${src})`);
        }
      },
    });
  },
};

export default config;
