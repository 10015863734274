/**
 * Section主要用于分区，用户可以选择将不同的字段放在不同的区块中
 */
import { BizFormPresenter } from '@src/bizForm/bizFormPresenter';
import { FormMode } from '@src/bizForm/formMode';
import { TemplateSection } from '@src/pages/devtool/bizForm/form/template';
import { Input, Row } from 'antd';
import React from 'react';
import cx from 'classnames';
import './style.scss';

interface ISection {
    className?: string;
    content: React.ReactNode;
    leftElement: React.ReactNode;
    rightElement?: React.ReactNode;
}

export default class BizSection extends React.Component<ISection> {

    render() {
        const { className, leftElement, rightElement, content } = this.props;
        return <div className={cx('biz-section', className)}>
            <div className={cx('biz-section-header')}>
                <div className={cx('biz-section-header-left')}>
                    {leftElement}
                </div>
                <div className={cx('biz-section-header-right')}>
                    {rightElement}
                </div>
            </div>
            <div className={cx('biz-section-body')}>
                {
                    content
                }
            </div>
        </div>
    }
}
