export enum FormMode {
  Create = "Create",
  View = "View",
  Edit = "Edit",
  Copy = "Copy",
  None = "None",
}

export const ModeModeLabelMap = {
  [FormMode.Create]: "新增",
  [FormMode.View]: "查看",
  [FormMode.Edit]: "编辑",
  [FormMode.Copy]: "复制",
  [FormMode.None]: "其他",
};
// 判断是否为新表单模式，可以用来在数据提交时直接提交所有数据
export const isNewFormMode = (mode: FormMode) => [FormMode.Create, FormMode.Copy].includes(mode);
// 表单是否处理编辑状态
export const isFormEditStatus = (mode: FormMode) => [FormMode.Create, FormMode.Copy, FormMode.Edit].includes(mode);

// 用于明细行的状态
export enum FormItemEditFlag {
  None = "none", // 没有任何操作
  Create = "create", // 新增行
  Delete = "delete", // 删除行
  Edit = "edit", // 更新行
}