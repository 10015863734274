import {BizListPresenter} from "@src/bizList/bizListPresenter";
import {message} from "antd";
import { genTinyCodeEmbedScript } from "../../../../common/tinyCode";
import EN_TinyCode from "@src/packages/gen/types/entities/TinyCode";
/**
 * 列表行使用
 */
export const EmbedCopy = {
  key: "embedCopy",
  label: "embed",
  options: {
    onClick: (presenter: BizListPresenter, data?: EN_TinyCode) => {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(genTinyCodeEmbedScript(data.id, data.name));
      }
      message.success("复制成功");
    },
  },
};
