import {DataLoader, IDataloaderOptions} from "../dataLoader/dataLoader";
import _get from "lodash/get";
import {BizListPresenter} from "@src/bizList/bizListPresenter";
import {BizFormListQueryParams, BizFormListQueryValue} from "@src/interfaces/bizFormListQuery";
import {fetchFormListData} from "../formData";
import {observable, runInAction} from "mobx";
import {getDefaultListData} from "@src/bizList/interface";
import { message } from "antd";
import { ValueType } from "@src/bizForm/components/QueryValueType/valueTypeMaps";

export interface IListDataLoader extends IDataloaderOptions {
  // logicPath: string;
  entityName: string;
  presenter: BizListPresenter;
  queryStr?: string;
  // isVirtualList?: boolean;
  // querySolution: QuerySolution;
}

/**
 * 列表使用
 */
export class ListDataLoader extends DataLoader {
  // 列表数据
  // @observable
  public override data = observable(getDefaultListData());

  private presenter: BizListPresenter;

  constructor(options: IListDataLoader) {
    super({
      ...options,
      sorts: options.presenter.model.sorts,
    });
    this.presenter = options.presenter;
    this.init();
    this.initQueryBuilder();
  }

  init() {
    this.queryOptions.queryFields = this.presenter.model.queryColumns;
  }

  public async query() {
    const {entityName} = this.options;

    let values: Array<BizFormListQueryValue> = [...this.presenter.querySolution.items];
    // 合并额外的查询条件，这些查询条件不在 querySolution 对象上，而是查询时在带上，通常只有在写死且不能修改的情况下使用
    const extraItems = this.presenter.config.querySolution?.extraItems?.(this.presenter.querySolution);
    if (extraItems) {
      values.push(...extraItems);
    }
    if (this.presenter.objectTypeId) {
      values.push({
        path: "objectTypeId",
        valueType: ValueType.eq,
        values: [this.presenter.objectTypeId],
      });
    }
    values = values
      .filter((item) => {
        if (!Array.isArray(item.values)) {
          return false;
        }
        return item.values.filter((value) => value !== "" && value !== null && typeof value !== "undefined").length > 0;
      })
      .map((item) => ({path: item.path, valueType: item.valueType, values: item.values}));

    const params: BizFormListQueryParams = {
      entityName: entityName,
      queryStr: this.queryBuilder.criteriaStr,
      columns: this.queryOptions.queryFields,
      values: values,
      pageSize: this.presenter.listData.pageSize,
      pageNo: this.presenter.listData.pageNo,
      sorts: this.queryBuilder.sorts,
    };

    try {
      return await fetchFormListData(params).then((res) => {
        //@ts-ignore
        const {data, success, errMsg} = res;
        if (success) {
          runInAction(() => {
            if (this.presenter.options.virtual) {
              // 虚拟长列表把数据直接追加在后面
              this.data.data.push(...data.data);
            } else {
              this.data.data = data.data;
            }
            this.data.count = data.count;
            this.data.pageSize = data.pageSize;
            this.data.pageNo = data.pageNo;
            this.data.hasNextPage = data.hasNextPage;
          });
        } else {
          message.error(errMsg);
        }
      });
    } catch (err) {
      console.error(err);
    }
  }
}
