import {BizListPresenter} from "@src/bizList/bizListPresenter";
import { http } from "@src/http";
import {Modal, message} from "antd";
/**
 * 列表行使用
 */
export const UpdateAllFavicons = {
  key: "updateFavicons",
  label: "刷新所有favicon",
  options: {
    onClick: (presenter: BizListPresenter, data?: any) => {
      Modal.confirm({
        title: "确定刷新所有favicon吗，此操作耗时较长，不要频繁操作",
        onOk: async () => {
          try {
            await http({
              method: "post",
              url: "/Favicon/refreshAll",
            });
          } catch (err) {
            message.error(toString());
          }
        },
      });
    },
  },
};
