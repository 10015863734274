
// Article 

// 文章ID 
export const F_Article_id = "id"; 

// 文章名称 
export const F_Article_name = "name"; 

// 业务类型 
export const F_Article_bizTypeId = "bizTypeId"; 

// 内容 
export const F_Article_content = "content"; 

// 概览 
export const F_Article_description = "description"; 

// 标签 
export const F_Article_tags = "tags"; 

// 分类 
export const F_Article_categoryId = "categoryId"; 

// 文章链接 
export const F_Article_url = "url"; 

// 是否公开 
export const F_Article_isPublic = "isPublic"; 

// 是否删除 
export const F_Article_isDeleted = "isDeleted"; 

// 更新时间 
export const F_Article_updateTime = "updateTime"; 

// 创建时间 
export const F_Article_createTime = "createTime"; 

// 最近编辑人 
export const F_Article_operatorId = "operatorId"; 

// 创建者 
export const F_Article_creatorId = "creatorId"; 

// 租户 
export const F_Article_tenantId = "tenantId"; 

// 点赞次数 
export const F_Article_likeCount = "likeCount"; 

// 查看次数 
export const F_Article_viewCount = "viewCount"; 

// 头图 
export const F_Article_headerImg = "headerImg"; 

// 创作类型 
export const F_Article_creativeType = "creativeType"; 

