import {LoadingStatus} from '@src/components/LoadingContainer';
import {observable} from 'mobx';
import {QueryBuilder} from '../queryBuilder';
import {QueryBuilderHelper} from '../queryBuilder/queryBuilderHelper';
import { IQuerySortItem } from '@src/interfaces/queryScheme';

/**
 * Dataloader graphql和queryBuilder的基础上封装了查询的逻辑，
 * 将一部分逻辑抽离出来dataloader自己管理；
 * 1. 自动管理分页问题
 * 2. 管理查询状态
 */
export interface IDataloaderQueryOptions {
  queryFields: string[];
  queryStr?: string;
  id?: string;
  queryCondition?: string[];
  pageSize?: number;
  pageNo?: number;
  sorts?: IQuerySortItem[];
}

export interface IDataloaderOptions {
  entityName: string;
  queryStr?: string;
  logicPath?: string;
  queryFields?: Array<string>;
  [key: string]: any;
  // presenter: BizFormPresenter | BizListPresenter;
}

/**
 * 提供dataLoader的基础逻辑，必须作为父类使用
 */
export class DataLoader {
  protected queryOptions: IDataloaderQueryOptions = {
    queryFields: [],
    queryCondition: [],
    queryStr: '',
    pageNo: 1,
    pageSize: 10,
    sorts: [],
  };

  public queryBuilder: QueryBuilder;

  public data: any;

  @observable
  public loadingStatus = LoadingStatus.None;

  constructor (protected options: IDataloaderOptions) {}

  protected initQueryBuilder () {
    this.queryBuilder = QueryBuilderHelper.create({
      entityName: this.options.entityName,
      queryOptions: {
        ...this.queryOptions,
        sorts: this.options["sorts"] || this.queryOptions.sorts,
      }
    });
  }

  public query (): void {}

  public clear () {
    // this.data.length = 0;
  }

  public get loading() {
    return this.loadingStatus === LoadingStatus.Loading;
  }
}
