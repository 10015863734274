import {IconFenping, IconTupian} from "@src/icons";
import {EditorTool} from "../types";

const config: EditorTool = {
  id: "splitScreen",
  icon: <IconFenping />,
  onClick: (options) => {
    options.setState("splitScreen", !options.state.splitScreen);
  },
};

export default config;
