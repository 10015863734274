import { IconArticle } from "@src/icons";
import { EditorTool } from "../types";
import { Modals } from "@src/components/Modals";
import { editorInsertContent } from "../../utils";
import LInkArticleModal from "./LInkArticleModal";
import EN_Article from "@src/packages/gen/types/entities/Article";
import { genArticle2MarkdownLink } from "@src/common/entity";

const config: EditorTool = {
  id: "linkArticle",
  icon: <IconArticle />,
  onClick: (options) => {
    Modals.show(LInkArticleModal, {
      onOk: (article: EN_Article) => {
        const code = genArticle2MarkdownLink(article.id, article.name)
        editorInsertContent(options.editor, code);
      },
    });
  },
};

export default config;
