import EN_Article from "@packages/gen/types/entities/Article";
import {IDisposer} from "@src/MST-Form/interface";
import {MSTForm} from "@src/MST-Form/MSTForm";
import {autobind} from "core-decorators";
import {BizFormPresenter} from "../../bizForm/bizFormPresenter";
import { FormMode } from "@src/bizForm/formMode";
import { F_Article_creativeType } from "@src/packages/gen/constants/entities/Article";
import ENUM_CreativeType from "@src/packages/gen/types/enums/CreativeType";

@autobind
export class ArticleFormPresenter<T = EN_Article> extends BizFormPresenter<T> {
  public onFormCreated(form: MSTForm<T>, disposers: IDisposer[]) {
    if (this.mode === FormMode.Create) {
      form.select(F_Article_creativeType).value = ENUM_CreativeType.Original;
    }
  }
}
