import {PresenterProvider} from "@src/bizForm/context/bizFormContext";
import {LifecycleEvent} from "@src/bizForm/lifecycleEvent";
import {ModuleNames} from "@src/bizForm/modules/moduleNames";
import LoadingContainer, {LoadingStatus} from "@src/components/LoadingContainer";
import React from "react";
import {BizListBody} from "./bizlistBody";
import {BizListHeader} from "./bizlistHeader";
import {BizListPresenter} from "./bizListPresenter";
import {QuickQuery} from "./QuickQuery";
import "./style/index.scss";
import withRouter from "@src/router/withRouter";

interface IBizForm {
  presenter?: BizListPresenter;
}

class BizList extends React.Component<IBizForm, any> {
  constructor(props: IBizForm) {
    super(props);
    this.state = {
      loadingStatus: LoadingStatus.Loading,
    };
  }

  componentDidMount() {
    this.init();
  }

  init = () => {
    this.presenter.initData();
    this.presenter.getModule(ModuleNames.EventModule).on(LifecycleEvent.onListInitialized, this.onListInitialized);
    this.presenter.getModule(ModuleNames.EventModule).on(LifecycleEvent.reloadBizList, this.reload);
  };

  reload = () => {
    this.state = {
      loadingStatus: LoadingStatus.Loading,
    };
    this.init();
  };

  onListInitialized = async () => {
    try {
      this.setState({
        loadingStatus: LoadingStatus.Complete,
      });
    } catch (err) {
      console.error(err);
      this.setState({
        loadingStatus: LoadingStatus.Failed,
      });
    }
  };

  render() {
    return (
      <PresenterProvider value={this.presenter}>
        <LoadingContainer status={this.state.loadingStatus}>{this.renderContent}</LoadingContainer>
      </PresenterProvider>
    );
  }

  renderContent = () => {
    if (this.state.loadingStatus === LoadingStatus.Complete) {
      return (
        <div className="bizList">
          {!this.presenter.config.hiddenListAction ? <BizListHeader presenter={this.presenter} /> : null}
          {!this.presenter.config.hiddenQickQuery ? <QuickQuery presenter={this.presenter} /> : null}
          <BizListBody presenter={this.presenter} />
        </div>
      );
    }
    return null;
  };

  get presenter() {
    return this.props.presenter;
  }
}

export default withRouter(BizList);
