
// 创作类型
enum ENUM_CreativeType {
    
      // 原创
      Original = "original",
    
      // 转载
      Reprint = "reprint",
    
      // 翻译
      Translate = "translate",
    
}
export default ENUM_CreativeType;
