import {BIZ_AUTH} from "@src/common/constants/token";
import {fetchBaseMetadata} from "@src/http/base";
import {graphql} from "@src/http/graphql";
import {metadata} from "@src/metadata/metadata";
import OperatorStore from "@src/operator";
import {message} from "antd";
import localforage from "localforage";
import React from "react";
import LoadingContainer, {LoadingStatus} from "../LoadingContainer";

export interface IInitDataLoader {
  children?: React.ReactNode;
}

interface InitDataLoaderState {
  loadingStatus: LoadingStatus;
}

export class InitDataLoader extends React.Component<IInitDataLoader, InitDataLoaderState> {
  constructor(props: any) {
    super(props);

    this.state = {
      loadingStatus: LoadingStatus.Loading,
    };
  }

  componentDidMount() {
    this.init();
  }

  render() {
    return <LoadingContainer status={this.state.loadingStatus}>{() => this.props.children as any}</LoadingContainer>;
  }

  async init() {
    // 获取元数据
    const {descriptions} = await fetchBaseMetadata();
    metadata.init({
      descriptions,
      // metadatas,
    });
    // 获取用户id
    // @ts-ignore
    const {id: operatorId} = await localforage.getItem(BIZ_AUTH);
    // 获取Operator数据
    const {data, success, errMsg} = await graphql({
      query: `
      {
        Operator(id:"${operatorId}") {
          id
          name
          avatar
          email
        }
      }
      `,
    });
    if (success) {
      OperatorStore.init(data);
      this.setState({
        loadingStatus: LoadingStatus.Complete,
      });
    } else {
      message.error(errMsg);
      this.setState({
        loadingStatus: LoadingStatus.Failed,
      });
    }
  }
}
