import {mutilValueType, singleValuetype, ValueType} from "@src/bizForm/components/QueryValueType/valueTypeMaps";
import {IQuerySchema} from "@src/interfaces/queryScheme";
import {metadata} from "@src/metadata/metadata";
import {autobind} from "core-decorators";
import {makeAutoObservable, toJS} from "mobx";

export interface IQuerySolution {
  querySchema: IQuerySchema; // 后端获取的数据
  options: {
    values: {[key: string]: Array<any>}; // 上一次的查询条件
    valueTypes: {[key: string]: ValueType}; // 上一次查询的operate
  };
}

export interface IQuerySolutionItem {
  path: string;
  valueType?: ValueType;
  values?: Array<any>;
}

@autobind
export class QuerySolution {
  public items: Array<QuerySolutionItem> = [];
  public entityName: string;

  constructor(private params: IQuerySolution) {
    this.init();
  }

  private init() {
    this.entityName = this.params.querySchema.entityName;
    this.params.querySchema.items.forEach((item) => {
      this.addItem(item.path, this.getValueTypeByPath(item.path), this.getValuesByPath(item.path));
    });
  }

  private getValuesByPath = (path: string) => {
    const {
      options: {values = {}},
    } = this.params;
    return values[path] || [];
  };

  private getValueTypeByPath = (path: string) => {
    const {
      options: {valueTypes},
    } = this.params;
    return valueTypes[path];
  };

  // 通过path查询
  public getByPath = (path: string) => {
    return this.items.find((item) => item.path === path);
  };

  // 获取有值的查询条件
  public getQueryItems = () => {
    return this.items.filter((item) => {
      if (
        Array.isArray(item.values) &&
        item.values.length &&
        item.values.some((val) => val !== undefined && val !== "")
      ) {
        return true;
      }
      return false;
    });
  };

  private getPathStr = (item: QuerySolutionItem) => {
    const entity = metadata.getEntity(this.entityName);
    const entityField = entity.getFieldLastNode(item.path);
    if (entityField && entityField.isRefer) {
      return `${item.path}.id`;
    }
    return item.path;
  };

  public getQueryStr = () => {
    const items = this.getQueryItems();
    const condition: Array<string> = [];
    items.forEach((item) => {
      // 区间
      if (item.valueType === ValueType.between) {
        const values = this.getItemValues(item);
        if (Array.isArray(values)) {
          if (values.length === 1) {
            condition.push(`${this.getPathStr(item)} ${ValueType.ge} ${values[0]}`);
          }
          else if (values.length === 2) {
            if (typeof values[0] !== "undefined" && typeof values[1] !== "undefined") {
              condition.push(`${this.getPathStr(item)} ${ValueType.between} (${values.join(',')})`);
            } else {
              if (values[0] !== undefined) {
                condition.push(`${this.getPathStr(item)} ${ValueType.ge} ${values[0]}`);
              }
              if (values[1] !== undefined) {
                condition.push(`${this.getPathStr(item)} ${ValueType.le} ${values[1]}`);
              }
            }
          }
        }
      }
      // 包含， 不包含
      else if ([ValueType.in, ValueType.nin].includes(item.valueType)) {
        const values = this.getItemValues(item);
        if (Array.isArray(values)) {
          condition.push(`${this.getPathStr(item)} ${item.valueType} (${values.join(",")})`);
        }
      } else {
        //单值
        const value = this.getItemValues(item);
        if (value !== undefined) {
          condition.push(`${this.getPathStr(item)} ${item.valueType} ${value}`);
        }
      }
    });

    return condition.join(" and ");
  };

  private getItemValues = (item: QuerySolutionItem) => {
    const formateValue = (value: any) => {
      if (value === undefined || value === "" || value === null) {
        return undefined;
      }
      switch (typeof value) {
        case "string":
          if (item.valueType === ValueType.like) {
            return `'%${value}%'`;
          }
          return `'${value}'`;
        case "boolean":
        case "number":
          return value;
        case "object":
          return `'${value.id}'`;
        default:
          return `'${value}'`;
      }
    };

    if (singleValuetype.includes(item.valueType)) {
      return formateValue(item.values[0]);
    }
    if (item.valueType === ValueType.between) {
      return [formateValue(item.values[0]), formateValue(item.values[1])];
    }
    if (mutilValueType.includes(item.valueType)) {
      return item.values.map((val) => formateValue(val));
    }
    return undefined;
  };

  // 重置所有查询条件
  public resetValues = () => {
    this.items.forEach((item) => {
      item.updateValues([]);
    });
  };

  public toJSON = () => {
    const values: {[key: string]: Array<any>} = {};
    const valueTypes: {[key: string]: ValueType} = {};
    this.items.forEach((item) => {
      values[item.path] = toJS(item.values);
      valueTypes[item.path] = item.valueType;
    });
    return {
      values,
      valueTypes,
    };
  };

  public setValues = (values: {[path: string]: Array<any>}) => {
    Object.keys(values).forEach((path: string) => {
      const item = this.getByPath(path);
      if (item) {
        item.updateValues(values[path]);
      }
    });
  };

  public setValueTypes = (valueTypes: {[path: string]: ValueType}) => {
    Object.keys(valueTypes).forEach((path: string) => {
      const item = this.getByPath(path);
      if (item) {
        item.updateValueType(valueTypes[path]);
      }
    });
  };

  public addItem(path: string, valueType: ValueType, values: Array<any>) {
    this.items.push(this.createItem(path, valueType, values));
  }

  public createItem(path: string, valueType: ValueType, values: Array<any>) {
    return new QuerySolutionItem({
      path: path,
      valueType: valueType,
      values: values,
    });
  }
}

@autobind
export class QuerySolutionItem {
  public path: string;

  public valueType: ValueType;

  public values: Array<any> = [];

  constructor(options: IQuerySolutionItem) {
    makeAutoObservable(this);
    this.path = options.path;
    this.valueType = options.valueType;
    this.values = options.values || [];
  }

  public updateValueType = (valueType: ValueType) => {
    this.valueType = valueType;
  };

  public updateValues = (values: Array<any>) => {
    if (!Array.isArray(values)) {
      this.values = [values];
    } else {
      this.values = values;
    }
  };

  public toJSON = () => {
    return {
      path: this.path,
      valueType: this.valueType,
      values: this.values,
    };
  };
}
