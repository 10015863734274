import {IMetadata, IMetadataField} from "@src/interfaces/metadata";
import {Entity} from "@src/metadata/entity";
import {metadata} from "@src/metadata/metadata";
import {Template} from "@src/pages/devtool/bizForm/form/template";
import {BizFormField} from "./bizFormField";
import {ITemplateColumn} from "@src/interfaces/template";
import { autobind } from "core-decorators";

/**
 * 前端数据模型, 用于展示使用
 */
@autobind
export class BizForm {
  public entity: Entity; // 基础数据模型
  public fields: BizFormField[];
  public childFileds: Record<string, BizFormField[]> = {};
  public fieldsMap: Record<string, BizFormField> = {};
  public childFieldsMap: Record<string, Record<string, BizFormField>> = {};

  public getField(key: string) {
    return this.fieldsMap[key];
  }

  public getChildField(itemPath: string, path: string) {
    return this.childFieldsMap[itemPath]?.[path];
  }

  constructor(private entityName: string, private formMetadata: IMetadata, private formTemplate: Template) {
    this.entity = metadata.getEntity(entityName);
    this.init();
  }

  init() {
    this.masterTable();
    this.childTables();
  }

  geTableInfo(options: {
    entityName: string;
    columns: ITemplateColumn[];
    fields: BizFormField[];
    fieldsMap: Record<string, BizFormField>;
    metadataFields: IMetadataField[];
  }) {
    options.fields = options.columns.map((templateCol) => {
      const metadataItem = options.metadataFields.find((item) => item.path === templateCol.path);
      const formField = new BizFormField(options.entityName, templateCol, metadataItem);

      options.fieldsMap[templateCol.path] = formField;
      // 如果携带关系的跟业务对象没有在map中，那么需要补充进去，因为mstForm的取值过程是从根业务对象开始的
      if (formField.isCarry && !options.fieldsMap[templateCol.path.split(".")[0]]) {
        const rootPath = templateCol.path.split(".")[0];
        const rootMetadataItem = options.metadataFields.find((item) => item.path === rootPath);
        const rootFormField = new BizFormField(options.entityName, templateCol, rootMetadataItem);
        options.fieldsMap[rootPath] = rootFormField;
      }

      return formField;
    });
  }

  masterTable() {
    const {master = []} = this.formTemplate;
    const columns = [...master].map((table) => table.columns).flat(1);

    const options = {
      entityName: this.entityName,
      columns,
      fields: [] as BizFormField[],
      fieldsMap: {},
      metadataFields: this.formMetadata.fields,
    };

    this.geTableInfo(options);
    this.fields = options.fields;
    this.fieldsMap = options.fieldsMap;
  }

  childTables() {
    const {childTables = []} = this.formTemplate;

    childTables.forEach((childTable) => {
      const metadataFields = Object.entries(this.formMetadata.items).find(([key, fields]) => childTable.path === key)?.[1];
     const entityName =  this.entity.getField(childTable.path).referType
      const options = {
        entityName: entityName,
        columns: childTable.columns,
        fields: [] as BizFormField[],
        fieldsMap: {},
        metadataFields: metadataFields,
      };

      this.geTableInfo(options);
      this.childFileds[childTable.path] = options.fields;
      this.childFieldsMap[childTable.path] = options.fieldsMap;
    });
  }
}
