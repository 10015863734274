/**
 *每个表单都应该有独立的生命周期，所以表单需的事件需要隔离。即需要独立的事件管理没模块
 * 计划使用模块的方式，结合事件订阅的处理。
 */

export enum LifecycleEvent {
  // presenter创建完毕
  afterPresenterCreated = 'afterPresenterCreated',
  // module初始化完成
  onModuleReady = 'onModuleReady',
  // 初始化数据加载完成(metadata、template、formData)
  onLoadedInitData = 'onInitData',
  // 表单初始化完成
  onFormInitialized = 'onFormInitialized',
  // form渲染w完成
  onFormCreated = 'onFormCreated',
  // 保存数据前，
  onBeforeSave = 'onBeforeSave',
  // 保存数据
  onSave = 'onSave',
  // 数据保存完成
  afterSave = 'afterSave',

  // 列表queryColumn、querySchema数据加载完毕
  onLoadedListInitData = 'onLoadedListInitData',
  // 列表数据加载完成
  onLoadedListData = 'onLoadedListData',
  // 列表初始化完成
  onListInitialized = 'onListInitialized',
  // 加载列表的过滤条件数据
  loadQuerySchemaData = 'loadQuerySchemaData',
  // 列表的过滤条件数据 加载完毕
  onQuerySchemaDataLoaded = 'onQuerySchemaDataLoaded',
  // 查询列表数据
  queryListData = 'queryListData',
  // 重置pageNo并查询列表
  resetPageNoQueryList = "resetPageNoqueryListData",
  // 重置列表的查询方案的值
  resetQueryListSolutionValues = 'resetQueryListSolutionValues',

  // 重新加载页面
  reloadBizForm = 'reloadBizForm',

  reloadBizList = 'reloadBizList',
}
