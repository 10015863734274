import {getQuerySchemaInput} from '@src/bizForm/components/QueryValueType';
import {ValueTypeSelect} from '@src/bizForm/components/QueryValueType/ValueTypeSelect';
import {BizListPresenter} from '@src/bizList/bizListPresenter';
import {QuerySolution} from '@src/bizList/core/querySolution';
import {LoadingStatus} from '@src/components/LoadingContainer';
import {QueryListSolution} from '@src/components/QueryListSolution';
import {ListSolutionPresenter} from '@src/components/QueryListSolution/listSolutionPresenter';
import {IQueryColumnItem} from '@src/interfaces/queryColumn';
import {IQuerySchema, IQuerySchemaItem} from '@src/interfaces/queryScheme';
import {FieldPathUtils} from '@src/utils/fieldPath';
import {Modal} from 'antd';
import {runInAction} from 'mobx';
import {observer} from 'mobx-react';
import React from 'react';
import style from './style.module.scss';

export interface IQuerySchemaList {
  entityName: string;
  querySchemaItems: Array<IQuerySchemaItem>;
  querySolution: QuerySolution;
}

export interface IQueryActionModal {
  presenter: BizListPresenter;
  onOk: () => void;
}

@observer
export class QuerySchemaList extends React.Component<IQuerySchemaList> {
  onChange = (key: string, values: Array<any>) => {};

  onValueTypeChange = (path: string, value: string) => {};

  getBizType = (item: IQuerySchemaItem) => {
    const pathInfo = FieldPathUtils.getPathInfo(this.props.entityName, item.path);
    return pathInfo.bizType;
  };

  getEnumType = (item: IQuerySchemaItem) => {
    const pathInfo = FieldPathUtils.getPathInfo(this.props.entityName, item.path);
    if (pathInfo.bizType === 'enum') {
      return pathInfo.enumType;
    }
    return undefined;
  };

  render () {
    const {querySchemaItems, querySolution, entityName} = this.props;
    return (
      <div className={style[ 'queryAction' ]}>
        {querySchemaItems.map((item) => {
          const querySolutionItem = querySolution.getByPath(item.path);
          const bizType = this.getBizType(item);
          const Comp = getQuerySchemaInput(bizType, querySolutionItem.valueType) as any;
          const props = {
            value: querySolutionItem.values,
            className: style[ 'queryAction-item-values' ],
            onChange: querySolutionItem.updateValues,
            enumName: this.getEnumType(item)
          };
          return (
            <div className={style[ 'queryAction-item' ]}>
              <div className={style[ 'queryAction-item-label' ]}>{item.displayName}:</div>
              <div className={style[ 'queryAction-item-body' ]}>
                <ValueTypeSelect
                  className={style[ 'queryAction-item-valueType' ]}
                  value={querySolutionItem.valueType}
                  onChange={querySolutionItem.updateValueType}
                  bizType={bizType}
                  path={item.path}
                  entityName={entityName}
                />
                <Comp {...props} />
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

@observer
export class QueryActionModal extends React.Component<IQueryActionModal> {
  private presenter: ListSolutionPresenter;

  constructor (props: IQueryActionModal) {
    super(props);
    this.presenter = new ListSolutionPresenter({
      entityName: this.props.presenter.entityName,
      queryColumn: this.props.presenter.queryColumn,
      querySchema: this.props.presenter.querySchema
    });
  }

  private onSave = () => {
    const {values, valueTypes} = this.presenter.querySolution.toJSON();
    this.props.presenter.querySolution.setValues(values);
    this.props.presenter.querySolution.setValueTypes(valueTypes);
  };

  private onOk = () => {
    this.onSave();
    this.props.onOk && this.props.onOk();
  };

  render () {
    const {...reset} = this.props;
    return (
      <Modal title="查询方案" {...reset} width={800} okText="确认" cancelText="取消" onOk={this.onOk}>
        {this.presenter.loadingStatus === LoadingStatus.Complete ? (
          <QueryListSolution presenter={this.presenter} />
        ) : null}
      </Modal>
    );
  }
}
