import {Modal} from "antd";
import {FC, useCallback, useMemo, useState} from "react";
import {EmbedTinyCodeModalProps} from "./types";
import Refer from "@src/bizForm/components/Refer";
import EN_TinyCode from "@packages/gen/types/entities/TinyCode";
import { genReferOptions } from "@src/pages/devtool/bizForm/form/metadata";
import { ENUM_Entity_TinyCode } from "@src/packages/gen/constants/enums/entityName";

const EmbedTinyCodeModal: FC<EmbedTinyCodeModalProps> = (props) => {

    const [tinyCode, setTinyCode] = useState<EN_TinyCode>();

    const onOk = useCallback(() =>{
        props.onOk(tinyCode)
    }, [tinyCode]);

    const referOptions = useMemo(
      () => genReferOptions(ENUM_Entity_TinyCode),
      [],
    );
  
    
  return (
    <Modal open={props.open} onCancel={props.onCancel} onOk={onOk} title={"选择代码片段"}>
      <Refer
        entityName={ENUM_Entity_TinyCode}
        referOptions={referOptions}
        value={tinyCode}
        onChange={(tinyCode: EN_TinyCode) => setTinyCode(tinyCode)}
      />
    </Modal>
  );
};

export default EmbedTinyCodeModal;

// https://yanthink.github.io/pingfan.ts/#/markdown-editor