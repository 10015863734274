import {Input} from "antd";
import {toJS} from "mobx";
import {observer} from "mobx-react";
import React, {useMemo, useRef, useState} from "react";
import _get from "lodash/get";
import {EllipsisOutlined, PlusOutlined} from "@ant-design/icons";
import classnames from "classnames";
import _uniqBy from "lodash/uniqBy";
import {Modals} from "@src/components/Modals";
import AdvanceReferModal from "./AdvanceReferModal";
import VirtualTable from "./VirtualTable";
import RCTooltip from "rc-tooltip";
import "rc-tooltip/assets/bootstrap_white.css";
import "./style.scss";
import {IMetadataReferOptions} from "@src/interfaces/metadata";
import {ReferDataLoader} from "@src/http/referDataLoader";
import {EntityFieldViewer} from "../EntityFieldViewer";
import { metadata } from "@src/metadata/metadata";

const DEFAULT_width = 200; // 默认宽度


export interface ISelectTable<T> {
  entityName: string;
  objectTypeId?: string;
  value: any;
  onChange: (value: T) => void;
  displayFieldName?: string; // 在input框中显示的字段
  className?: string;
  id?: string;
  disabled?: boolean;
  onCreate?: () => void;
  referOptions: IMetadataReferOptions;
}


// @observer
function SingleSelectTable<T extends Record<string, any>>(props: ISelectTable<T>) {
  const _ref = useRef<HTMLDivElement>();

  const [showTable, setShowTable] = useState(false);

  const dataloader: ReferDataLoader<T> = useMemo(() => {
    return new ReferDataLoader({
      entityName: props.entityName,
      objectTypeId: props.objectTypeId,
      queryFields: props.referOptions.queryColumn.items.map((item) => item.path),
    });
  }, [props]);

  const displayFieldName = () => {
    return props.displayFieldName || "name";
  };

  const hiddenTable = (e: any) => {
    if (_ref.current && !_ref.current.contains(e.target)) {
      closeTable();
    }
  };

  const tableBodyScroll = (rect: {top: number; totalHeight: number; clientHeight: number}) => {
    if (rect.totalHeight - rect.top - rect.clientHeight <= 80) {
      if (!dataloader.loading) {
        dataloader.query();
      }
    }
  };

  const getColumns = () => {
    return props.referOptions.queryColumn.items.map((item) => ({
      title: item.displayName,
      dataIndex: item.path,
      ellipsis: true,
      // width: 100,
      render: (value: any) => {
        const field = metadata.getEntity(props.entityName).getFieldLastNode(item.path)
        return (
          <EntityFieldViewer
            entityName={props.entityName}
            value={value}
            path={item.path}
            title={item.displayName}
            bizType={field.bizType}
          />
        );
      },
    }));
  };

  // 关闭下拉table
  const closeTable = () => {
      setShowTable(false);
      dataloader.clear();
      document.removeEventListener("click", hiddenTable, false);
  };

  const rowOnClick = (record: T) => {
    props.onChange && props.onChange(record);
    closeTable();
  };

  // 行样式
  const rowClassName = (record: T, index: number, indent: number) => {
    if (props.value && props.value.id === record.id) {
      // 行被选中
      return "select-table-row_selected";
    }

    return "select-table-row";
  };

  const handleOnFocus = () => {
    if (!showTable) {
      setShowTable(true);
      dataloader.query();
      document.addEventListener("click", hiddenTable, false);
    }
  };

  const handleOnChange = (e: any) => {
    handleOnFocus();
    props?.onChange(undefined);
  };

  const handleCreate = () => {
    props.onCreate?.();
  };

  const handleAdvanceRefer = (e: React.MouseEvent<HTMLSpanElement>): void => {
    // 阻止向上冒泡
    e.stopPropagation();
    const {value, disabled, entityName, objectTypeId, referOptions} = props;

    if (disabled) {
      return;
    }
    closeTable();

    Modals.show(AdvanceReferModal, {
      value,
      onCreate: handleCreate,
      entityName,
      objectTypeId,
      referOptions,
      onOk: props.onChange,
    });
  };


  return (
    <div ref={_ref} id={props.id} className={classnames("select-table", props.className)}>
      <RCTooltip
        visible={showTable}
        showArrow={false}
        placement="bottomLeft"
        trigger={["click"]}
        destroyTooltipOnHide
        overlay={
          showTable ? (
            <div className={"select-table-body"}>
              <div className={"select-table-body-list"}>
                <VirtualTable
                  virtual
                  loading={dataloader.loading}
                  size="small"
                  rowKey="id"
                  pagination={false}
                  bordered={true}
                  columns={getColumns()}
                  dataSource={toJS(dataloader.data) || []}
                  scroll={{y: 200, x: 300}}
                  rowClassName={rowClassName}
                  onRow={(record) => {
                    return {
                      onClick: () => rowOnClick(record), // 点击行
                      onDoubleClick: (event) => {},
                      onContextMenu: (event) => {},
                      onMouseEnter: (event) => {}, // 鼠标移入行
                      onMouseLeave: (event) => {},
                    };
                  }}
                  onScroll={tableBodyScroll}
                />
                <div className={"select-table-actions"}>
                  <a className={"select-table-actions-add"} onClick={handleCreate}>
                    <PlusOutlined></PlusOutlined>
                  </a>
                </div>
              </div>
            </div>
          ) : null
        }
      >
        <Input
          value={_get(props.value, displayFieldName(), undefined)}
          onFocus={handleOnFocus}
          onChange={handleOnChange}
          addonAfter={
            <span
              className={classnames("select-table-btn", {"select-table-btn-disabled": props.disabled})}
              onClick={handleAdvanceRefer}
            >
              <EllipsisOutlined rev={undefined} />
            </span>
          }
          disabled={props.disabled}
        />
      </RCTooltip>
    </div>
  );
}

export default observer(SingleSelectTable);
