import React from 'react';
import { IBizFormComponentOProps } from '../interface';
import { Count } from '../Count';
import { BetweenWrapper } from '../BetweenWrapper';

interface ICountBetween extends IBizFormComponentOProps {
    value: Array<number>
}

/**
 *整数-区间
 * @param props 
 * @returns 
 */
export function CountBetween(props: ICountBetween) {
    const value = Array.isArray(props.value) ? props.value : [];

    const onChange = (index: number, val: number) => {
        value[index] = val;
        props.onChange && props.onChange(value);
    }

    return <BetweenWrapper {...props} value={value} onChange={onChange} component={Count}/>
    // return <div style={{display: 'flex'}}>
    //     <Count {...props} value={value[0]} onChange={(val) => onChange(0, val)} />
    //     <span>-</span>
    //     <Count {...props} value={value[1]} onChange={(val) => onChange(1, val)} />
    // </div>
}
