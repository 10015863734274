import {BizFormPresenter} from "@src/bizForm/bizFormPresenter";
import {Button, Form, FormInstance} from "antd";
import {runInAction} from "mobx";
import {Observer, observer} from "mobx-react";
import React from "react";
import Section from "./Section";
import _get from "lodash/get";
import styles from "./style.module.scss";
import {FormMode} from "@src/bizForm/formMode";
import {FormHeader} from "./FormHeader";
import {RouteUtils} from "@src/router/routeUtils";
import {ModuleNames} from "@src/bizForm/modules/interface";
import {LifecycleEvent} from "@src/bizForm/lifecycleEvent";
import ChildSection from "./ChildSection";
import {metadata} from "@src/metadata/metadata";

interface IMasterFrom {
  // template: Template;
  presenter: BizFormPresenter;
}

/**
 * 渲染主表字段
 */
@observer
export class MasterFrom extends React.Component<IMasterFrom> {
  _ref = React.createRef<FormInstance>();

  private get formLayout() {
    const {
      presenter: {mode},
    } = this.props;
    if (mode === FormMode.View) {
      return "horizontal";
    }
    return "vertical";
  }

  private isShowFooter = () => {
    const {
      presenter: {mode},
    } = this.props;
    return [FormMode.Create, FormMode.Edit].includes(mode);
  };

  // @computed
  get getFormFields() {
    const {
      presenter: {model, form},
    } = this.props;
    const result: any = [];
    model.fields.forEach((col) => {
      const mstFormField = this.props.presenter.form.select(col.path);
      result.push({
        name: col.path,
        errors: mstFormField && mstFormField.errors,
        value: col.isCarry || col.isSystemField ? _get(form.getValue(), col.path, undefined) : mstFormField.value,
      });
    });

    return result;
  }

  onValuesChange = (changedValues: {[key: string]: any} = {}, values: any) => {
    const {
      presenter: {form},
    } = this.props;

    const entity = metadata.getEntity(this.props.presenter.entityName);
    runInAction(() => {
      Object.entries(changedValues).forEach(([fieldName, value]) => {
        if (entity.getField(fieldName).isItems) {
          Object.entries(value).forEach(([_id, obj]) => {
            Object.entries(obj).forEach(([key, value]) => {
              form.selectItemById(fieldName, _id).select(key).value = value;
            });
          });
        } else {
          form.select(fieldName).value = value;
        }
      });
    });
  };

  private onSave = () => {
    this.props.presenter.save();
  };

  private onCancel = () => {
    const {
      presenter: {mode, entityName, id},
    } = this.props;
    switch (mode) {
      case FormMode.Edit:
        RouteUtils.toForm(
          {
            entityName,
            objectTypeId: this.props.presenter.objectTypeId,
            mode: FormMode.View,
            id,
          },
          {replace: true},
        );
        break;
      case FormMode.Create:
        RouteUtils.toList({
          entityName,
          objectTypeId: this.props.presenter.objectTypeId,
        });
        break;
      default:
        break;
    }
  };

  private onReset = () => {
    this.props.presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.reloadBizForm);
  };

  render() {
    const {
      presenter: {template, mode, form},
      presenter,
    } = this.props;
    return (
      <Observer>
        {() => (
          <Form
            // layout={template.layout as FormLayout}
            ref={this._ref}
            layout={this.formLayout}
            scrollToFirstError={true} // 提交失败自动滚动到第一个校验失败的位置
            size={"middle"}
            className={styles["masterForm"]}
            fields={this.getFormFields}
            onValuesChange={this.onValuesChange}
          >
            <FormHeader presenter={presenter} />
            <div className={styles["masterForm-body"]}>
              {template.master.map((section, index) => (
                <Section key={index} presenter={presenter} mode={mode} template={section} />
              ))}
              {template.childTables.map((section) => (
                <ChildSection key={section.path} presenter={presenter} mode={mode} template={section} />
              ))}
            </div>
            {this.isShowFooter() ? (
              <div className={styles["masterForm-footer"]}>
                <div></div>
                <div className={styles["actions"]}>
                  <Button size="small" onClick={this.onReset}>
                    重置
                  </Button>
                  <Button size="small" onClick={this.onCancel}>
                    取消
                  </Button>
                  <Button type="primary" size="small" onClick={this.onSave}>
                    保存
                  </Button>
                </div>
              </div>
            ) : null}
          </Form>
        )}
      </Observer>
    );
  }
}
