import {IDisposer} from "@src/MST-Form/interface";
import {MSTForm} from "@src/MST-Form/MSTForm";
import {autobind} from "core-decorators";
import {BizFormPresenter} from "./bizFormPresenter";
import {BizFormItemConfig} from "./interface";
import { BizFormItemActionManager } from "@src/bizAction/bizFormItemActionManager";
import { IValidator } from "@src/MST-Form/validators/interface";

export interface IBizFormPresenterOptions {
  // entityName: string;
}

@autobind
export abstract class BizFormItemPresenter<T> {
  // 明细表关联的主表的字段
  abstract readonly logicPath: string;
  // 明细表对应的业务对象名称
  abstract readonly itemEntityName: string;

  public disposers: Array<IDisposer> = [];

  public config: BizFormItemConfig<T>;

  constructor(public readonly formPresenter: BizFormPresenter, public readonly options?: IBizFormPresenterOptions) {
    this.init();
  }

  init() {
    this.config = this._bizFormItemConfig();
  }

  // 数据行显示完成
  public onRowCreated(form: MSTForm<T>, disposers: IDisposer[]) {}

  // 添加行数据校验

  public getForm() {
    return this.formPresenter.form;
  }

  public getItemForm(data: Record<string, any>) {
    return this.formPresenter.form.selectItemById(this.logicPath, data._id);
  }

  // 获取所有行，包括已经删除的
  public getRows() {
    return this.getForm()?.select(this.logicPath);
  }

  // 获取所有未删除的行
  public getViews() {

    // return this.getRows()?.filter(row => )
  }
  // 删除行
  public removeByRowId(_id: string) {
    this.formPresenter.form.removeItem(this.logicPath, _id);
  }

  public $onRowCreated() {
    this.onRowCreated(this.getForm(), this.disposers);
  }
  /**
   *  明细表的配置
   *  每行的action可能不一样，所以每行的action在渲染时采取生成，
   * @param config: 配置
   * @param row : 行数据
   * @returns
   */
  public bizFormItemConfig(config: BizFormItemConfig<T>): BizFormItemConfig<T> {
    return config;
  }

  private _bizFormItemConfig(): BizFormItemConfig<T> {
    const defaultConfig: BizFormItemConfig<T> = {
      formItemActionManager: new BizFormItemActionManager(),  //
    };
    return this.bizFormItemConfig(defaultConfig);
  }

  public addValidator(path: string, validator: IValidator) {

  }
}
