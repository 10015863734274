
// 面试题难度
enum ENUM_InterviewQuestionDifficulty {
    
      // 简单
      Easy = "easy",
    
      // 中等
      Medium = "medium",
    
      // 困难
      Difficult = "difficult",
    
}
export default ENUM_InterviewQuestionDifficulty;
