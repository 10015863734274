import React from "react";
import {Outlet, RouteObject, createBrowserRouter} from "react-router-dom";
import BaseRoutes from "./baseRoutes";
import CustomeRoutes from "./customeRoutes";
import NoSiderRoutes from "./noSiderRoutes";
import AuthCheck from "@src/components/AuthCheck";
import {InitDataLoader} from "@src/components/InitDataLoader";
import {Layout} from "antd";
import {Nav} from "@src/Layout/Nav";
import Header from "@src/Layout/header";
import {Modals} from "@src/components/Modals";
import RouteTabsWrapper from "@src/components/RouterTabs/RouteTabsWrapper";
import RouterTabs from "@src/components/RouterTabs";
import RouteTabsList from "@src/components/RouterTabs/RouteTabsList";
const NotFound = React.lazy(() => import("@src/pages/404"));

const setRouteTabWrapper = (routes: RouteObject[]) => {
  return routes.map((route) => {
    const newRoute = {
      ...route,
    };
    if (newRoute.element) {
      newRoute.Component = () => <RouteTabsWrapper realComponent={route.element}>{null}</RouteTabsWrapper>;
    }
    if (newRoute.Component) {
      newRoute.Component = () => {
        return <RouteTabsWrapper realComponent={() => <route.Component />}>{null}</RouteTabsWrapper>;
      };
    }
    if (newRoute.children?.length) {
      newRoute.children = setRouteTabWrapper(newRoute.children);
    }
    return newRoute;
  });
};

const mainRoutes = setRouteTabWrapper([...BaseRoutes, ...CustomeRoutes]);

const AppRouter = createBrowserRouter([
  {
    element: (
      <>
        <Outlet />
        <Modals />
      </>
    ),
    children: [
      ...NoSiderRoutes,
      {
        element: (
          <RouterTabs>
              <AuthCheck>
                <InitDataLoader>
                  <Layout>
                    <Nav />
                    <Layout className="site-layout">
                      <Header></Header>
                      <RouteTabsList />
                      <Outlet />
                    </Layout>
                  </Layout>
                </InitDataLoader>
              </AuthCheck>
          </RouterTabs>
        ),
        children: mainRoutes,
      },
    ],
  },
  {
    path: "*",
    Component: NotFound,
    handle: {
      title: "页面不存在"
    }
  },
]);

export default AppRouter;
