import React from "react";
import Icon from "iconfont-extract-icon";

import { IconBaseProps } from "iconfont-extract-icon/lib/types";
import IconQuanpingSvg from "../../react-svgs/IconQuanping";

const IconQuanping = React.forwardRef<HTMLSpanElement, IconBaseProps>(
  (props, ref): JSX.Element => {
    return <Icon component={IconQuanpingSvg} ref={ref} {...props} />;
  }
);

export default IconQuanping;
