import React from "react";
import Icon from "iconfont-extract-icon";

import { IconBaseProps } from "iconfont-extract-icon/lib/types";
import IconFenpingSvg from "../../react-svgs/IconFenping";

const IconFenping = React.forwardRef<HTMLSpanElement, IconBaseProps>(
  (props, ref): JSX.Element => {
    return <Icon component={IconFenpingSvg} ref={ref} {...props} />;
  }
);

export default IconFenping;
