import {ValueType} from "@src/bizForm/components/QueryValueType/valueTypeMaps";
import {IEntity} from "@src/interfaces/entity";
import {IMetadataField, IMetadataReferOptions} from "@src/interfaces/metadata";
import {metadata} from "@src/metadata/metadata";
import {TreeNodeProps} from "antd";
import {makeAutoObservable} from "mobx";

export const genReferOptions = (entityName: string) => {
  return {
    entityName: entityName,
    queryColumn: {
      entityName,
      items: [
        {
          path: "id",
          displayName: "ID",
          visiable: true,
          isContextField: false,
          isDisabledSummary: false,
          fixed: "",
        },
        {
          path: "name",
          displayName: "名称",
          visiable: true,
          isContextField: false,
          isDisabledSummary: false,
          fixed: "",
        },
      ],
    },
    querySchema: {
      entityName,
      items: [
        {
          path: "id",
          valueType: ValueType.eq,
          displayName: "ID",
          isContextField: false,
          isDisabledSummary: false,
        },
        {
          path: "name",
          valueType: ValueType.like,
          displayName: "名称",
          isContextField: false,
          isDisabledSummary: false,
          
        },
      ],
      sorts: [
        {
          path: "createTime",
          isDesc: true,
        },
      ],
    },
  };
};

export const getDefaultReferOptions = (entityName: string, itemPath: string): IMetadataReferOptions | undefined => {
  const field = metadata.getEntity(entityName).getFieldLastNode(itemPath);
  if (field) {
    const entityName = field.referType;
    return genReferOptions(entityName);
  }
  return undefined;
};

export class Metadata {
  public id?: string;
  public fields: IMetadataField[] = [];
  public items: Record<string, IMetadataField[]> = {}; // key为明细对应的字段

  // refer查询相关配置
  public referOptions?: Record<string, IMetadataReferOptions> = {};

  public getField(path: string): IMetadataField {
    return this.fields.find((item) => item.path === path);
  }

  public getFields() {
    return this.fields;
  }

  public setFields(data: IMetadataField[]) {
    this.fields = data;
  }

  constructor(public entityName: string) {
    // makeAutoObservable(this);
  }

  public pathExist(path: string) {
    return this.fields.some((item) => item.path === path);
  }

  private createFieldFromMetadata(data: TreeNodeProps) {
    const field: IMetadataField = {
      path: data.key as string,
      displayName: [...data.ancestors.map((ancestor: IEntity) => ancestor.title), data.title].join("/"),
      alwaysHidden: false,
      required: false,
      readonly: data.isCarry || false, // 如果字段是携带，则不容许编辑，只能读
      isMulti: false,
      isSystemField: data.fieldData.isSystemField,
    };
    // 系统参数只能允许后台设置数据，前端不允许编辑
    if (data.fieldData.isSystemField) {
      field.readonly = true;
      field.required = false;
    }
    return field;
  }

  //  通过AsyncTree添加新的item数据
  public addFieldFormMetadata(data: TreeNodeProps) {
    const field = this.createFieldFromMetadata(data);
    this.fields.push(field);
    if (metadata.getEntity(this.entityName).getFieldLastNode(field.path).isRefer) {
      this.setDefaultReferOptions(field.path);
    }
  }

  // 更新item的数据
  public updateMetaDataField(path: string, key: string, value: string | number | boolean) {
    const index = this.fields.findIndex((item) => item.path === path);
    if (index > -1) {
      this.fields[index] = {
        ...this.fields[index],
        [key]: value,
      };
    }
  }

  // 删除item
  public removeMetaDataField(path: string) {
    this.fields = this.fields.filter((item) => item.path !== path);
    const field = metadata.getEntity(this.entityName).getField(path);
    if (field?.isItems) {
      Reflect.deleteProperty(this.items, path);
      this.removeReferOptions(path);
    }
  }

  private getItemFieldFromMetadata(data: TreeNodeProps) {
    return (data.key as string).split(".").slice(1).join("");
  }

  private createItemFieldFromMetadata(data: TreeNodeProps) {
    const item: IMetadataField = {
      path: this.getItemFieldFromMetadata(data),
      displayName: [...data.ancestors.map((ancestor: IEntity) => ancestor.title), data.title].join("/"),
      alwaysHidden: false,
      required: false,
      readonly: data.isCarry || false, // 如果字段是携带，则不容许编辑，只能读
      isMulti: false,
      isSystemField: data.fieldData.isSystemField,
    };
    // 系统参数只能允许后台设置数据，前端不允许编辑
    if (data.fieldData.isSystemField) {
      item.readonly = true;
      item.required = false;
    }
    return item;
  }

  // 添加明细的字段
  public addItemField(itemPath: string, data: TreeNodeProps) {
    if (!this.items[itemPath]) {
      this.items[itemPath] = [];
    }
    this.items[itemPath].push(this.createItemFieldFromMetadata(data));
  }

  public pathItemFieldExist(itemPath: string, data: TreeNodeProps) {
    if (!Reflect.has(this.items, itemPath)) {
      return false;
    }

    const path = this.getItemFieldFromMetadata(data);
    if (this.items[itemPath].some((item) => item.path === path)) {
      return true;
    }
    return false;
  }

  // 删除item
  public removeItemField(itemPath: string, data: TreeNodeProps) {
    if (!Reflect.has(this.items, itemPath)) {
      return;
    }
    const fields = this.items[itemPath];
    const path = this.getItemFieldFromMetadata(data);
    this.items[itemPath] = fields.filter((item) => item.path !== path);
  }
  // 添加默认的 referOptions
  public setDefaultReferOptions(itemPath: string) {
    const field = metadata.getEntity(this.entityName).getFieldLastNode(itemPath);
    if (field) {
      const entityName = field.referType;
      this.referOptions[itemPath] = getDefaultReferOptions(entityName, itemPath);
    }
  }
  // 删除 refer options
  private removeReferOptions(itemPath: string) {
    Reflect.deleteProperty(this.referOptions, itemPath);
  }
  // 更新 refer options
  public updateReferOptions(itemPath: string, data: Omit<IMetadataReferOptions, "entityName">) {
    this.referOptions[itemPath] = {
      ...this.referOptions[itemPath],
      ...data,
    };
  }
  // 获取referOptions
  public getReferOptions(itemPath: string) {
    // 这里暂时做一个补偿，兼容目前存在数据中没有referOptions的情况，防止保存

    if (!Reflect.has(this.referOptions, itemPath)) {
      this.setDefaultReferOptions(itemPath);
    }

    return this.referOptions[itemPath];
  }
}

export class MetaDataUtils {
  static createObservableMetadata(entityName: string): Metadata {
    return makeAutoObservable(MetaDataUtils.createMetaData(entityName));
  }
  static createMetaData(entityName: string): Metadata {
    return new Metadata(entityName);
  }

  static wrapperMetadata(data: Metadata) {
    const metadata = new Metadata(data.entityName);
    metadata.id = data.id;
    metadata.fields = data.fields || [];
    metadata.items = data.items || {};
    metadata.referOptions = data.referOptions || {};

    return makeAutoObservable(metadata);
  }
}
