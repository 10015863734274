import {memo} from "react";
import CodeEditor from "../CodeEditorBase";

export interface ICodeViewer {
  value: string;
}
const CodeViewer = (props: ICodeViewer) => {

  console.log(props);
  return <CodeEditor value={props.value} readonly/>
};

export default memo(CodeViewer);
