import {Input, Table, Tag} from 'antd';
import {toJS} from 'mobx';
import {observer} from 'mobx-react';
import React, {createRef} from 'react';
import _get from 'lodash/get';
import './style.scss';
import classnames from 'classnames';
import _uniqBy from 'lodash/uniqBy';

const DEFAULT_width = 200; // 默认宽度

interface EntityItem {
  id: string;
}

export interface IMutilSelectTable<T> {
  value: T[]; // 单选时就是对象，多选时就是数组
  onChange: (value: T[]) => void;
  onSearch: (value?: string) => void;
  dataSource: T[];
  fields: Array<{
    name: string;
    label: string;
  }>;
  displayFieldName?: string; // 在input框中显示的字段
  loading?: boolean;
  className?: string;
  id?: string;
  disabled?: boolean;
  onCreate?: () => void;
}

interface SMutilSelectTable {
  showTable: boolean;
}

@observer
export class MutilSelectTable<T> extends React.Component<IMutilSelectTable<T>, SMutilSelectTable> {
  _ref = createRef<HTMLDivElement>();

  get displayFieldName () {
    return this.props.displayFieldName || 'name';
  }

  constructor (props: IMutilSelectTable<T>) {
    super(props);
    this.state = {
      showTable: false
    };
  }

  componentDidMount () {
    document.addEventListener('click', this.hiddenTable, false);
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.hiddenTable, false);
  }

  private hiddenTable = (e: any) => {
    if (this._ref.current && !this._ref.current.contains(e.target)) {
      this.closeTable();
    }
  };

  private getColumns = () => {
    const {fields = []} = this.props;
    return _uniqBy(fields, 'name').map((field) => ({
      title: field.label,
      dataIndex: field.name.split('.'),
      ellipsis: true
    }));
  };

  // 关闭下拉table
  private closeTable = () => {
    this.setState({
      showTable: false
    });
  };

  private handleOnFocus = () => {
    if (!this.state.showTable) {
      this.setState({
        showTable: true
      });
      this.props.onSearch && this.props.onSearch();
    }
  };

  private handleOnChange = (e: any) => {
    this.handleOnFocus();
    this.props.onChange && this.props.onChange(undefined);
  };

  private onSelectChange = (selectedRowKeys: string[], selectedRows: T[]) => {
    this.props.onChange && this.props.onChange(selectedRows);
  };

  private getRowSelection = (): any => {
    return {
      selectedRowKeys: this.props.value.map((val) => _get(val, 'id')),
      onChange: this.onSelectChange
    };
  };

  render () {
    const {showTable} = this.state;
    return (
      <div ref={this._ref} id={this.props.id} className={classnames('select-table', this.props.className)}>
        {this.renderInput()}

        <div className={'select-table-body'}>
          {showTable ? (
            <div className={'select-table-body-list'}>
              {/* <VirtualTable */}
              <Table
                // loading={loading}
                size="small"
                rowKey="id"
                pagination={false}
                bordered={true}
                rowSelection={this.getRowSelection()}
                columns={this.getColumns()}
                dataSource={(toJS(this.props.dataSource) || []) as any}
                scroll={{y: 300}}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }

  renderInput = () => {
    const {value = [], disabled} = this.props;
    const {showTable} = this.state;
    return (
      <div
        className={classnames('mutil-select-input', {'mutil-select-input_disabled': disabled})}
        onClick={this.handleOnFocus}
        // onChange={this.handleOnChange}
      >
        {value.map((val) => (
          // @ts-ignore
          <Tag key={_get(val, 'id')} closable onClose={() => this.removeRecord(_get(val, 'id'))}>
            {_get(val as any, 'name')}
          </Tag>
        ))}
        {showTable && <Input size="small" className={'mutil-select-input_focus'} autoFocus disabled={disabled}/>}
      </div>
    );
  };

  private removeRecord = (id: string) => {
    const value = this.props.value.filter((val) => _get(val, 'id') !== id);
    this.props.onChange && this.props.onChange(value);
  };
}
