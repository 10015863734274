import EN_InterviewQuestion from "@packages/gen/types/entities/InterviewQuestion";
import {IBizAction} from "@src/bizAction/interface";
import {BizListPresenter} from "@src/bizList/bizListPresenter";
import {BizListConfig} from "@src/bizList/interface";
import {InterviewQuestionFormPresenter} from "./interviewQuestionFormPresenter";

export class InterviewQuestionListPresenter<T = EN_InterviewQuestion> extends BizListPresenter<T> {
  public bizFormPresenter = InterviewQuestionFormPresenter;

  // public listBizConfig(config: BizListConfig<T>): BizListConfig<T> {
  //   config.column = {};

  //   config.listRowActionManager.use((actions: Array<IBizAction>, data: any): Array<IBizAction> => {
  //     // if (data.id === 'db56a548-b6f8-4bd4-8886-051dcde9858b') {
  //     //   return [];
  //     // }
  //     return actions;
  //   });
  //   return config;
  // }
}
