
// TinyCodeScriptType 

// javascript 
export const ENUM_TinyCodeScriptType_javascript = "javascript"; 

// typescript 
export const ENUM_TinyCodeScriptType_typescript = "typescript"; 

// react(js) 
export const ENUM_TinyCodeScriptType_reactJS = "react-js"; 

// react(ts) 
export const ENUM_TinyCodeScriptType_reactTS = "react-ts"; 

