import {BizListPresenter} from "@src/bizList/bizListPresenter";
import { Modals } from "@src/components/Modals";
import {message} from "antd";
import UpdateModal from "./UpdateModal";
/**
 * 列表行使用
 */
export const UpdateFavicons = {
  key: "updateFavicons",
  label: "刷新favicon",
  options: {
    onClick: (presenter: BizListPresenter, data?: any) => {
      Modals.show(UpdateModal)
      
    },
  },
};
