import {IDisposer} from "@src/MST-Form/interface";
import {MSTForm} from "@src/MST-Form/MSTForm";
import {autobind} from "core-decorators";
import {BizFormPresenter} from "../../bizForm/bizFormPresenter";
import EN_Poetry from "@packages/gen/types/entities/Poetry";

@autobind
export class PoetryFormPresenter<T = EN_Poetry> extends BizFormPresenter<T> {
  public onFormCreated(form: MSTForm<T>, disposers: IDisposer[]) {
    // disposers.push(
    //     reaction(
    //         () => form.select("category").value,
    //         (value) => {
    //             form.select("name").disabled = true;
    //         }
    //     )
    // )
  }

  // public setInitFormData(): void {
  //   if (isNewFormMode(this.mode)) {
  //     this.initFormData = {
  //       categoryId: "1bdee5c4-3827-468e-a16a-0658f2dda198",
  //     };
  //   }
  // }
}
