import {autobind} from "core-decorators";
import {fetchMetadata, fetchTemplate} from "@src/http";
import {FormDataLoader} from "@src/http/formDataLoader";
import {metadata} from "@src/metadata/metadata";
import {BizFormPresenter} from "../bizFormPresenter";
import {LifecycleEvent} from "../lifecycleEvent";
import {BaseModule} from "./baseModule";
import {EventModule} from "./eventModule";
import {ModuleNames} from "./interface";
import {ModulePropertyWrapper, ModuleWrapper} from "./moduleManager";
import { isNewFormMode} from "../formMode";
import {MutilObjectDataLoader, ObjectType} from "@src/http/mutilObjectDataLoader";
import { MetaDataUtils } from "@src/pages/devtool/bizForm/form/metadata";

// 整个页面的初始化模块
@autobind
@ModuleWrapper(ModuleNames.InitModule)
export class InitModule extends BaseModule {
  @ModulePropertyWrapper(ModuleNames.Presenter) private presenter: BizFormPresenter;
  @ModulePropertyWrapper(ModuleNames.EventModule) private eventModule: EventModule;

  public apply() {
    this.eventModule.on(LifecycleEvent.onModuleReady, this.loadData);
  }

  // 加载tempalte、metadata、formData数据
  private async loadData() {
    const {presenter} = this;
    // @TODO: 考虑抽离出去
    const [metadata, template] = await Promise.all([
      fetchMetadata(presenter.entityName),
      fetchTemplate(presenter.entityName),
    ]);
    // 获取form表单数据

    presenter.metadata = MetaDataUtils.wrapperMetadata(metadata as any);
    presenter.template = template as any;
    if (isNewFormMode(presenter.mode)) {
      await this.loadFormReferData();
    } else {
      const formDataLoader: FormDataLoader = new FormDataLoader({
        entityName: presenter.entityName,
        presenter,
        // @TODO: 暂时写死
        id: presenter.id,
      });

      await formDataLoader.query();

      presenter.initFormData = formDataLoader.data || {};
    }

    this.eventModule.dispatch(LifecycleEvent.onLoadedInitData);
  }

  // 在创新模式时从 initFormData 中找出refer数据，让后向服务器发送请求用来初始化数据
  private async loadFormReferData() {
    if (this.presenter.initFormData && typeof this.presenter.initFormData === "object") {
      const objectList: ObjectType[] = [];
      const entity = metadata.getEntity(this.presenter.entityName);
      [...Reflect.ownKeys(this.presenter.initFormData)].forEach((path) => {
        const entityField = entity.getField(path as string);
        if (entityField) {
          if (entityField.isRefer) {
            objectList.push({
              id: this.presenter.initFormData[path],
              entityName: entityField.referType,
              aliasName: entityField.aliasName,
              queryFields: [],
            } as ObjectType);
          }
        }
      });
      if (objectList.length) {
        const loader = new MutilObjectDataLoader({
          objectList: objectList,
        });

        await loader.query();

        Object.entries(loader.data || {}).forEach(([key, value]) => {
          this.presenter.initFormData[key] = value;
        });
      }
    }
  }
}
