import React from "react";
import _cloneDeep from "lodash/cloneDeep";
import {IBizActionManager} from "@src/bizAction/interface";
import { QuerySolution, QuerySolutionItem } from "./core/querySolution";

export interface BizListConfig<T> {
  // [path: string]: {
  //   cellRender: (val: any, record: any) => React.ReactNode;
  // };
  // actionBar: Array<IBizAction>; // 列表操作按钮
  // rowActionBar: Array<IBizRowAction>; // 行操作按钮

  hiddenListAction?: boolean;
  hiddenQickQuery?: boolean;
  column?: {
    [path: string]: {
      cellRender?: (val: any, record: any) => React.ReactNode;
    };
  };
  listActionManager: IBizActionManager<T>;
  listRowAction?: boolean; // 是否显示table的操作列
  listRowActionManager: IBizActionManager<T>;
  querySolution?: {
    initValues?: Record<string, unknown>; // 设置默认值
    extraItems?: (querySolution: QuerySolution) => QuerySolutionItem[] | undefined; // 设置额外的查询相
  };
  allowRowSection?: boolean; // 是否开启多行选择, 默认false
}

export interface ListData {
  data: Array<any>;
  count: number;
  pageSize: number;
  pageNo: number;
  hasNextPage?: boolean;
}

export const defaultListData: ListData = {
  data: [],
  pageSize: 20,
  pageNo: 1,
  count: 0,
  hasNextPage: false,
};

export const getDefaultListData = () => _cloneDeep(defaultListData);
