import {BizFormField} from "@src/bizForm/core/model/bizFormField";
import {FormMode} from "@src/bizForm/formMode";
import {MSTFormField} from "@src/MST-Form/MSTFormField";
import {Col, Form} from "antd";
import {observer} from "mobx-react";
import React from "react";
import { NamePath } from "antd/lib/form/interface";
import styles from "./style.module.scss";
import classNames from "classnames";

export interface FormFieldElementProps {
  key: string;
  path: string;
  field: MSTFormField;
  model: BizFormField;
  mode: FormMode;
  enumType?: string;
  referType?: string;
  presenter?: any;
  isItemField?: boolean; // 是否为明细表字段，明细表不需要显示label，边框等数据
  itemPath?: string;
  itemId?: string; // item 数据项的 _id
  className?: string;
}

const COL_SPAN_STEP = 6;

function getColSpan(colSpan: number = 1) {
  return colSpan * COL_SPAN_STEP;
}

@observer
export class FormFieldElement extends React.Component<
  FormFieldElementProps & {children?: (props: FormFieldElementProps) => React.ReactNode | React.ReactNode}
> {
  private getValuePropName = (bizType: string) => {
    // return bizType === 'bool' ? 'checked' : 'value'
    return "value";
  };

  private getProps = (): {
    label: string;
    path: NamePath;
    valuePropName: string;
    required: boolean;
    mode: FormMode;
    colSpan: number;
    className?: string;
  } => {
    const {
      model: {colSpan, path, bizType},
      model,
      field,
      mode,
      isItemField,
      itemPath,
      itemId,
      className,
    } = this.props;

    return {
      label: model.label || (field && field.label),
      path: isItemField ? [itemPath, itemId, path] : path,
      valuePropName: this.getValuePropName(bizType),
      required: field && typeof field.required !== "undefined" ? field.required : model.required,
      mode,
      colSpan,
      className,
    };
  };

  render() {
    const {children, field, isItemField} = this.props;
    const {colSpan, label, path, valuePropName, required, className} = this.getProps();

    const renderProps = {
      label: isItemField ? undefined : label,
      name: path,
      className: classNames(isItemField ? styles["item-field-wrapper"] : styles["field-wrapper"], className),
      valuePropName: valuePropName,
      required,
      initialValue: field ? field.value : undefined,
    };
    if (isItemField) {
      return <Form.Item {...renderProps}>{typeof children === "function" ? children(this.props) : children}</Form.Item>;
    }

    return (
      <Col span={getColSpan(colSpan)}>
        <Form.Item {...renderProps}>{typeof children === "function" ? children(this.props) : children}</Form.Item>
      </Col>
    );
  }
}
