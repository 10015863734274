import React from 'react';
import _get from 'lodash/get';

const toString = Object.prototype.toString;

export interface ITextViewer {
    value: string;
}
/**
 * 文本的查看模式内容组件
 */
export const TextViewer = (props: ITextViewer): any => {
    return <span title={props.value}>{props.value}</span>
}