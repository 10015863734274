import * as monaco from "monaco-editor";
import Editor, { Monaco, loader } from "@monaco-editor/react";
import { FC, useCallback, useMemo, useState } from "react";
import { CodeEditorProps, MyEditorState } from "./types";
import classNames from "classnames";
import Toolbar from "./tools";
import { useRef } from "react";
import { MarkdownViewer } from "../MarkdownViewer";
import style from "./style.module.scss";

loader.config({ monaco });

// https://github.com/suren-atoyan/monaco-react
const MyEditor: FC<CodeEditorProps> = (props) => {
  const [loaded, setLoaded] = useState(false);
  const containerRef = useRef<HTMLDivElement>();
  const editorRef = useRef(null);
  const monacoRef = useRef(null);

  const [state, setState] = useState<MyEditorState>({
    splitScreen: false,
  });

  function handleEditorDidMount(editor: monaco.editor.IStandaloneCodeEditor, monaco: Monaco) {
    // here is the editor instance
    // you can store it in `useRef` for further usage
    editorRef.current = editor;
    monacoRef.current = monaco;
    setLoaded(true);
  }

  const handleChange = useCallback((value: string) => {
    props.onChange(value);
  }, []);

  const handleSetState = useCallback(
    <T extends keyof MyEditorState>(key: T, value: MyEditorState[T]) => {
      setState({
        ...state,
        [key]: value,
      });
    },
    [state],
  );

  const options = useMemo((): monaco.editor.IStandaloneEditorConstructionOptions => {

    const _options: monaco.editor.IStandaloneEditorConstructionOptions = {
      wordWrap: "on",// 自动换行
    }
    if (props.readonly) {
      _options.readOnly = true;
      _options.renderValidationDecorations = 'on'
    }
    return _options;
  }, [props.readonly])

  return (
    <div className={classNames(style["markdown-editor"], props.className)} ref={containerRef}>
      {props.showToolbar ? (
      <Toolbar editor={editorRef.current} container={containerRef.current} setState={handleSetState} state={state} />
    ) : null}
      <div id="markdown-editor-main" className={style["markdown-editor-main"]}>
        <div className={style["markdown-editor-content"]}>
        <Editor
          language={props.language}
          defaultValue=""
          value={props.value || ""}
          onChange={handleChange}
          loading={!loaded}
          theme={props.theme || "vs-dark"}
          onMount={handleEditorDidMount}
          options={options}
        />
        </div>
        {state.splitScreen ? (
          <div className={style["markdown-editor-viewer"]}>
            <MarkdownViewer value={props.value} />
          </div>
        ) : null}
      </div>
    </div>
  );
};


export default MyEditor;