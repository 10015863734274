import {ListActionBar} from '@src/bizAction/ActionBar/ListActionBar';
import { metadata } from '@src/metadata/metadata';
import React from 'react';
import {BizListPresenter} from '../bizListPresenter';

import style from './style.module.scss';

interface IBizListHeader {
  presenter: BizListPresenter;
}
/**
 * 用于渲染table
 */
export class BizListHeader extends React.Component<IBizListHeader> {
  componentDidMount () {}

  render () {
    const {presenter} = this.props;
    return (
      <div className={style[ 'biz-list-header' ]}>
        <div >
          <h3 className={style[ 'biz-list-header-title' ]}>{metadata.getEntity(presenter.entityName).title}列表 </h3>
        </div>
        {this.renderActions()}
      </div>
    );
  }

  renderActions = () => {
    return <ListActionBar presenter={this.props.presenter} />;
  };
}
