import {ModalsComponentProps} from "@src/components/Modals";
import {SimpleUpload} from "@src/components/Upload";
import {Modal} from "antd";
import {FC, useCallback, useState} from "react";

const UploadImageModal: FC<ModalsComponentProps> = (props) => {
  const [value, setValue] = useState<string>();

  const onOk = useCallback(() => {
    props.onOk(value);
  }, [value]);

  return (
    <Modal open={props.open} onCancel={props.onCancel} onOk={onOk} title={"添加图片"}>
      <SimpleUpload value={value} onChange={(value) => setValue(value)} />
    </Modal>
  );
};

export default UploadImageModal;
