import EN_Article from "@packages/gen/types/entities/Article";
import {IDisposer} from "@src/MST-Form/interface";
import {MSTForm} from "@src/MST-Form/MSTForm";
import {autobind} from "core-decorators";
import {BizFormPresenter} from "../../bizForm/bizFormPresenter";
import {FormMode} from "@src/bizForm/formMode";
import {
  F_TinyCode_cssType,
  F_TinyCode_htmlType,
  F_TinyCode_scriptType,
} from "@src/packages/gen/constants/entities/TinyCode";
import {ENUM_TinyCodeHtmlType_html} from "@src/packages/gen/constants/enums/TinyCodeHtmlType";
import {ENUM_TinyCodeCssType_css} from "@src/packages/gen/constants/enums/TinyCodeCssType";
import {ENUM_TinyCodeScriptType_javascript} from "@src/packages/gen/constants/enums/TinyCodeScriptType";

@autobind
export default class FormPresenter<T = EN_Article> extends BizFormPresenter<T> {
  public onFormCreated(form: MSTForm<T>, disposers: IDisposer[]) {
    if (this.mode === FormMode.Create) {
      form.select(F_TinyCode_htmlType).value = ENUM_TinyCodeHtmlType_html;
      form.select(F_TinyCode_cssType).value = ENUM_TinyCodeCssType_css;
      form.select(F_TinyCode_scriptType).value = ENUM_TinyCodeScriptType_javascript;
    }
  }
}
