import {ENUM_Entity_Favicon} from "@packages/gen/constants/enums/entityName";
import {http} from "./http";

export function recommendFavicon(data: {id: string; recommended: boolean}) {
  return http({
    url: `/${ENUM_Entity_Favicon}/recommend`,
    method: "post",
    data,
  });
}
