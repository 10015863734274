import {config} from "./config";
import styles from "./style.module.scss";
import {EditorTool, ToolbarProps} from "./types";
import {FC, useCallback} from "react";

const Item: FC<EditorTool & ToolbarProps> = (props) => {
  const handleClick = useCallback(() => {
    props.onClick({
      editor: props.editor,
      container: props.container,
      setState: props.setState,
      state: props.state,
    });
  }, [props]);
  return (
    <div className={styles["tool-item"]} onClick={handleClick}>
      {props.icon}
    </div>
  );
};

const Toolbar: FC<ToolbarProps> = (props) => {
  return (
    <div className={styles["code-editor-toolbar"]}>
      {config.map((item) => (
        <Item
          key={item.id}
          {...item}
          editor={props.editor}
          container={props.container}
          setState={props.setState}
          state={props.state}
        />
      ))}
    </div>
  );
};
export default Toolbar;
