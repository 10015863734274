import React from 'react';
import {Input as AntdInput, InputProps} from 'antd';
import {IBizFormComponentOProps} from '../interface';
import _get from 'lodash/get';
import {metadata} from '@src/metadata/metadata';
import moment from 'moment';

interface ICarry extends IBizFormComponentOProps {}
const getEntityField = (entityName: string, path: string) => {
  const entityField = metadata.getEntity(entityName).getFieldLastNode(path);
  if (!entityField) {
    console.error('path is not exist in entity:' + entityName);
    return null;
  }
  return entityField;
};

// 携带字段
export function Carry (props: ICarry) {
  let value = props.value;
  if (props.value && typeof props.value === 'object') {
    value = _get(value, 'name');
  }

  const entityField = getEntityField(props.model.entityName, props.model.path);
  if (value && entityField) {
    switch (entityField.bizType) {
      case 'datetime':
        value = moment(value).format('YYYY-MM-DD HH:mm:ss');
        break;
      case 'date':
        value = moment(value).format('YYYY-MM-DD');
        break;
    }
  }

  return <AntdInput {...props} value={value} disabled />;
}
