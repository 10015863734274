
// Favicon 

// ID 
export const F_Favicon_id = "id"; 

// 站点名称 
export const F_Favicon_name = "name"; 

// 域名 
export const F_Favicon_domain = "domain"; 

// 站点描述 
export const F_Favicon_description = "description"; 

// 关键词 
export const F_Favicon_keywords = "keywords"; 

// 推荐语 
export const F_Favicon_recommendation = "recommendation"; 

// favicon明细 
export const F_Favicon_faviconItems = "faviconItems"; 

// 是否推荐 
export const F_Favicon_recommended = "recommended"; 

// 来源方式 
export const F_Favicon_sourceMethod = "sourceMethod"; 

// 是否删除 
export const F_Favicon_isDeleted = "isDeleted"; 

// 更新时间 
export const F_Favicon_updateTime = "updateTime"; 

// 创建时间 
export const F_Favicon_createTime = "createTime"; 

// 最近编辑人 
export const F_Favicon_operatorId = "operatorId"; 

// 创建者 
export const F_Favicon_creatorId = "creatorId"; 

// 租户 
export const F_Favicon_tenantId = "tenantId"; 

