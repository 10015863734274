import React from "react";
import Icon from "iconfont-extract-icon";

import { IconBaseProps } from "iconfont-extract-icon/lib/types";
import IconCloseSvg from "../../react-svgs/IconClose";

const IconClose = React.forwardRef<HTMLSpanElement, IconBaseProps>(
  (props, ref): JSX.Element => {
    return <Icon component={IconCloseSvg} ref={ref} {...props} />;
  }
);

export default IconClose;
