import {autobind} from "core-decorators";
import {LifecycleEvent} from "../lifecycleEvent";
import {BaseModule} from "./baseModule";
import {EventModule} from "./eventModule";
import {ModuleNames} from "./interface";
import {ModulePropertyWrapper, ModuleWrapper} from "./moduleManager";
import {BizListPresenter} from "@src/bizList/bizListPresenter";
import localforage from "localforage";
import {toJS} from "mobx";
import OperatorStore from "@src/operator";

export const QUERY_SOLUTION_PREFIX = "__QUERY_SOLUTION__";
// 整个页面的初始化模块
@autobind
@ModuleWrapper(ModuleNames.ListModule)
export class ListModule extends BaseModule {
  @ModulePropertyWrapper(ModuleNames.Presenter)
  private presenter: BizListPresenter;
  @ModulePropertyWrapper(ModuleNames.EventModule)
  private eventModule: EventModule;

  // 选中行的key
  private selectedRowKeys: string[] = [];

  public apply() {
    this.eventModule.on(LifecycleEvent.queryListData, this.queryListData);
    this.eventModule.on(LifecycleEvent.resetPageNoQueryList, this.resetPageNoQueryListData);
    this.eventModule.on(LifecycleEvent.resetQueryListSolutionValues, this.resetQueryListQueryValues);
  }

  // 查询数据
  private async queryListData() {
    const {presenter} = this;
    this.saveQueryOptions();
    presenter.dataLoader.queryBuilder.setCriteriaStr(this.presenter.querySolution.getQueryStr());
    await presenter.dataLoader.query();
  }

  // 重置分页后查询
  private async resetPageNoQueryListData() {
    this.resetPageNo();
    await this.queryListData();
  }

  // 重置查询列表的查询条件
  private resetQueryListQueryValues() {
    this.presenter.querySolution.resetValues();
    this.resetPageNo();
    this.queryListData();
  }

  private resetPageNo() {
    this.presenter.listData.pageNo = 1;
  }

  private saveQueryOptions() {
    const items = this.presenter.querySolution.items;
    const values = items.reduce((prev, current) => {
      prev[current.path] = toJS(current.values);
      return prev;
    }, {} as any);

    const valueTypes = items.reduce((prev, current) => {
      prev[current.path] = current.valueType;
      return prev;
    }, {} as any);

    if (this.presenter.querySchema.id) {
      localforage.setItem(
        `${OperatorStore.getID()}_${this.presenter.querySchema.id}_${QUERY_SOLUTION_PREFIX}options`,
        {
          values,
          valueTypes,
        },
        (err) => {
          if (err) {
            console.log("存储查询条件失败");
            console.error(err);
          }
        },
      );
    }
  }

  public setSelectedRowKeys(keys: string[]) {
    this.selectedRowKeys = keys;
  }

  public getSelectedRowKeys() {
    return this.selectedRowKeys;
  }
}
