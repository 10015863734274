import {CloudUploadOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {getUpyunConfig} from "@src/common/upload";
import {message, Upload} from "antd";
import React, {useMemo, useState} from "react";
import type {UploadChangeParam} from "antd/es/upload";
import type {RcFile, UploadFile, UploadProps} from "antd/es/upload/interface";
import classnames from "classnames";

interface IMyUpload {
  onChange?: (data?: any) => void;
  value?: any;
  dragger?: boolean;
  className?: string;
}
const getBase64 = (img: RcFile, callback: (url: string) => void) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: RcFile) => {
  // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  // if (!isJpgOrPng) {
  //   message.error('You can only upload JPG/PNG file!');
  // }
  const isLt10M = file.size / 1024 / 1024 < 10;
  if (!isLt10M) {
    message.error("Image must smaller than 10MB!");
  }
  // return isJpgOrPng && isLt10M;
  return isLt10M;
};

export const SimpleUpload = (props: IMyUpload) => {
  const {value, onChange} = props;
  const upyunOptions = useMemo(() => getUpyunConfig(), []);
  const [loading, setLoading] = useState(false);

  const handleChange: UploadProps["onChange"] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj as RcFile, (url) => {
        setLoading(false);
        onChange && onChange(upyunOptions.url + info.file.response.url);
      });
    }

    if (info.file.status === "error") {
      setLoading(false);
    }
  };

  const uploadButton = <div>{loading ? <LoadingOutlined rev={undefined} /> : <PlusOutlined rev={undefined} />}</div>;

  const draggerUploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined rev={undefined} />
      ) : (
        <div>
          <p className="ant-upload-drag-icon">
          <CloudUploadOutlined rev={undefined} />
          </p>
          <p className="ant-upload-hint">点击或者拖动图片进行上传</p>
        </div>
      )}
    </div>
  );

  const uploadProps = useMemo(
    (): UploadProps => ({
      name: upyunOptions.name,
      method: "POST",
      listType: "picture-card",
      className: classnames("avatar-uploader", props.className),
      showUploadList: false,
      action: upyunOptions.action,
      beforeUpload: beforeUpload,
      onChange: handleChange,
      headers: upyunOptions.headers,
      data: {
        authorization: upyunOptions.upyun?.token,
        policy: upyunOptions.upyun?.policy,
      },
    }),
    [],
  );

  if (props.dragger) {
    return (
      <Upload.Dragger {...uploadProps}>
        {value ? <img src={value} style={{width: "100%"}} loading="lazy" /> : draggerUploadButton}
      </Upload.Dragger>
    );
  }

  return (
    <Upload {...uploadProps}>
      {value ? <img src={value} style={{width: "100%"}} loading="lazy" /> : uploadButton}
    </Upload>
  );
};
