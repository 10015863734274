import { TenantFormPresenter} from "@src/pages/tenant";
import { bizFormRoute } from "../bizFormRouter";
import { ENUM_Entity_Tenant } from "@src/packages/gen/constants/enums/entityName";

export default [
  ...bizFormRoute({
    entityName: ENUM_Entity_Tenant,
    createPresenter: (options: any) => {
      // const { ProductFormPresenter } = await import(
      //   "@src/pages/product/productFormPresenter"
      // );
      // return new ProductFormPresenter(options);

      return new TenantFormPresenter(options);
    },
  }),
];
