import EN_BizType from '@packages/gen/types/entities/BizType';
import {IBizAction} from '@src/bizAction/interface';
import {BizListPresenter} from '@src/bizList/bizListPresenter';
import {BizListConfig} from '@src/bizList/interface';
import { BizTypeFormPresenter } from './bizTypeFormPresenter';

export class BizTypeListPresenter<T = EN_BizType> extends BizListPresenter<T> {

  public bizFormPresenter = BizTypeFormPresenter;

  public listBizConfig (config: BizListConfig<T>): BizListConfig<T> {
    config.column = {
      name: {
        cellRender: (val) => {
          return <span style={{color: 'red'}}>{val}</span>;
        }
      }
    };

    config.listRowActionManager.use((actions: Array<IBizAction>, data: any): Array<IBizAction> => {
      // if (data.id === 'db56a548-b6f8-4bd4-8886-051dcde9858b') {
      //   return [];
      // }
      return actions;
    });
    return config;
  }
}
