import { PieChartOutlined } from "@ant-design/icons";
import IconFont from "@src/components/Iconfont";
import { ENUM_Entity_InterviewQuestion } from "@src/packages/gen/constants/enums/entityName";
import { isProd } from "@src/utils";
import { ItemType } from "antd/lib/menu/hooks/useItems";
import React from "react";

export type INavConfig = ItemType & {
  // path: string;
};

export const configList: Array<INavConfig> = [
  // {
  //   key: "/Workflow",
  //   label: "教程列表",
  //   icon: <IconFont type="icon-workflow" />,
  // },
  {
    key: "/Article",
    label: "文章列表",
    icon: <IconFont type="icon-article" />,
  },
  {
    key: "/TinyCode",
    label: "在线代码",
    icon: <IconFont type="icon-article" />,
  },
  {
    key: "/Favicon",
    label: "favicon列表",
    icon: <IconFont type="icon-article" />,
  },
  {
    key: "/"+ ENUM_Entity_InterviewQuestion,
    label: "面试题",
    icon: <IconFont type="icon-article" />,
  },
  // {
  //   key: "/Poetry",
  //   label: "诗歌列表",
  //   icon: <IconFont type="icon-article" />,
  // },
  // {
  //   key: "/Author",
  //   label: "作者列表",
  //   icon: <IconFont type="icon-article" />,
  // },
  // {
  //   key: "/Category",
  //   label: "文章分类",
  //   icon: <IconFont type="icon-database" />,
  // },
  // {
  //   key: "/BizType",
  //   label: "业务类型",
  //   icon: <IconFont type="icon-database" />,
  // },
  {
    key: "/base-data",
    label: "基础数据",
    icon: <IconFont type="icon-database" />,
    children: [
      {
        type: "group",
        label: "文章",
        key: "Article",
        children: [
          {
            key: "Article/Category",
            label: "分类",
            icon: <IconFont type="icon-database" />,
          },
          {
            key: "Article/BizType",
            label: "业务类型",
            icon: <IconFont type="icon-database" />,
          },
        ],
      },
      {
        type: "group",
        label: "面试题",
        key: ENUM_Entity_InterviewQuestion,
        children: [
          {
            key: `${ENUM_Entity_InterviewQuestion}/Category`,
            label: "分类",
            icon: <IconFont type="icon-database" />,
          },
          {
            key: `${ENUM_Entity_InterviewQuestion}/BizType`,
            label: "业务类型",
            icon: <IconFont type="icon-database" />,
          },
        ],
      },
    ],
  },
  {
    key: "/settings",
    label: "设置",
    icon: <IconFont type="icon-setting" />,
  },
  // {
  //   key: "/Operator",
  //   label: "用户列表",
  //   icon: <IconFont type="icon-workflow" />,
  // },
  // {
  //   key: "/File",
  //   label: "文件列表",
  //   icon: <PieChartOutlined />,
  // },
  // {
  //   key: "/Email",
  //   label: "邮件列表",
  //   icon: <PieChartOutlined />,
  // },
  // {
  //   key: "/Template",
  //   label: "模版列表",
  //   icon: <PieChartOutlined />,
  // },
  {
    key: "/devtool/form",
    label: "开发工具",
    icon: <PieChartOutlined rev={undefined} />,
  },
  {
    key: "/devtool/querySchema",
    label: "列表方案",
    icon: <PieChartOutlined rev={undefined} />,
  },

];
