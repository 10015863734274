import { EntityFieldViewer } from "@src/bizForm/components/EntityFieldViewer";
import { toJS } from "mobx";
import { Observer } from "mobx-react-lite";
import React from "react";
import { BizListPresenter } from "./bizListPresenter";
import _get from "lodash/get";

import { BizColumn } from "./core/bizList";
import { ModuleNames } from "@src/bizForm/modules/interface";
import { ColumnType } from "antd/lib/table";
import { RenderedCell } from "rc-table/lib/interface";
import { LifecycleEvent } from "@src/bizForm/lifecycleEvent";
import { ListRowActionBar } from "@src/bizAction/ActionBar/ListRowActionBar";
import BizTable, { BizColumnProps } from "@src/components/BizTable";
import "./style/index.scss";

interface IBizListBody {
  presenter: BizListPresenter;
}

interface SBizListBody<T> {
  columns: ColumnType<T>[];
}

/**
 * 用于渲染table
 */
export class BizListBody<T> extends React.Component<IBizListBody, SBizListBody<T>> {
  state = {
    columns: [] as BizColumnProps<T>[],
  };

  constructor(props: IBizListBody) {
    super(props);
    this.state.columns = this.getColumns();
  }

  getCellRender = (col: BizColumn) => {
    const cellRender = _get(this.props.presenter.config, `${col.path}.cellRender`) as unknown as (
      value: any,
      record: any,
      index: number,
    ) => React.ReactNode | RenderedCell<any>;
    return cellRender
      ? cellRender
      : (value: any) => <EntityFieldViewer entityName={this.props.presenter.entityName} value={value} {...col} />;
  };

  getColumns(): any {
    // @ts-ignore
    const columns: BizColumnProps<any>[] = [
      ...this.props.presenter.model.columns.map((col) => ({
        ...col,
        dataIndex: col.path.split("."), // antd table  支持string | string[]
        render: this.getCellRender(col),
      })),
    ];

    if (this.props.presenter.config.listRowAction !== false) {
      columns.push({
        dataIndex: '_actions',
        title: "操作",
        fixed: "right",
        width: 300,
        render: (_: any, item: T) => {
          return <ListRowActionBar presenter={this.props.presenter} data={item} />;
        },
      });
    }

    return columns;
  }

  render() {
    return this.renderContent();
  }

  renderContent = () => {
    const { presenter } = this.props;
    if (!presenter.querySchema) {
      return null;
    }
    return (
      <Observer>
        {() => (
          <BizTable
            // tableLayout="fixed"
            virtual={presenter.options.virtual}
            sticky
            className={"bizList-table"}
            rowKey="id"
            rowSelection={this.getRowSelection()}
            columns={this.state.columns}
            dataSource={toJS(presenter.listData.data)}
            scroll={{
              x: 2000,
              // 分页、查询后滚动到列表顶部
              scrollToFirstRowOnChange: true,
            }}
            onRow={presenter.onTableRow}
            bordered
            pagination={{
              current: presenter.listData.pageNo,
              total: presenter.listData.count,
              pageSize: presenter.listData.pageSize,
              showQuickJumper: true,
              showSizeChanger: true,
              onChange: this.handlePageChange,
            }}
            tableId={presenter.querySchema.id} // 暂时是使用schema的id
          />
        )}
      </Observer>
    );
  };

  private onSelectChange = (selectedRowKeys: string[], selectedRows: []) => {
    this.props.presenter.getModule(ModuleNames.ListModule).setSelectedRowKeys(selectedRowKeys);
    this.forceUpdate();
  };

  private getRowSelection = (): any => {
    if (!this.props.presenter.config?.allowRowSection) {
      return undefined;
    }
    const keys = this.props.presenter.getModule(ModuleNames.ListModule).getSelectedRowKeys();
    return {
      selectedRowKeys: keys,
      onChange: this.onSelectChange,
      fixed: true,
    };
  };

  private query = () => {
    this.props.presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.queryListData);
  };

  private handlePageChange = (page: number, pageSize?: number) => {
    this.props.presenter.listData.pageSize = pageSize;
    this.props.presenter.listData.pageNo = page;

    this.query();
  };
}
