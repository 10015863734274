import React from "react";
import Icon from "iconfont-extract-icon";

import { IconBaseProps } from "iconfont-extract-icon/lib/types";
import IconCodeSvg from "../../react-svgs/IconCode";

const IconCode = React.forwardRef<HTMLSpanElement, IconBaseProps>(
  (props, ref): JSX.Element => {
    return <Icon component={IconCodeSvg} ref={ref} {...props} />;
  }
);

export default IconCode;
