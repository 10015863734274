import {http} from "./http";

export interface Options {
  query: string;
  variables?: {[key in string]: any};
}

export interface graphqlQuery {
  query: string;
  operation?: string;
  variables?: {[key in string]: any};
}

function queryBuilder(params: graphqlQuery) {
  return {
    ...params,
  };
}

export function graphql(options: Options) {
  const {query, variables} = options;
  return http({
    url: "/graphql",
    method: "post",
    data: queryBuilder({query, variables}),
  });
}
export function graphqlMutilObject(options: Options) {
  const {query, variables} = options;
  return http({
    url: "/graphql/mutil",
    method: "post",
    data: queryBuilder({query, variables}),
  });
}
export function graphqlList(options: Options) {
  const {query, variables} = options;
  return http({
    url: "/graphql/list",
    method: "post",
    data: queryBuilder({query, variables}),
  });
}

export function graphqlAllData(options: Options) {
  const {query, variables} = options;
  return http({
    url: "/graphql/list/all",
    method: "post",
    data: queryBuilder({query, variables}),
  });
}
