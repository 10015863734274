import { metadata } from '@src/metadata/metadata';
import { Select } from 'antd';
import React from 'react';
import { getValueTypeOptions, IvalueTypeOption, ValueType } from './valueTypeMaps';
const { useEffect } = React;

export interface IValueTypeSelect {
    value?: string;
    onChange: (value: ValueType) => void;
    bizType: string;
    className?: string;
    disabled?: boolean;
    path: string;
    entityName: string;
}

export function ValueTypeSelect(props: IValueTypeSelect) {
    const { bizType, value, onChange, className, disabled, path, entityName } = props;
    let options: Array<IvalueTypeOption>
    if (!bizType && path.split('.').length > 1) {
        // 对于携带的字段，需要通过递归的方式，在最后一个entity上获取bizType
        const entityField = metadata.getEntity(entityName).getFieldLastNode(path);
        if (entityField) {
            options = getValueTypeOptions(entityField.bizType);
        }
    } else {
        options = getValueTypeOptions(bizType);
    }

    useEffect(() => {
        // 如果没有默认值，则赋值options第一个选项的值为默认值
        if (options.length) {
            // onChange && onChange(options[0].value);
        }
    }, [])

    return <Select
        options={options}
        value={value as any}
        onChange={onChange}
        className={className}
        disabled={disabled} />
}

