
  const ENTITY_TITLE: Record<string, string> = {
    
// Article 
"Article": "文章"
,
// Author 
"Author": "作者"
,
// BizType 
"BizType": "业务类型"
,
// Category 
"Category": "分类"
,
// Collaboration 
"Collaboration": "协作"
,
// CollaboratorRight 
"CollaboratorRight": "协作权限类型"
,
// Comment 
"Comment": "评论"
,
// CreativeType 
"CreativeType": "创作类型"
,
// Email 
"Email": "邮件"
,
// EmailType 
"EmailType": "邮件类型"
,
// Favicon 
"Favicon": "favicon"
,
// FaviconImageType 
"FaviconImageType": "Favicon图标资源类型"
,
// FaviconItem 
"FaviconItem": "FaviconItem"
,
// FaviconItemSourceMethod 
"FaviconItemSourceMethod": "FaviconItem来源方式"
,
// FaviconRelType 
"FaviconRelType": "Favicon rel类型"
,
// File 
"File": "文件"
,
// InterviewQuestion 
"InterviewQuestion": "面试题"
,
// InterviewQuestionDifficulty 
"InterviewQuestionDifficulty": "面试题难度"
,
// ObjectType 
"ObjectType": "对象类型"
,
// Operator 
"Operator": "用户"
,
// OperatorStatus 
"OperatorStatus": "Operator状态枚举值"
,
// Order 
"Order": "订单"
,
// Permision 
"Permision": "权限"
,
// Poetry 
"Poetry": "诗歌"
,
// Product 
"Product": "产品"
,
// Role 
"Role": "角色"
,
// Sku 
"Sku": "sku"
,
// Template 
"Template": "模版"
,
// Tenant 
"Tenant": "租户"
,
// TenantOperatorAssociate 
"TenantOperatorAssociate": "租户用户关联信息"
,
// TinyCode 
"TinyCode": "代码演示"
,
// TinyCodeCssType 
"TinyCodeCssType": "TinyCodeCssType类型"
,
// TinyCodeHtmlType 
"TinyCodeHtmlType": "TinyCodeHtml类型"
,
// TinyCodeScriptType 
"TinyCodeScriptType": "TinyCodeScriptType类型"
,
// ValueType 
"ValueType": "valueType"
,
// Website 
"Website": "站点"
,
// Workflow 
"Workflow": "教程"
,
// WorkflowItem 
"WorkflowItem": "教程明细"
,
// MiddlewareType 
"MiddlewareType": "中间键类型"

  }
  
  export default ENTITY_TITLE;