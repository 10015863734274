import {
  NavigateFunction,
    useLocation,
    useNavigate,
    useParams,
  } from "react-router-dom";

  export interface WithRouterProps {
    router: {
      location: Location,
      navigate: NavigateFunction;
      params: Record<string, string | undefined>;
    }
  }
  
  const withRouter = (Component: React.ComponentType<any>) => {
    function ComponentWithRouterProp(props: any) {
      const location = useLocation();
      const navigate = useNavigate();
      const params = useParams();
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
  }

  export default withRouter;