import {LifecycleEvent} from "@src/bizForm/lifecycleEvent";
import {ModuleNames} from "@src/bizForm/modules/interface";
import {BizListPresenter} from "@src/bizList/bizListPresenter";
import {TDeleteResponse} from "@src/interfaces/response";
import {metadata} from "@src/metadata/metadata";
import FormUtils from "@src/utils/formUtils";
import {message} from "antd";

export const DeleteAction = {
  key: "delete",
  label: "删除",
  options: {
    confirm: true,
    onClick: async (presenter: BizListPresenter, data: any) => {
      try {
        // 删除的时候，如果是基础数据，需要带上 objectTypeId
        const extension: {objectTypeId?: string} = {};
        if (metadata.getEntity(presenter.entityName)?.isBaseData) {
          extension.objectTypeId = presenter.objectTypeId;
        }
        const result = (await FormUtils.FormActionDelete(presenter.entityName, data.id, extension)) as TDeleteResponse;
        if (result.data) {
          presenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.queryListData);
        } else {
          message.error(result.errMsg);
        }
      } catch (err: any) {
        message.error(err);
      }
    },
  },
};
