import {TimePicker as AntTimePicker} from 'antd';
import {useCallback} from 'react';
import {IBizFormComponentOProps} from '../interface';
import dayjs from 'dayjs';

export const TimePicker = (props: IBizFormComponentOProps) => {
  const onChange = useCallback((_: any, timeString: string | string[]) => {
    props.onChange && props.onChange(timeString);
  }, []);

  return (
    <AntTimePicker
      value={dayjs(props.value)}
      onChange={onChange}
      disabled={props.disabled}
    />
  );
};
