import React, { createRef } from 'react';

import {Tag, Input, Tooltip} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {TagsEditorProps, TagsEditorState} from './types';
import "./style.scss";

export default class TagsEditor extends React.Component<TagsEditorProps, TagsEditorState> {
  state = {
    inputVisible: false,
    inputValue: '',
    editInputIndex: -1,
    editInputValue: ''
  };

  inputRef = createRef<any>();
  editInputRef  = createRef<any>();

  getTags = (): Array<string> => {
    return this.props.value || [];
  };

  handleClose = (removedTag: string) => {
    const tags = this.getTags().filter((tag) => tag !== removedTag);
    this.props.onChange && this.props.onChange(tags);
  };

  showInput = () => {
    this.setState({inputVisible: true}, () => this.inputRef.current.focus());
  };

  handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({inputValue: e.target.value});
  };

  handleInputConfirm = () => {
    const {inputValue} = this.state;
    let tags = this.getTags();
    if (inputValue && tags.indexOf(inputValue) === -1) {
      tags = [ ...tags, inputValue ];
    }
    this.props.onChange && this.props.onChange(tags);
    this.setState({
      inputVisible: false,
      inputValue: ''
    });
  };

  handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({editInputValue: e.target.value});
  };

  handleEditInputConfirm = () => {
    const tags = this.getTags();
    this.setState(({editInputIndex, editInputValue}): any => {
      const newTags = [ ...tags ];
      newTags[ editInputIndex ] = editInputValue;
      this.props.onChange && this.props.onChange(tags);

      return {
        editInputIndex: -1,
        editInputValue: ''
      };
    });
  };

  render () {
    const tags = this.getTags();
    const {inputVisible, inputValue, editInputIndex, editInputValue} = this.state;
    return (
      <>
        {tags.map((tag, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                ref={this.editInputRef as any}
                key={tag}
                size="small"
                className="tagsEditor-input"
                value={editInputValue}
                onChange={this.handleEditInputChange}
                onBlur={this.handleEditInputConfirm}
                onPressEnter={this.handleEditInputConfirm}
              />
            );
          }

          const isLongTag = tag.length > 20;

          const tagElem = (
            <Tag className="tagsEditor-tag" key={tag} closable onClose={() => this.handleClose(tag)}>
              <span
                onDoubleClick={(e) => {
                  if (index !== 0) {
                    this.setState({editInputIndex: index, editInputValue: tag}, () => {
                      this.editInputRef.current.focus();
                    });
                    e.preventDefault();
                  }
                }}
              >
                {isLongTag ? `${ tag.slice(0, 20) }...` : tag}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            ref={this.inputRef}
            type="text"
            size="small"
            className="tagsEditor-input"
            value={inputValue}
            onChange={this.handleInputChange}
            onBlur={this.handleInputConfirm}
            onPressEnter={this.handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag className="tagsEditor-plus" onClick={this.showInput}>
            <PlusOutlined rev={undefined} /> 添加
          </Tag>
        )}
      </>
    );
  }
}
