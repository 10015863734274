import {FaviconFormPresenter, FaviconListPresenter} from "@src/pages/favicon";
import {bizFormRoute} from "../bizFormRouter";
import {bizListRouter} from "../bizListRouter";
import {ENUM_Entity_Favicon} from "@packages/gen/constants/enums/entityName";

export default [
  ...bizFormRoute({
    entityName: ENUM_Entity_Favicon,
    createPresenter: (options: any) => {
      // const { ProductFormPresenter } = await import(
      //   "@src/pages/product/productFormPresenter"
      // );
      // return new ProductFormPresenter(options);

      return new FaviconFormPresenter(options);
    },
  }),
  ...bizListRouter({
    entityName: ENUM_Entity_Favicon,
    createPresenter: (options: any) => {
      return new FaviconListPresenter(options);
    },
  }),
];
