import EmbedTinyCode from "./EmbedTinyCode";
import FullScreen from "./FullScreen";
import LinkArticle from "./LinkArticle";
import SplitScreen from "./SplitScreen";
import UploadImage from "./UploadImage";

export const config = [
    EmbedTinyCode,
    UploadImage,
    SplitScreen,
    FullScreen,
    LinkArticle,
]