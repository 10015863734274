// 用于对页面组件进行一次包装，将路由信息添加到context中，或者从context中复用已存在页面

import {FC, ReactElement, useContext, useEffect} from "react";
import {RouterTabsContext} from "./context";
import {useMatches, useParams} from "react-router-dom";

const RouteTabsWrapper: FC<{
  children: React.ReactNode | null;
  realComponent: React.ReactNode | null | (() => React.ReactNode);
}> = (props): null | ReactElement => {
  const context = useContext(RouterTabsContext);
  const matches = useMatches();
  const match = matches[matches.length - 1];
  const params = useParams();

  useEffect(() => {
    const tab = context.tabs.find((tab) => tab.path === match.pathname);
    if (tab) {
      if (tab.path !== context.activeTab.path) {
        context.onChange?.(tab);
      }
    } else {
      const node = typeof props.realComponent === "function" ? props.realComponent() : props.realComponent;
      const handle = match.handle as Record<string, any>;
      const title = typeof handle?.title === "function" ? handle?.title?.(params) : handle?.title;
      context.onCreate?.(match.pathname, title, node);
    }
  }, []);

  return null;
};

export default RouteTabsWrapper;
