import {IDisposer} from "@src/MST-Form/interface";
import {MSTForm} from "@src/MST-Form/MSTForm";
import {autobind} from "core-decorators";
import {BizFormPresenter} from "../../bizForm/bizFormPresenter";
import EN_Favicon from "@packages/gen/types/entities/Favicon";
import {BizFormItemPresenter} from "@src/bizForm/bizFormItemPresenter";
import {FaviconItemFormPresenter} from "./faviconItemFormPresenter";
import { F_Favicon_sourceMethod } from "@src/packages/gen/constants/entities/Favicon";
import { FormMode } from "@src/bizForm/formMode";
import ENUM_FaviconItemSourceMethod from "@src/packages/gen/types/enums/FaviconItemSourceMethod";

@autobind
export class FaviconFormPresenter<T = EN_Favicon> extends BizFormPresenter<T> {
  public bizFormItemPresenters: Array<typeof BizFormItemPresenter<any>> = [FaviconItemFormPresenter];

  public getItemPresenters() {
    return [FaviconItemFormPresenter]
  }

  public onFormCreated(form: MSTForm<T>, disposers: IDisposer[]) {
    if (this.mode === FormMode.Create) {
      form.select(F_Favicon_sourceMethod).value = ENUM_FaviconItemSourceMethod.Crawl;
    }
  }

}
