import React from "react";
import Icon from "iconfont-extract-icon";

import { IconBaseProps } from "iconfont-extract-icon/lib/types";
import IconArticleSvg from "../../react-svgs/IconArticle";

const IconArticle = React.forwardRef<HTMLSpanElement, IconBaseProps>(
  (props, ref): JSX.Element => {
    return <Icon component={IconArticleSvg} ref={ref} {...props} />;
  }
);

export default IconArticle;
