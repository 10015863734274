import {Modal} from "antd";
import {memo, useMemo} from "react";
import {AdvanceReferModalProps} from "./types";
import BizList from "@src/bizList/bizList";
import {BizListConfig} from "@src/bizList/interface";
import {BizListPresenter} from "@src/bizList/bizListPresenter";
import "./style.scss";

const AdvanceReferModal = (props: AdvanceReferModalProps): JSX.Element => {
  const {open, onCancel, onOk} = props;

  const presenter = useMemo(() => {
    // ReferListPresenter 写在外面变编译会出问题，还没找到具体的问题
    class ReferListPresenter<T = any> extends BizListPresenter<T> {
      public listBizConfig(config: BizListConfig<T>): BizListConfig<T> {
        config.listRowAction = false;

        return config;
      }
      public onTableRow(record: T) {
        return {
          onClick: () => {
            if (!props.isMutil) {
              onOk?.(record);
            }
          },
        };
      }
    }

    return new ReferListPresenter({
      entityName: props.entityName,
      objectTypeId: props.objectTypeId,
      queryColumn: props.referOptions.queryColumn,
      querySchema: props.referOptions.querySchema,
      pagination: {
        pageSize: 10,
      },
    });
  }, []);

  return (
    <Modal width={700} open={open} onCancel={onCancel} wrapClassName="advanceRefer" footer={null}>
      <BizList presenter={presenter} />
    </Modal>
  );
};

export default memo(AdvanceReferModal);
