import {ModalsComponentProps} from "@src/components/Modals";
import {http} from "@src/http";
import {Form, Input, Modal, message} from "antd";
import {FC} from "react";

interface Props extends ModalsComponentProps {}

export const domainReg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/;

export const formatDomain = (domain: string) => {
  const str = (domain || "").trim().match(domainReg)?.[0] || "";

  return str.toLowerCase();
};

const UpdateModal: FC<Props> = (props) => {
  const [form] = Form.useForm();
  const handleOk = () => {
    form.validateFields().then(async (values: {domainStr: string}) => {
      try {
        const domains = values.domainStr
          .replaceAll(",", "")
          .split("\n")
          .map((domain: string) => formatDomain(domain.trim()))
          .filter(Boolean);
        await http({
          method: "post",
          url: "/Favicon/crawl",
          data: {
            domains: Array.from(new Set(domains)),
          },
        });
        message.success("域名已提交，刷新中！");
        props.onOk?.();
      } catch (err) {
        message.error(toString());
      }
    });
  };

  return (
    <Modal {...props} onOk={handleOk}>
      <Form form={form}>
        <Form.Item name={"domainStr"}>
          <Input.TextArea autoSize={{minRows: 5, maxRows: 20}} autoFocus placeholder="输入域名/地址，每行一个" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateModal;
