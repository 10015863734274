import React from 'react';
import { BetweenWrapper } from '../BetweenWrapper';
import { IBizFormComponentOProps } from '../interface';
import { Numeric } from '../Numeric';

interface INumericBetween extends IBizFormComponentOProps {
    value: Array<number>
}

/**
 *整数-区间
 * @param props 
 * @returns 
 */
export function NumericBetween(props: INumericBetween) {
    const value = Array.isArray(props.value) ? props.value : [];

    const onChange = (index: number, val: number) => {
        value[index] = val;
        props.onChange && props.onChange(value);
    }

    return <BetweenWrapper {...props} value={value} onChange={onChange} component={Numeric}/>
}
