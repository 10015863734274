import {Table} from "antd";
import type {TableProps} from "antd";
import {FC, useEffect, useMemo, useRef} from "react";

interface Props extends TableProps {
  onScroll?: (rect: {top: number; totalHeight: number; clientHeight: number}) => void;
}

const VirtualTable: FC<Props> = (props) => {
  const _tableRef = useRef<HTMLDivElement>();
  const _id = useMemo((): string => {
    return props.id ?? Date.now() + "";
  }, [props.id]);

  useEffect(() => {
    let tableBodyVirtualHolder: HTMLDivElement;

    const callback = () => {
      if (!tableBodyVirtualHolder) {
        return;
      }
      // e.a
      props.onScroll?.({
        totalHeight: tableBodyVirtualHolder.scrollHeight,
        top: tableBodyVirtualHolder.scrollTop,
        clientHeight: tableBodyVirtualHolder.clientHeight,
      });
    };

    if (_tableRef.current && props.dataSource?.length) {
      tableBodyVirtualHolder = document.getElementById(_id)?.querySelector(".ant-table-tbody-virtual-holder");
      tableBodyVirtualHolder?.addEventListener("scroll", callback, false);
    }

    return () => {
      tableBodyVirtualHolder?.removeEventListener("scroll", callback, false);
    };
  }, [props.dataSource]);

  return <Table {...props} id={_id} ref={_tableRef as any} />;
};

export default VirtualTable;
