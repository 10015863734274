import {autobind} from "core-decorators";
import { BizFormItemPresenter } from "@src/bizForm/bizFormItemPresenter";
import EN_FaviconItem from "@packages/gen/types/entities/FaviconItem";
import { ENUM_Entity_FaviconItem } from "@packages/gen/constants/enums/entityName";
import { F_Favicon_faviconItems } from "@packages/gen/constants/entities/Favicon";
import { BizFormItemConfig } from "@src/bizForm/interface";
import { IBizAction } from "@src/bizAction/interface";

@autobind
export class FaviconItemFormPresenter<T = EN_FaviconItem> extends BizFormItemPresenter<T> {
  public readonly logicPath: string = F_Favicon_faviconItems
  public readonly itemEntityName: string = ENUM_Entity_FaviconItem

  public bizFormItemConfig (config: BizFormItemConfig<T>): BizFormItemConfig<T> {
    config.formItemActionManager.use((actions: Array<IBizAction>, data: any): Array<IBizAction> => {

      return actions;
    });
    return config;
  }
}
