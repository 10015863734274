/**
 * form表单字段渲染的数据模型，后端获取到的template，metadata，跟前端存在entity实体模型组合成实际的渲染模型
 */

import {IMetadataField} from '@src/interfaces/metadata';
import {ITemplateColumn} from '@src/interfaces/template';
import {Entity, EntityField} from '@src/metadata/entity';
import {metadata} from '@src/metadata/metadata';
import {StringUtils} from '@src/utils/stringUtils';
import componentsMap from './componentMap';

export class BizFormField {
  public entityField: EntityField;

  // 判断字段是否是根据外键refer携带的字段， 携带的字段直接根据查询回来的数据显示，并且不能编辑和提交保存
  // 携带项可以根据是基本数据类型来匹配对应的显示组件， 按找通用逻辑来说，说有的object对象都应该是外键，都应该匹配refer组件
  // bizType的作用更多的是针对 string(text、textarea)、number(amount、count、percision)、bool。
  public isCarryField: boolean;

  constructor (public entityName: string, private templateColumn: ITemplateColumn, private metadataItem: IMetadataField) {
    const entity = metadata.getEntity(entityName);

    try {
      this.entityField = entity.getField(templateColumn.path);
      if (!this.entityField) {
        this.isCarryField = true;
        this.entityField = {} as any;
      }
    } catch (err) {
      console.error(`model entity: ${ this.entityName } field: ${ this.templateColumn.path } error`);
      console.error(err);
    }
  }

  public get label () {
    return this.templateColumn.displayName || this.metadataItem.displayName;
  }

  public get path () {
    return this.templateColumn.path;
  }

  public get required () {
    return this.templateColumn.required;
  }

  public get readonly () {
    if (this.isCarry) {
      return true;
    }
    return this.templateColumn.readonly;
  }
  public get disabled () {
    return this.readonly;
  }
  public get alwaysHidden () {
    return this.templateColumn.alwaysHidden;
  }
  public get isMulti () {
    return this.templateColumn.isMulti;
  }

  public get colSpan () {
    return this.templateColumn.colSpan;
  }

  //
  public get bizType () {
    return this.entityField.bizType;
  }
  public get enumType () {
    return this.entityField.enumType;
  }

  public get referType () {
    if (!this.entityField) {
      return;
    }
    return this.entityField.referType;
  }

  public get isRefer () {
    return this.entityField.isRefer;
  }

  public get isEnum () {
    return this.entityField.isEnum;
  }

  // 携带字段不允许编辑，只是展示作用
  public get isCarry () {
    return this.isCarryField;
  }

  // 系统字段，不允许编辑，只能展示
  public get isSystemField () {
    return this.entityField.isSystemField;
  }
  // 获取当前携带字段的父级字段，就是对应的entity
  public get carryType () {
    const pathArr = this.templateColumn.path.split('.');
    return StringUtils.upperCapital(pathArr[ pathArr.length - 2 ]);
  }

  public get component () {
    // 如果是携带关系的，则直接从对应的父级实体上去对应的字段的bizType信息
    if (this.isCarry) {
      const pathArr = this.templateColumn.path.split('.');
      const carryField = metadata.getEntity(this.carryType).getField(pathArr.pop());
      return componentsMap(carryField.bizType);
    }
    return componentsMap(this.bizType);
  }
}
