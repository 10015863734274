import React from 'react';
import { Input as AntdInput } from 'antd';

export const inputValueTrim = (value?: string) => typeof value === "string" ? value?.trim() : value;

export const BaseInput = (props: any) => {
    const { onChange, value = '', ...reset } = props;

    const handleChange = (e: any) => {
        onChange?.(e.target.value);
    };
    const handleBlur = (e: React.FocusEvent): void => {
        onChange?.(inputValueTrim(value));
    };

    return <AntdInput {...reset} value={value} onChange={handleChange} onBlur={handleBlur} />;
}
