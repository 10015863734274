import {BizFormItemPresenter} from "@src/bizForm/bizFormItemPresenter";
import {FormMode} from "@src/bizForm/formMode";
import {LifecycleEvent} from "@src/bizForm/lifecycleEvent";
import {ModuleNames} from "@src/bizForm/modules/interface";
import {TDeleteResponse} from "@src/interfaces/response";
import FormUtils from "@src/utils/formUtils";
import {Modal, message} from "antd";

/**
 * 删除行数据
 */
export const RemoveRowAction = {
  key: "remove",
  label: "删除",
  options: {
    onClick: async (itemPresenter: BizFormItemPresenter<any>, rowData?: any) => {
      if (itemPresenter.formPresenter.mode === FormMode.View) {
        Modal.confirm({
          title: "确定删除该行?",
          onOk: async () => {
            try {
              const extension: {objectTypeId?: string} = {};
              const result = (await FormUtils.FormActionDelete(
                itemPresenter.itemEntityName,
                rowData.id,
                extension,
              )) as TDeleteResponse;
              if (result.data) {
                itemPresenter.formPresenter.getModule(ModuleNames.EventModule).dispatch(LifecycleEvent.reloadBizForm);
              } else {
                message.error(result.errMsg);
              }
            } catch (err: any) {
              message.error(err);
            }
          },
        });
      } else {
        itemPresenter.removeByRowId(rowData._id);
      }
    },
  },
};
