import EN_BizType from '@packages/gen/types/entities/BizType';
import {IDisposer} from '@src/MST-Form/interface';
import {MSTForm} from '@src/MST-Form/MSTForm';
import {autobind} from 'core-decorators';
import {reaction} from 'mobx';
import {BizFormPresenter} from '../../bizForm/bizFormPresenter';

@autobind
export class BizTypeFormPresenter<T = EN_BizType> extends BizFormPresenter<T> {
  public onFormCreated (form: MSTForm<T>, disposers: IDisposer[]) {
    // disposers.push(
    //     reaction(
    //         () => form.select("category").value,
    //         (value) => {
    //             form.select("name").disabled = true;
    //         }
    //     )
    // )
  }
}
