import React from "react";
import {Input as AntdInput, InputProps} from "antd";

interface IInput extends Omit<InputProps, "value" | "onChange"> {
  value?: string;
  onChange?: (data: string) => void;
}

export default function Input(props: IInput) {
  const {onChange = () => {}, value = "", ...reset} = props;
  const handleChange = (e: any) => {
    onChange(e.target.value);
  };
  const handleBlur = (e: React.FocusEvent): void => {
    if (typeof value === "string") {
      const _value = value?.trim();
      if (_value !== value) {
        onChange(_value);
      }
    } else {
      onChange(value);
    }
  };
  return <AntdInput {...reset} value={value} onChange={handleChange} onBlur={handleBlur} />;
}
