import React from 'react';
import {Input as AntdInput, InputProps} from 'antd';
import {IBizFormComponentOProps} from '../interface';

interface INumeric extends IBizFormComponentOProps {
  precision?: number; // 精度 默认不控制
  max?: number; // 默认值 Number.MAX_SAFE_INTEGER
  min?: number; // 默认值 Number.MIN_SAFE_INTEGER
  formatter?: (value: number | string) => string; // 输入框展示的格式
}

/**
 * 编辑时显示原始格式，失去焦点时显示格式化的文本
 * @param props
 * @returns
 */
export function Numeric (props: INumeric) {
  const [ value, setValue ] = React.useState(props.value);
  const [ focused, setFocused ] = React.useState(false);

  React.useEffect(() => {
    setValue(props.value);
  }, [ props.value ]);

  const onChange = (e: any) => {
    const val = e.target.value;
    const reg = /^-?\d*(\.\d*)?$/;
    if (val === '') {
      setValue(undefined);
    } else if (val === '-') {
      setValue(val);
    } else if (!isNaN(val) && reg.test(val)) {
      setValue(editFormatter(val));
    }
  };

  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
    props.onChange && props.onChange(submitFormatter(value));
  };

  // 编辑时的格式化
  const editFormatter = (value: string) => {
    const {precision, max, min} = props;
    let formateValue = Number(value);
    if (formateValue < min) {
      formateValue = min;
    }

    if (formateValue > max) {
      formateValue = max;
    }

    const valueArr = value.split('.');
    if (!valueArr[ 1 ] && precision !== 0) {
      return value;
    }
    if (Number.isInteger(precision)) {
      return Number(formateValue.toFixed(precision));
    }

    return value;
  };

  // 返回value根据precision, max, min,格式化后的数字
  const submitFormatter = (value: number | string) => {
    const {precision, max, min} = props;
    if (typeof value === 'undefined' || value === '-') {
      return undefined;
    }
    value = Number(value);
    if (value < min) {
      value = min;
    }

    if (value > max) {
      value = max;
    }

    if (Number.isInteger(precision)) {
      return Number(value.toFixed(precision));
    }

    return Number(value);
  };

  const viewFormatter = (value: number | string) => {
    if (typeof props.formatter === 'function') {
      return props.formatter(value);
    }

    if (typeof value === 'undefined') {
      return undefined;
    }

    const {precision} = props;
    value = Number(value);
    // 后端可以添加千分位
    if (Number.isInteger(precision)) {
      return Number(value.toFixed(precision));
    }

    return Number(value);
  };

  return (
    <AntdInput
      {...props}
      value={focused ? value : viewFormatter(value)}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
}
