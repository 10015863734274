import {graphqlMutilObject} from "../graphql";
import {QueryBuilderHelper} from "../queryBuilder/queryBuilderHelper";
import {LoadingStatus} from "@src/components/LoadingContainer";
import {QueryBuilder} from "../queryBuilder";

export type ObjectType = {
  id: string; // 数据id，目前只支持通过id获取
  entityName: string;
  aliasName: string; // 多个对象同事查询时需要设置graphql的别名
  queryFields: string[]; // 需要获取的字段
};

export interface MutilObjectDataLoaderOptions {
  objectList: Array<ObjectType>;
}

export class MutilObjectDataLoader {
  public queryBuilderMap: Map<string, QueryBuilder> = new Map();

  public data: any;

  public loadingStatus = LoadingStatus.None;

  constructor(protected options: MutilObjectDataLoaderOptions) {
    this.initQueryBuilders(options.objectList);
  }

  protected initQueryBuilders(objectList: Array<ObjectType> = []) {
    objectList.forEach((obj) => {
      this.queryBuilderMap.set(
        obj.aliasName,
        QueryBuilderHelper.create({
          entityName: obj.entityName,
          queryOptions: {
            queryFields: obj.queryFields,
            // queryStr: this.options.queryStr,
            queryCondition: [`id: "${obj.id}"`],
          },
        }),
      );
    });
  }

  private getQueryString() {
    return `{${[...this.queryBuilderMap.entries()].map(([aliasName, queryBuilder]) => {
      const queryObject = queryBuilder.getQueryObject();
      return `
      ${aliasName}: ${queryObject.entityName}${queryObject.conditionString}{
        ${queryObject.fieldsString}
      }
      `;
    })}}`;
  }

  public clear() {
    this.data = {};
  }

  public async query() {
    try {
      this.loadingStatus = LoadingStatus.Loading;
      const result = await graphqlMutilObject({
        query: this.getQueryString(),
      });
      if (result.success) {
        this.data = result.data ? result.data : {};
        this.loadingStatus = LoadingStatus.Complete;
      } else {
        this.loadingStatus = LoadingStatus.Failed;
      }
    } catch {
      this.loadingStatus = LoadingStatus.Failed;
    }
  }
}
