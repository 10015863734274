import React from "react";
import CodeEditor from "../CodeEditorBase";
// import {Controlled as CodeMirror} from 'react-codemirror2';
// import 'codemirror/lib/codemirror.css';
import "./style.scss";

interface IMarkdownEditor {
  value: string;
  onChange: (val: string) => void;
}

const options = {
  mode: "xml",
  theme: "material",
  lineNumbers: true,
};

export const MarkdownEditor = (props: IMarkdownEditor) => {
  return (
    <div className="markdown-editor">
      <CodeEditor className="markdown-editor-main" value={props.value} onChange={props.onChange} language="markdown" showToolbar/>
    </div>
  );
};
