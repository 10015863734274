import { IconCode } from "@src/icons";
import { EditorTool } from "../types";
import { Modals } from "@src/components/Modals";
import EmbedTinyCodeModal from "./EmbedTinyCodeModal";
import EN_TinyCode from "@packages/gen/types/entities/TinyCode";
import { editorInsertContent } from "../../utils";
import { genTinyCodeEmbedScript } from "../../../../../common/tinyCode";

const config: EditorTool = {
  id: "embedTinyCode",
  icon: <IconCode />,
  onClick: (options) => {
    Modals.show(EmbedTinyCodeModal, {
      onOk: (tinyCode: EN_TinyCode) => {
        const code = genTinyCodeEmbedScript(tinyCode.id)
        editorInsertContent(options.editor, code);
      },
    });
  },
};

export default config;
