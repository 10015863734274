import {getSnapshot, applySnapshot, IAnyStateTreeNode} from "mobx-state-tree";
import {getItemEditFlag, getNode, getNodeByPath, wrapItemWithId} from "./MSTForm-utils";
import {MSTFormField, MSTFormFieldParams} from "./MSTFormField";
import {observable, runInAction, toJS} from "mobx";
import {MSTFieldWriteableProps, Optional} from "./interface";
import lodashGet from "lodash/get";
import {IValidators, Validators} from "./validators/validator";
import {IValidator} from "./validators/interface";
import {FormItemEditFlag, FormMode} from "@src/bizForm/formMode";
import {FORM_EDIT_FALG_FIELD} from "@src/common/constants/mst";
import {metadata} from "@src/metadata/metadata";

export interface FieldOptions {
  initState: Optional<MSTFieldWriteableProps>;
}

export interface MSTFormOptions {
  fieldOptions?: {[fieldName: string]: FieldOptions};
  childFieldOptions?: Record<string, {[fieldName: string]: FieldOptions}>;
  formMode: FormMode;
  entityName: string;
}

export class MSTForm<T extends Record<string, any>> {
  static create<T>(mstInstance: any, options: MSTFormOptions) {
    return new MSTForm<T>(mstInstance, options);
  }

  private initSnapshot: T; // 快照

  private fieldsMap: Map<string, MSTForm<T> | MSTFormField> = new Map();

  private _deleted: boolean = false; // 标记是否删除form，在明细行中有用

  public valiator: Validators<T>;

  constructor(public mstInstance: IAnyStateTreeNode, private options: MSTFormOptions) {
    this.initSnapshot = getSnapshot(this.mstInstance);
    // unprotect(this.mstInstance);
    this.initState();
    this.initValidators();
  }

  destructor() {}

  initState() {
    const rootNode = getNode(this.mstInstance);
    rootNode["$FORM_STATE_KEY"] = observable.map(new Map<string, any>());
  }

  //初始化校验器
  initValidators() {
    const {fieldOptions = {}} = this.options;

    const params: IValidators<T> = {
      form: this,
      options: {
        validators: {},
      },
    };

    (Reflect.ownKeys(fieldOptions) as Array<string>).forEach((path) => {
      params.options.validators[path] = lodashGet(fieldOptions[path], "validators", []);
    });

    this.valiator = new Validators(params);
  }

  select(path: string): MSTFormField {
    const node = getNodeByPath(this.mstInstance, path);
    if (!node) {
      return null;
    }
    // const nodeId = node.nodeId; // 使用nodeId作为map的可以，防止模型在嵌套情况下时，path相同造成的数据覆盖

    // 1: 从缓存中获取
    if (this.fieldsMap.has(path)) {
      return this.fieldsMap.get(path) as MSTFormField;
    }

    const entity = metadata.getEntity(this.options.entityName);
    const fieldInfo = entity.getField(path);
    if (!fieldInfo.isItems) {
      const fieldOptions = lodashGet(this.options.fieldOptions, path, {}) as FieldOptions;
      const initSnapshot = lodashGet(this.initSnapshot, path);
      const options = {
        ...fieldOptions,
      };

      const params: MSTFormFieldParams = {
        path: path, // 目前不考虑嵌套的path情况，第一版处理最简单的逻辑
        form: this as any,
        node: node,
        options,
        initSnapshot,
      };

      const field = new MSTFormField(params);

      this.fieldsMap.set(path, field);

      return field;
    }

    return null;
  }

  public getValue(path?: string): any {
    if (!path) {
      return this.mstInstance;
    }
    return this.mstInstance.getValue(path);
  }

  public setValue(path: string, value: any) {
    runInAction(() => {
      this.mstInstance.setValue(path, value);
      // 进行深度比较，因为value可能为数组，也要比较值数组元素是否发生改变
      // https://www.lodashjs.com/docs/lodash.isEqual
      // if (!lodashIsEqual(value, lodashGet(this.initSnapshot, path))) {
      //   // 如果值发生变化，更新_editFlag
      //   this.setEditFalg(FormItemEditFlag.Update);
      // }
    });
  }
  // 获取数据
  public getFormData() {
    return this.mstInstance.toJSON();
  }

  // 获取修改过的数据
  public getChangeData() {
    const formatChangeData = (entityName: string, mstForm: MSTForm<T>) => {
      const entity = metadata.getEntity(entityName);
      const result: any = {};

      // 如果删除了直接返回id
      if (mstForm._deleted) {
        // 判断id是否存在的原因是：可能前端新建了form但是又删除了，就可以忽略该数据
        if (mstForm.initSnapshot?.id) {
          result.id = mstForm.initSnapshot?.id || undefined;
          result[FORM_EDIT_FALG_FIELD] = mstForm.itemEditFlag();
        }
        return result;
      }
      // create/update
      [...mstForm.fieldsMap.keys()].map((key: string) => {
        const fieldInfo = entity.getField(key);
        if (fieldInfo) {
          if (!fieldInfo.isItems) {
            const mstFormField = mstForm.fieldsMap.get(key) as MSTFormField;
            if (mstFormField.changed) {
              result[key] = toJS(mstFormField.value);
            }
          }
        } else {
          const itemPath = key.split(".")[0];
          const fieldInfo = entity.getField(itemPath);
          if (fieldInfo.isItems) {
            if (!result[itemPath]) {
              result[itemPath] = [];
            }
            const itemFieldsMap = mstForm.fieldsMap.get(key) as MSTForm<T>;
            const formData = formatChangeData(fieldInfo.referType, itemFieldsMap);
            if (Object.keys(formData).length) {
              formData.id = itemFieldsMap.initSnapshot?.id || undefined;
              formData[FORM_EDIT_FALG_FIELD] = itemFieldsMap.itemEditFlag(formData);
              result[itemPath].push(formData);
            }
          }
        }
      });

      return result;
    };

    return formatChangeData(this.options.entityName, this);
  }
  // 重置数据
  public reset() {
    applySnapshot(this.mstInstance, this.initSnapshot);
  }

  public validate() {
    const result = this.valiator.validate();
    return result;
  }

  public addValidator(path: string, validator: IValidator) {
    return this.valiator.addValidator(path, validator);
  }

  public removeValidator(path: string, validator: IValidator) {
    this.valiator.removeValidator(path, validator);
  }

  public get changed(): boolean {
    if (this._deleted) {
      return true;
    }

    return Object.keys(this.getChangeData()).length !== 0;
  }

  // <! ----- item start -------- >
  public selectItem(itemPath: string, index: number): MSTForm<T> {
    const node = getNodeByPath(this.mstInstance, itemPath)[index];
    if (!node) {
      return null;
    }

    const itemValue = this.getValue(itemPath)[index];
    if (!itemValue) {
      return null;
    }
    const _path = `${itemPath}.${itemValue._id}`;
    // 1: 从缓存中获取
    if (this.fieldsMap.has(_path)) {
      return this.fieldsMap.get(_path) as MSTForm<T>;
    }

    const entity = metadata.getEntity(this.options.entityName);
    const fieldInfo = entity.getField(itemPath);
    if (fieldInfo.isItems) {
      // 创建明细表的mstForm
      const referEntityName = fieldInfo.referType;
      const mstFormOptions = {
        fieldOptions: this.options.childFieldOptions[itemPath],
        formMode: getItemEditFlag(this.options.formMode, itemValue?.id),
        entityName: referEntityName,
      };
      const referMstInstance = MSTForm.create<T>(node, mstFormOptions);
      this.fieldsMap.set(_path, referMstInstance);
      return referMstInstance;
    }

    return null;
  }

  public selectItemById(itemPath: string, _id: string): MSTForm<T> {
    const entity = metadata.getEntity(this.options.entityName);
    const fieldInfo = entity.getField(itemPath);
    if (fieldInfo.isItems) {
      const _path = `${itemPath}.${_id}`;
      // 1: 从缓存中获取
      if (this.fieldsMap.has(_path)) {
        return this.fieldsMap.get(_path) as MSTForm<T>;
      }
      //
      const referEntityName = fieldInfo.referType;
      const items = this.mstInstance.getItems(itemPath);
      const targetaIndex = items.findIndex((item: T) => item._id === _id);
      if (targetaIndex !== -1) {
        const node = this.mstInstance[itemPath][targetaIndex];
        if (!node) {
          return null;
        }
        const formMode = getItemEditFlag(this.options.formMode, items[targetaIndex].getValue("id"));
        const mstFormOptions = {
          fieldOptions: this.options.childFieldOptions[itemPath],
          formMode,
          entityName: referEntityName,
        };
        const referMstInstance = MSTForm.create<T>(node, mstFormOptions);
        this.fieldsMap.set(_path, referMstInstance);
        return referMstInstance;
      }
    }

    return null;
  }

  // 添加新行
  // 直接在fieldsMap上添加字段就行，不需要在
  public createItem(itemPath: string, behindId?: string) {
    const items = toJS(this.getValue(itemPath)) || [];
    const index = items.findIndex((item: any) => item._id === behindId);
    if (index !== -1) {
      items.splice(index + 1, 0, wrapItemWithId());
    } else {
      items.push(wrapItemWithId());
    }
    this.setValue(itemPath, items);
  }

  public removeItem(itemPath: string, _id: string) {
    const item = this.selectItemById(itemPath, _id);
    if (item) {
      item._deleted = true;
      this.mstInstance.remveItemById(itemPath, _id);
      // 如果 所有的数据行都删除了，则添加一个空行
      const items = toJS(this.getValue(itemPath)) || [];
      if(!items.length) {
        this.createItem(itemPath);
      }
    }
  }

  // 根据表单mode，已经编辑行为判断，不在模型上添加字段，简化逻辑
  public itemEditFlag(changedData: Record<string, any> = {}): FormItemEditFlag {
    if (this._deleted) {
      return FormItemEditFlag.Delete;
    }

    if ([FormMode.Create, FormMode.Copy].includes(this.options.formMode)) {
      return FormItemEditFlag.Create;
    }

    if (Object.keys(changedData).length) {
      return FormItemEditFlag.Edit;
    }

    return FormItemEditFlag.None;
  }

  // <! ----- item end -------- >
}
