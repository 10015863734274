import {DataLoader, IDataloaderOptions} from "../dataLoader/dataLoader";
import _get from "lodash/get";
import {LoadingStatus} from "@src/components/LoadingContainer";
import {observable, toJS} from "mobx";
import {fetchFormListData} from "../formData";
import {ValueType} from "@src/bizForm/components/QueryValueType/valueTypeMaps";

export interface IReferDataLoader extends IDataloaderOptions {
  objectTypeId?: string;
}

/**
 * 列表使用
 */
export class ReferDataLoader<T> extends DataLoader {
  public data = observable.array<T>([], {deep: false});

  public pagination: {pageNo: number; pageSize: number; count?: number; hasNextPage?: boolean} = {
    pageNo: 0,
    pageSize: 20,
  };

  // @TODO: 暂时使用objectTypeId， 后续添加一个referPresenter来完成
  private objectTypeId?: string;

  constructor(options: IReferDataLoader) {
    super(options);
    this.objectTypeId = options.objectTypeId;
    this.init();

    this.initQueryBuilder();
  }

  init() {
    this.queryOptions.queryFields = this.options.queryFields || [];
  }

  public async query() {
    if (this.loadingStatus === LoadingStatus.Loading) {
      return;
    }

    if (typeof this.pagination.count !== "undefined" && this.data.length >= this.pagination.count) {
      return;
    }

    let values = [];
    if (this.objectTypeId) {
      values.push({
        path: "objectTypeId",
        valueType: ValueType.eq,
        values: [this.objectTypeId],
      });
    }

    const {entityName} = this.options;
    this.pagination.pageNo++;
    const params = {
      entityName: entityName,
      queryStr: this.queryBuilder.criteriaStr,
      values: values,
      columns: this.queryOptions.queryFields,
      pageSize: this.pagination.pageSize,
      pageNo: this.pagination.pageNo,
      sorts: [
        {
          name: "createTime",
          isDesc: true,
        },
      ],
    };

    try {
      this.loadingStatus = LoadingStatus.Loading;
      const res = await fetchFormListData(params);
      console.log(res);
      //@ts-ignore
      const {data: responseData, success} = res;
      if (success) {
        const {data, ...rest} = responseData;
        this.data.push(...(data ?? []));
        toJS(this.data);
        this.pagination = {
          ...rest,
        };
      }
      this.loadingStatus = LoadingStatus.Complete;
    } catch (err) {
      console.error(err);
      this.loadingStatus = LoadingStatus.Failed;
    }
  }

  public clear() {
    this.data = observable.array([], {deep: false});
    this.pagination = {
      pageNo: 0,
      pageSize: 20,
    };
  }
}
