import {IType} from "mobx-state-tree";
import {EntityField} from "@src/metadata/entity";
import {types} from "./types";
import {metadata} from "@src/metadata/metadata";
import {FieldOptions, MSTForm} from "./MSTForm";
import {ValidatorUtils} from "./validators/validatorUtils";
import {genItemFieldPath, wrapItemWithId} from "./MSTForm-utils";
import _set from "lodash/set";
import {IValidator} from "./validators/interface";
import {FormMode} from "@src/bizForm/formMode";
import {MSTFormItem} from "./interface";

export interface MSTFormHelperOptions {
  formMode: FormMode;
  fields: Array<string>;
  fieldOptions: {[fieldName: string]: FieldOptions}; // 主表和通过fieldName区分
  childFields?: Record<string, Array<string>>;
  childFieldOptions: Record<string, {[fieldName: string]: FieldOptions}>; // 子表都放在这里面，通过fieldName区分
  initValue?: object;
  // validators?: Array<IValidator>; // 检验期后面添加
}

// 格式化数据
// 1: 明细表每行添加 _id，字段
const formatInitValue = (entityName: string, options: MSTFormHelperOptions, initValue: Record<string, any> = {}) => {
  const entity = metadata.getEntity(entityName);
  Object.entries(initValue).forEach(([field, value]) => {
    if (entity.getField(field).isItems) {
      let _value = (value || []).length ? value : [];
      if (options.formMode !== FormMode.View && !_value.length) {
        // 非view模式下，添加空行
        _value.push({});
      }
      initValue[field] = _value.map((item: Record<string, any>[]) => wrapItemWithId(item));
    }
  });
  if ([FormMode.Create, FormMode.Copy, FormMode.Edit].includes(options.formMode)) {
    // 找到initValue上没有的 明细表字段，并添加空行
    Object.keys(options.childFields).forEach((field) => {
      if (entity.getField(field).isItems && !Reflect.has(initValue, field)) {
        initValue[field] = [{}].map((item) => wrapItemWithId(item));
      }
    });
  }

  // 补上 _editFlag，表单是创建/复制模式时，_editFlag设置为Create
  // initValue[FORM_EDIT_FALG_FIELD] = [FormMode.Create, FormMode.Copy].includes(options.mode)
  //   ? FormEditFlag.Create
  //   : FormEditFlag.None;

  return initValue;
};

// MSTForm 实例构建类
export class MSTFormHelper {
  public static createForm<T>(entityName: string, options: MSTFormHelperOptions) {
    const {initValue} = options;
    const entityModel = MSTFormHelper.createFormModel(entityName, options);

    const _formatedValue = formatInitValue(entityName, options, initValue || {});
    const mstModel = entityModel.create(_formatedValue);

    const MSTFormOptions = {
      fieldOptions: options.fieldOptions,
      childFieldOptions: options.childFieldOptions,
      formMode: options.formMode,
      entityName,
      // validators: options.validators,
    };

    const mstForm = MSTForm.create<T>(mstModel, MSTFormOptions);
    MSTFormHelper.addInitValidator(mstForm, options, _formatedValue);

    return mstForm;
  }

  public static createFormModel(entityName: string, options: MSTFormHelperOptions) {
    // master
    const entityModelTypes = MSTFormHelper.createModelTypes(entityName, options.fields);
    // child
    for (const [itemPath, fields] of Object.entries(options.childFields)) {
      const field = metadata.getEntity(entityName).getField(itemPath);
      if (field.isItems && field.referType) {
        const itemEntityModalTypes = MSTFormHelper.createModelTypes(field.referType, fields);

        const itemEntityModal = MSTFormHelper.createModel(field.referType, itemEntityModalTypes);
        entityModelTypes[itemPath] = types.optional(types.array(itemEntityModal), []);
      }
    }

    // form types model
    return MSTFormHelper.createModel(entityName, entityModelTypes);
  }

  public static createModelTypes(entityName: string, fields: Array<string>) {
    const modelTypes: Record<string, IType<any, any, any>> = {};

    const entity = metadata.getEntity(entityName);
    fields.forEach((fieldName) => {
      const entityField = entity.getField(fieldName);
      if (!entityField) {
        console.error(`field '${fieldName}' is not exist with the entity '${entityName}'`);
        return;
      }
      const typeConstructor = MSTFormHelper.createTypeConstructor(entityField);

      modelTypes[fieldName] = typeConstructor;
    });
    // 添加 _id 属性，该属性必须存在
    modelTypes["_id"] = types.optional(types.string, "");
    // id属性
    modelTypes["id"] = types.optional(types.string, "");

    // 所有entity添加添加编辑状态
    // modelTypes[FORM_EDIT_FALG_FIELD] = types.optional(
    //   types.enumeration<FormItemEditFlag>("FormEditFlag", Object.values(FormItemEditFlag)),
    //   FormItemEditFlag.None,
    // );

    return modelTypes;
  }

  public static createModel(entityName: string, modelTypes: Record<string, IType<any, any, any>>) {
    return types
      .model(modelTypes)
      .views((self) => ({
        getValue(path: string) {
          const _pathArr = path.split(".");
          if (_pathArr.length > 1) {
            let entity = metadata.getEntity(entityName);
            return _pathArr.reduce((prev, cur, index) => {
              if (Array.isArray(prev)) {
                // 如果是数组，判断是否为明细表
                const field = entity.getField(_pathArr[index - 1]); //
                if (field.isItems) {
                  return prev.find((item) => item._id === cur); // 明细表的路径是使用_id来拼接的, eg: itempath._id.name
                }
                return prev[cur as unknown as number];
              } else {
                return prev[cur];
              }
            }, self);
          }
          return self[path];
        },
        // 明细获取数据
        getItemValue(itemPath: string, index: number) {
          return self[itemPath]?.[index];
        },
        getItems<T>(itemPath: string): undefined | MSTFormItem<T>[] {
          // 获取所有明细行
          return self[itemPath];
        },
        getItemByUid<T>(itemPath: string, _id: string): undefined | MSTFormItem<T> {
          return self[itemPath]?.find((item: MSTFormItem<T>) => item._id === _id);
        },
        // getViews<T>(itemPath: string): undefined | MSTFormItem<T>[] {
        //   const entity = metadata.getEntity(entityName);
        //   if (!entity || !entity.getField(itemPath).isRefer) {
        //     return undefined;
        //   }
        //   // 获取未删除的明细行
        //   return self[itemPath]?.filter((item: MSTFormItem<T>) => item[FORM_EDIT_FALG_FIELD] !== FormItemEditFlag.Delete);
        // },
      }))
      .actions((self) => ({
        setValue(path: string, value: any) {
          const _pathArr = path.split(".");
          if (_pathArr.length > 1) {
            const _lastPath = _pathArr.pop();
            let entity = metadata.getEntity(entityName);
            let _value = _pathArr.reduce((prev, cur, index) => {
              if (Array.isArray(prev)) {
                // 如果是数组，判断是否为明细表
                const field = entity.getField(_pathArr[index - 1]); //
                if (field.isItems) {
                  return prev.find((item) => item._id === cur); // 明细表的路径是使用_id来拼接的, eg: itempath._id.name
                }
                return prev[cur];
              } else {
                return prev[cur];
              }
            }, self);
            _value[_lastPath] = value;
          } else {
            self[path] = value;
          }
        },
        setItemValue(itemPath: string, index: number, value: any) {
          if (!self[itemPath]) {
            self[itemPath] = [];
          }
          self[itemPath][index] = value;
        },
        remveItemById(itemPath: string, _id: string) {
          if (self[itemPath]) {
            self[itemPath] = self[itemPath].filter((item: any) => item._id !== _id);
          }
        },
      }));
  }

  // 字段属性的类型
  public static createTypeConstructor(field: EntityField) {
    let typeConstructor: IType<any, any, any> = types.optional(types.string, "");

    // if (field.isItems) {
    //   // 明细表
    //   const itemEntityName = field.referType;
    //   if (field.referType) {
    //     MSTFormHelper.createModel(field.referType, {});
    //     typeConstructor = types.array(MSTFormHelper.getPrimitiveType(field.type));
    //   }
    // }

    // 是否为外键
    if (field.isMutil) {
      typeConstructor = types.array(MSTFormHelper.getPrimitiveType(field.type));
    } else if (field.isRefer) {
      // refer
      typeConstructor = MSTFormHelper.getPrimitiveType("refer");
    } else {
      // 其他类型
      typeConstructor = MSTFormHelper.getPrimitiveType(field.type);
    }

    return typeConstructor;
  }

  public static getPrimitiveType(type: string) {
    let typeConstructor: IType<any, any, any> = types.optional(types.string, "");

    switch (type) {
      case "refer":
        typeConstructor = types.refer;
        break;
      case "string":
        typeConstructor = types.optional(types.string, "");
        break;
      case "bool":
        typeConstructor = types.optional(types.boolean, false);
        break;
      case "int64":
      case "uint64":
      case "int32":
      case "uint32":
      case "int8":
      case "uint8":
      case "int":
      case "uint":
        typeConstructor = types.optional(types.integer, 0);
        break;
      case "float":
        typeConstructor = types.optional(types.number, 0);
        break;
      default:
        typeConstructor = types.optional(types.string, ""); // 默认字符串
    }
    return typeConstructor;
  }

  public static addInitValidator<T>(form: MSTForm<T>, options: MSTFormHelperOptions, initValue: Record<string, any>) {
    options.fields.forEach((fieldName) => {
      const field = options.fieldOptions[fieldName];
      if (field && field.initState?.required) {
        MSTFormHelper.addValidator(form, fieldName, ValidatorUtils.required(field.initState.label));
      }
    });
    // options.childFields
    Object.entries(initValue).forEach(([itemPath, value], index) => {
      (options.childFields?.[itemPath] || []).forEach((fieldName) => {
        const field = options.childFieldOptions[itemPath]?.[fieldName];
        if (field && value[index]) {
          const _path = genItemFieldPath(itemPath, value[index]._id, fieldName);
          if (field.initState?.required) {
            MSTFormHelper.addValidator(form, _path, ValidatorUtils.required(field.initState.label)); // 添加required校验
          }
        }
      });
    });
  }

  // 给字段添加校验器
  public static addValidator<T>(form: MSTForm<T>, path: string, callback: IValidator) {
    form.addValidator(path, callback);
  }

  // 给明细添加校验器，每次校验时都要返回当前行的所有数据
  /**
   *
   * @param form
   * @param itemPath
   * @param row : 行数据
   * @param callback
   */
  public static addRowValidtor<T>(form: MSTForm<T>, itemPath: string, row: Record<string, any>, callback: IValidator) {}
}
