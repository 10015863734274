import React from "react";
import Icon from "iconfont-extract-icon";

import { IconBaseProps } from "iconfont-extract-icon/lib/types";
import IconAddSvg from "../../react-svgs/IconAdd";

const IconAdd = React.forwardRef<HTMLSpanElement, IconBaseProps>(
  (props, ref): JSX.Element => {
    return <Icon component={IconAddSvg} ref={ref} {...props} />;
  }
);

export default IconAdd;
