import { IBizAction } from "@src/bizAction/interface";
import {BizListPresenter} from "@src/bizList/bizListPresenter";
/**
 * 列表行使用
 */
export const OpenFavicon: IBizAction = {
  key: "openFavicon",
  label: "查看图标",
  options: {
    onClick: (presenter: BizListPresenter, data?: any) => {
      window.open(`https://favicon.vwood.xyz?domain=${data.domain}`)
    },
  },
};
