/**
 * gql查询条件编译器
 * + 场景：
 *  + 根据选择的字段拼接 查询字符串
 *
 */

import { IQuerySortItem } from "@src/interfaces/queryScheme";
import {metadata} from "@src/metadata/metadata";

interface IQueryBuilder {
  fields: string[];
  entityName: string;
  criteriaStr?: string;
  sorts?: IQuerySortItem[];
  queryCondition?: string[];
}

/**
 * 所有实体的查询逗必须包含id字段
 */
export class QueryBuilder {
  fields: string[];
  entityName: string;
  public criteriaStr?: string;
  public sorts?: IQuerySortItem[];

  constructor(private options: IQueryBuilder) {
    this.entityName = options.entityName;
    this.fields = Array.from(new Set(options.fields)); // 使用[...new Set()]的形式回提示错误
    this.criteriaStr = options.criteriaStr;
    this.sorts = options.sorts;
  }

  // 完整的查询字符串
  public toString() {
    return this.generateGraphqlString();
  }
  // 返回查询条件的对象
  public getQueryObject(): {
    entityName: string;
    conditionString: string;
    fieldsString: string;
  } {
    return {
      entityName: this.entityName,
      conditionString: this.getQueryConditionString(),
      fieldsString: this.parseQueryFields(this.entityName, this.fields).join(" "),
    };
  }

  // 添加查询字段
  public addField(field: string) {
    if (!this.fields.includes(field)) {
      this.fields.push(field);
    }
    return this;
  }

  public setCriteriaStr(criteriaStr: string) {
    this.criteriaStr = criteriaStr;
    return this;
  }

  private getQueryConditionString() {
    const queryCondition: string[] = [...(this.options.queryCondition || [])];
    if (this.criteriaStr) {
      queryCondition.push(`queryStr: "${this.criteriaStr}"`);
    }
    if (this.sorts) {
      queryCondition.push(`sorts: ${this.sorts}`);
    }
    let queryConditionStr = "";
    if (queryCondition.length) {
      queryConditionStr = `(${queryCondition.join(",")})`;
    }

    return queryConditionStr;
  }

  private generateGraphqlString() {
    return `{${this.entityName}${this.getQueryConditionString()}{
        ${this.parseQueryFields(this.entityName, this.fields).join(" ")}
    }}`;
  }

  // 将查询的属性转换成gql query的形式
  private parseQueryFields(entityName: string, queryFields: string[]) {
    const entity = metadata.getEntity(entityName);
    const childrenFields: string[] = []; // 子属性，
    const posterityFields: string[] = []; // 后代属性

    queryFields.forEach((fieldName) => {
      if (fieldName.split(".").length === 1) {
        const field = entity?.getField(fieldName);
        if (field && field.isRefer) {
          // 如果是外键，那么必须有id属性
          fieldName = `${fieldName}.id`;
        }
      }

      if (fieldName.split(".").length > 1) {
        const attrArr = fieldName.split(".");
        if (!childrenFields.includes(attrArr[0])) {
          childrenFields.push(attrArr[0]);
        }
        posterityFields.push(fieldName);
      } else {
        childrenFields.push(fieldName);
      }
    });

    // 外建必须有id、name属性
    if (!childrenFields.includes("id")) {
      childrenFields.push("id");
    }
    if (!childrenFields.includes("name")) {
      childrenFields.push(`name`);
    }

    let result: string[] = [];
    // 转换子节点
    childrenFields.forEach((fieldName) => {
      const field = entity?.getField(fieldName);
      if (field) {
        if (field.isRefer || field.isItems) {
          // 携带字段、外键的处理
          const subFields = posterityFields
            .filter((pFieldName) => pFieldName.startsWith(`${fieldName}.`))
            .map((pFieldName) => {
              return pFieldName.split(".").slice(1).join(".");
            });
          result.push(`${fieldName}{${this.parseQueryFields(field.referType, subFields).join(" ")}}`);
        } else {
          // 普通字段直接塞进数组
          result.push(fieldName);
        }
      }
    });

    return result;
  }
}
