import {http} from './http';

export function fetchMetadata (entityName) {
  return http({
    url: `/metadata/${ entityName }`,
    method: 'get'
  });
}

export function createMetadata (data) {
  return http({
    url: '/metadata',
    method: 'post',
    data
  });
}

export function updateMetadata (data) {
  return http({
    url: '/metadata',
    method: 'put',
    data
  });
}

export function removeMetadata (data) {
  return http({
    url: '/metadata',
    method: 'delete',
    data
  });
}

export function fetchMetadatas () {
  return http({
    url: '/metadata/listByVersion',
    method: 'get'
  });
}
