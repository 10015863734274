import EN_Article from '@packages/gen/types/entities/Article';
import {IBizAction} from '@src/bizAction/interface';
import {BizListPresenter} from '@src/bizList/bizListPresenter';
import {BizListConfig} from '@src/bizList/interface';
import { EmbedCopy } from './listActions/EmbedCopy';
import FormPresenter from './formPresenter';

export default class ListPresenter<T = EN_Article> extends BizListPresenter<T> {

  public bizFormPresenter = FormPresenter;

  public listBizConfig (config: BizListConfig<T>): BizListConfig<T> {
    config.column = {
      name: {
        cellRender: (val) => {
          return <span style={{color: 'red'}}>{val}</span>;
        }
      }
    };

    config.listRowActionManager.use((actions: Array<IBizAction>, data: any): Array<IBizAction> => {
       const result = [
        ...actions,
      ]
      result.splice(2, 0, EmbedCopy)
      return result
    });
    return config;
  }
}
