import {QueryBuilder} from "../queryBuilder";
import { IQuerySortItem } from "@src/interfaces/queryScheme";

/**
 * Dataloader graphql和queryBuilder的基础上封装了查询的逻辑，
 * 将一部分逻辑抽离出来dataloader自己管理；
 * 1. 自动管理分页问题
 * 2. 管理查询状态
 */
export interface IDataloaderQueryOptions {
  queryFields: string[];
  queryStr?: string;
  queryCondition?: string[];
  sorts?: IQuerySortItem[];
}

export interface IDataloaderOptions {
  entityName: string;
  queryOptions: IDataloaderQueryOptions;
}

/**
 * 提供dataLoader的基础逻辑，必须作为父类使用
 */
export class QueryBuilderHelper {
  static create(options: IDataloaderOptions) {
    return new QueryBuilder({
      entityName: options.entityName,
      criteriaStr: options.queryOptions.queryStr,
      fields: options.queryOptions.queryFields,
      queryCondition: options.queryOptions.queryCondition,
      sorts: options.queryOptions.sorts,
    });
  }
}
