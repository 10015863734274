
// FaviconItem来源方式
enum ENUM_FaviconItemSourceMethod {
    
      // 爬虫
      Crawl = "crawl",
    
      // 手动
      Manual = "manual",
    
}
export default ENUM_FaviconItemSourceMethod;
