import {BizFormPresenter} from '@src/bizForm/bizFormPresenter';
import {IMetadata} from '@src/interfaces/metadata';
import {ITemplate, TemplateSection} from '@src/interfaces/template';
import {observable} from 'mobx';
import {DataLoader, IDataloaderOptions} from '../dataLoader/dataLoader';
import {graphql} from '../graphql';

export interface FormDataLoaderOptions extends IDataloaderOptions {
  id: string;
  presenter: BizFormPresenter;
}

// type QueryParams struct {
// 	Query     string                 `json:"query"`
// 	Operation string                 `json:"operation"`
// 	Variables map[string]interface{} `json:"variables"`
// }

export class FormDataLoader extends DataLoader {
  @observable
  public data = {};

  private id: string;

  private presenter: BizFormPresenter;

  constructor (options: FormDataLoaderOptions) {
    super(options);
    this.id = options.id;
    this.presenter = options.presenter;

    this.proccessQueryOptions();

    this.initQueryBuilder();
  }

  private proccessQueryOptions () {
    this.queryOptions = {
      queryFields: this.getTemplatepath(),
      queryStr: this.options.queryStr,
      queryCondition: [ `id: "${ this.id }"` ]
    };
  }

  // 根据元数据整理查询字段
  private getMetadataPath () {
    return this.presenter.metadata.fields.map((item) => item.path);
  }

  // 根据tempalte整理查询字段
  private getTemplatepath () {
    const paths: string[] = [];
    const {master = [], childTables = []} = this.presenter.template || {};
    // 主表
    master.forEach((section: TemplateSection) => {
      section.columns.forEach((col) => {
        paths.push(col.path);
      });
    });

    // 子表
    childTables.forEach((section: TemplateSection) => {
      section.columns.forEach((col) => {
        paths.push(`${ section.path }.${ col.path }`);
      });
    });

    return paths;
  }

  public async query () {
    const _params = {
      query: this.queryBuilder.toString()
    };
    const result = await graphql(_params);
    if (result.success) {
      this.data = result.data ? result.data : {};
    }
  }
}
