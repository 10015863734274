import {FormMode} from "@src/bizForm/formMode";
import {Modals} from "@src/components/Modals";
import {metadata} from "@src/metadata/metadata";
import {createBrowserHistory} from "history";
import AppRouter from ".";

export interface IRouteFormOptions {
  entityName: string;
  mode: FormMode;
  id?: string;
  objectTypeId?: string; // 数据关联的objectType
}

export interface IRouteListOptions {
  entityName: string;
  force?: boolean;
  objectTypeId?: string; // 数据关联的objectType
}

export class RouteUtils {
  // 跳转到另一个表单的路由
  static toForm(options: IRouteFormOptions, opts?: {replace: boolean}) {
    const {entityName, mode, id, objectTypeId} = options;
    const entity = metadata.getEntity(entityName);
    if (entity) {
      let to = `/${entityName}/${mode}${id ? "/" + id : ""}`;
      if (entity.isBaseData && objectTypeId) {
        to = `/base-data/${objectTypeId}/${entityName}/${mode}${id ? "/" + id : ""}`;
      }
      RouteUtils.to(to, opts);
    }
  }

  static to(url: string, opts?: {replace: boolean}) {
    Modals.clear();
    AppRouter.navigate(url, opts);
  }

  // 返回列表页
  static toList(options: IRouteListOptions, opts?: {replace: boolean}) {
    const {entityName, objectTypeId} = options;
    const entity = metadata.getEntity(entityName);
    if (entity) {
      let to = `/${entityName}`;
      if (entity.isBaseData && objectTypeId) {
        to = `/base-data/${objectTypeId}/${entityName}`;
      }
      RouteUtils.to(to, opts);
    }
  }
}
