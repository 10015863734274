import ENTITY_TITLE from "@packages/gen/entityTitle";
import {FormMode, ModeModeLabelMap} from "@src/bizForm/formMode";
import { ENUM_Entity_Article } from "@src/packages/gen/constants/enums/entityName";
import React from "react";
import {RouteObject, redirect} from "react-router-dom";
const Home = React.lazy(() => import(/* webpackChunkName: "home" */"@src/pages/home"));

const Template = React.lazy(() => import(/* webpackChunkName: "template" */ "@src/pages/devtool/bizForm/form/index"));
const TemplateList = React.lazy(() => import(/* webpackChunkName: "template-list" */ "@src/pages/devtool/bizForm/list"));
const QuerySchema = React.lazy(() => import("@src/pages/devtool/querySchema/form/index"));
const QuerySchemaList = React.lazy(() => import("@src/pages/devtool/querySchema/list"));
const Settings = React.lazy(() => import(/* webpackChunkName: "settings" */ "@src/pages/settings"));
const BaseDataList = React.lazy(() => import(/* webpackChunkName: "base-data-list" */"@src/pages/baseData/list"));
const BaseDataForm = React.lazy(() => import(/* webpackChunkName: "base-data-form" */ "@src/pages/baseData/form"));

export const BaseRoutes: RouteObject[] = [
  {
    index: true,
    path: "/",
    Component: Home,
    handle: {
      title: "首页",
    },
    loader: () => {
      return redirect("/" + ENUM_Entity_Article);
    }
  },
  {
    path: "/settings",
    Component: Settings,
    handle: {
      title: "设置",
    },
  },
  {
    path: `/base-data/:objectTypeId/:entityName/:mode/:formId`,
    Component: BaseDataForm,
    handle: {
      title: (params: {entityName: string; objectTypeId?: string; mode?: FormMode}) =>
        `${ModeModeLabelMap[params.mode]}-${ENTITY_TITLE[params.entityName]}`,
    },
  },
  {
    path: `/base-data/:objectTypeId/:entityName/:mode`,
    Component: BaseDataForm,
    handle: {
      title: (params: {entityName: string; objectTypeId?: string; mode?: FormMode}) =>
        `${ModeModeLabelMap[params.mode]}-${ENTITY_TITLE[params.entityName]}`,
    },
  },
  {
    path: `/base-data/:objectTypeId/:entityName`,
    Component: BaseDataList,
    handle: {
      title: (params: {entityName: string; objectTypeId?: string; mode?: FormMode}) =>
        `${ENTITY_TITLE[params.entityName]}`,
    },
  },
  {
    path: `/devtool/querySchema/:entityName/:mode`,
    Component: QuerySchema,
    handle: {
      title: "编辑列表方案",
    },
  },
  {
    path: `/devtool/querySchema`,
    Component: QuerySchemaList,
    handle: {
      title: "列表方案",
    },
  },
  {
    path: `/devtool/form/:entityName/:mode`,
    Component: Template,
    handle: {
      title: "编辑表单配置",
    },
  },
  {
    path: `/devtool/form`,
    Component: TemplateList,
    handle: {
      title: "表单配置列表",
    },
  },
];

export default BaseRoutes;
