// eslint-disable-next-line no-use-before-define
import {useContext, useEffect, useState} from "react";
import {Layout, Menu} from "antd";
import {configList} from "./config";
import styles from "./style.module.scss";
import {RouteUtils} from "@src/router/routeUtils";
import {RouterTabsContext} from "@src/components/RouterTabs/context";
const {Sider} = Layout;

export function Nav() {
  const context = useContext(RouterTabsContext);

  const [selectedKey, setSelectedKey] = useState([]);
  const [collapsed, setCollapsed] = useState(false);

  useEffect(() => {
    const path = context.activeTab?.path;
    if (path) {
      const selectedKeys = [path];
      const pathArr = path.split("/") || [];
      if (pathArr.length > 2) {
        selectedKeys.unshift("/" + pathArr[1]);
      }
      setSelectedKey(selectedKeys);
    } else {
      setSelectedKey([]);
    }
  }, [context.activeTab]);

  useEffect(() => {
    setSelectedKey([window.location.pathname]);
  }, []);

  const onClick = (props: any) => {
    const path = props.keyPath.reverse().join("/");
    if (path === location.pathname) {
      return;
    }
    RouteUtils.to(path);
  };
  return (
    <Sider
      theme="light"
      className={styles["layout-sider"]}
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
    >
      <div className="logo" />
      {/* <Menu selectedKeys={selectedKey} mode="vertical" onClick={onClick}>
        {configList.map((item) =>
          item.children ? (
            <SubMenu key={item.key} icon={item.icon} title={item.label}>
              {item.children.map((subItem) => (
                <Menu.Item key={subItem.key} icon={subItem.icon}>
                  <span>{subItem.label}</span>
                </Menu.Item>
              ))}
            </SubMenu>
          ) : (
            <Menu.Item key={item.key} icon={item.icon}>
              <span>{item.label}</span>
            </Menu.Item>
          ),
        )} */}
      {/* </Menu> */}
      <Menu items={configList} selectedKeys={selectedKey} mode="vertical" onClick={onClick} />
    </Sider>
  );
}
