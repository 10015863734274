import {Table} from "antd";

import React from "react";
import _get from "lodash/get";

import {ColumnProps, ColumnType, TableProps} from "antd/lib/table";

import type {ResizeCallbackData} from "react-resizable";
import OperatorStore from "@src/operator";
import ResizableTableTitle from "@src/components/ResizableTableTitle";
import {useMount} from "let-hooks";
import VirtualTable from "../VirtualTable";

export declare type DataIndex = string | number | readonly (string | number)[];

const BIZ_LIST_COLUMN_OPTIONS = "biz-list-column-options";

export interface BizColumnProps<T> extends ColumnProps<T> {
  fixedWidth?: boolean; //是否固定该列宽度， 设置为true，必须置顶width属性
}

interface Props<T = Record<any, any>> extends Omit<TableProps<T>, "columns"> {
  columns: BizColumnProps<T>[];
  tableId?: string; //表格的id，只有设置了这属性，动态修改宽度才会被保存在本地, 如果tableId发生改变，columnOptions 数据将从本地重新获取（程序认为表格已经是一个新表格）
}
// fixedWidth

const BizTable: React.FC<Props> = (props): JSX.Element => {
  const {columns = [], tableId, ...rest} = props;
  const [loaded, setLoaded] = React.useState(false);
  const storageKey = React.useMemo(() => {
    return BIZ_LIST_COLUMN_OPTIONS + "_" + OperatorStore.getID() + "_" + tableId;
  }, [tableId]);

  const getLocalstorageData = React.useCallback(() => {
    try {
      const data = localStorage.getItem(storageKey);
      return data ? JSON.parse(data) : {};
    } catch {
      return {};
    }
  }, [storageKey]);

  const [columnOptions, setColumnOptions] = React.useState<Record<string, {width?: number}>>(getLocalstorageData);

  const handleResize =
    (dataIndex: string) =>
    (_: React.SyntheticEvent<Element>, {size}: ResizeCallbackData) => {
      const newColumnOptions = {
        ...columnOptions,
        [dataIndex]: {
          ...columnOptions[dataIndex],
          width: size.width,
        },
      };
      setColumnOptions(newColumnOptions);
      if (tableId) {
        localStorage.setItem(storageKey, JSON.stringify(newColumnOptions || {}));
      }
    };

  const bizColumns = React.useMemo(() => {
    const dataIndexToString = (dataIndex: DataIndex) => {
      if (Array.isArray(dataIndex)) {
        return dataIndex.join(".");
      }

      return dataIndex + "";
    };

    return columns.map((col) => {
      const dataIndex = dataIndexToString(col.dataIndex);
      return {
        ...col,
        ellipsis: {
          showTitle: true,
        },
        textWrap: "word-break",
        width: col.fixedWidth ? col.width : columnOptions?.[dataIndex]?.["width"] || 200,
        onHeaderCell: (column: ColumnType<any>) => ({
          width: column.width,
          onResize: handleResize(dataIndex),
        }),
      };
    });
  }, [columns, columnOptions]);

  React.useEffect(() => {
    if (loaded) {
      getLocalstorageData();
    }
  }, [storageKey]);

  useMount(() => {
    setLoaded(true);
  });

  if (props.virtual) {
    return (
      <VirtualTable
        size="small"
        bordered
        {...rest}
        components={{
          header: {
            cell: ResizableTableTitle,
          },
        }}
        columns={bizColumns as any}
        footer={null}
        pagination={false}
      />
    );
  }

  return (
    <Table
      size="small"
      bordered
      {...rest}
      components={{
        header: {
          cell: ResizableTableTitle,
        },
      }}
      columns={bizColumns as any}
    />
  );
};

export default BizTable;
