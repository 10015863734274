import _get from "lodash/get";
// import BizList from "@src/bizList/bizList";
import {BizListPresenter} from "@src/bizList/bizListPresenter";
import {RouteObject} from "react-router-dom";
// import { metadata } from "@src/metadata/metadata";
import ENTITY_TITLE from "@packages/gen/entityTitle";
import React from "react";
const BizList = React.lazy(() => import(/* webpackChunkName: "biz-list" */"@src/bizList/bizList"));

// 通过业务对象生成对应的表单路由
interface IBizListRoute {
  entityName: string;
  path?: string;
  createPresenter: (options?: any) => BizListPresenter;
}
export const bizListRouter = (options: IBizListRoute) => {
  const {entityName, createPresenter, path} = options;
  const routes: RouteObject[] = [
    {
      caseSensitive: true,
      path: path ? path : `/${entityName}`,
      handle: {
        title: `${ENTITY_TITLE[entityName]}列表`
      }
    },
  ];

  return routes.map((route) => ({
    path: route.path,
    caseSensitive: route.caseSensitive,
    handle: route.handle,
    Component: () => <BizList presenter={createPresenter(options) as any}></BizList>
  }));
};
