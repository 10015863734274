import { RouteObject, useParams } from 'react-router-dom';
// import BizForm from '../bizForm/bizForm';
import { BizFormPresenter } from '../bizForm/bizFormPresenter';
import { FormMode } from '../bizForm/formMode';
import _get from 'lodash/get';
import { Observer } from 'mobx-react';
import ENTITY_TITLE from '@packages/gen/entityTitle';
import { useMemo } from 'react';
import React from 'react';
const BizForm = React.lazy(() => import(/* webpackChunkName: "biz-form" */"../bizForm/bizForm"));

// 通过业务对象生成对应的表单路由
interface IBizFormRoute {
  entityName: string;
  createPresenter: (options?: any) => BizFormPresenter;
}
export const bizFormRoute = (options: IBizFormRoute) => {
  const { entityName, createPresenter } = options;
  const routes: Array<RouteObject & { mode: FormMode }> = [
    {
      caseSensitive: true,
      path: `/${entityName}/${FormMode.View}/:formId`,
      mode: FormMode.View,
      handle: {
        title: `查看-${ENTITY_TITLE[entityName]}`
      }
    },
    {
      caseSensitive: true,
      path: `/${entityName}/${FormMode.Copy}/:formId`,
      mode: FormMode.Copy,
      handle: {
        title: `复制-${ENTITY_TITLE[entityName]}`
      }
    },
    {
      caseSensitive: true,
      path: `/${entityName}/${FormMode.Edit}/:formId`,
      mode: FormMode.Edit,
      handle: {
        title: `编辑-${ENTITY_TITLE[entityName]}`
      }
    },
    {
      caseSensitive: true,
      path: `/${entityName}/${FormMode.Create}`,
      mode: FormMode.Create,
      handle: {
        title: `新增-${ENTITY_TITLE[entityName]}`
      }
    }
  ];

  return routes.map((route) => ({
    path: route.path,
    caseSensitive: route.caseSensitive,
    handle: route.handle,
    Component: () => {
      const params = useParams();
      const formId = useMemo(() => params.formId, [])
      const presenter = useMemo(() => createPresenter({
        ...options,
        mode: route.mode
      }) as any, [])

      return (
        <Observer>
          {() => (
            <BizForm
              key={route.path}
              id={formId}
              mode={route.mode}
              presenter={presenter}
            ></BizForm>
          )}
        </Observer>
      );
    }
  }));
};
