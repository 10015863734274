import React from "react";
import Icon from "iconfont-extract-icon";

import { IconBaseProps } from "iconfont-extract-icon/lib/types";
import IconTupianSvg from "../../react-svgs/IconTupian";

const IconTupian = React.forwardRef<HTMLSpanElement, IconBaseProps>(
  (props, ref): JSX.Element => {
    return <Icon component={IconTupianSvg} ref={ref} {...props} />;
  }
);

export default IconTupian;
