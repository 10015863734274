import {getQuerySchemaInput} from '@src/bizForm/components/QueryValueType';
import {ValueType} from '@src/bizForm/components/QueryValueType/valueTypeMaps';
import {ValueTypeSelect} from '@src/bizForm/components/QueryValueType/ValueTypeSelect';
import {QuerySolution} from '@src/bizList/core/querySolution';
import {IQuerySchemaItem} from '@src/interfaces/queryScheme';
import {FieldPathUtils} from '@src/utils/fieldPath';
import {ValueTypeUtils} from '@src/utils/valueTypeUtils';
import {observer} from 'mobx-react';
import React from 'react';
import style from './style.module.scss';

export interface IQuerySchemaList {
  entityName: string;
  querySchemaItems: Array<IQuerySchemaItem>;
  querySolution: QuerySolution;
}

@observer
export class QuerySchemaList extends React.Component<IQuerySchemaList> {
  onChange = (key: string, values: Array<any>) => {};

  onValueTypeChange = (path: string, value: string) => {};

  getBizType = (item: IQuerySchemaItem) => {
    const pathInfo = FieldPathUtils.getPathInfo(this.props.entityName, item.path);
    return pathInfo.bizType;
  };

  getEnumType = (item: IQuerySchemaItem) => {
    const pathInfo = FieldPathUtils.getPathInfo(this.props.entityName, item.path);
    if (pathInfo.bizType === 'enum') {
      return pathInfo.enumType;
    }
    return undefined;
  };

  render () {
    const {querySchemaItems, querySolution, entityName} = this.props;
    return (
      <div className={style[ 'queryAction' ]}>
        {querySchemaItems.map((item) => {
          const querySolutionItem = querySolution.getByPath(item.path);
          const bizType = this.getBizType(item);
          const Comp = getQuerySchemaInput(bizType, querySolutionItem.valueType) as any;
          const value = ValueTypeUtils.isMutil(querySolutionItem.valueType) ?
            querySolutionItem.values :
            querySolutionItem.values[ 0 ];

          const props = {
            value: value,
            fieldName: item.path,
            entityName: entityName,
            querySchemaItems: querySchemaItems,
            onChange: querySolutionItem.updateValues,
            isMutil: [ ValueType.in, ValueType.nin ].includes(querySolutionItem.valueType),
            enumName: this.getEnumType(item)
          };
          return (
            <div className={style[ 'queryAction-item' ]}>
              <div className={style[ 'queryAction-item-label' ]}>{item.displayName}:</div>
              <div className={style[ 'queryAction-item-body' ]}>
                <ValueTypeSelect
                  className={style[ 'queryAction-item-valueType' ]}
                  value={querySolutionItem.valueType}
                  onChange={querySolutionItem.updateValueType}
                  bizType={bizType}
                  path={item.path}
                  entityName={entityName}
                />
                <div className={style[ 'queryAction-item-values' ]}>
                  <Comp {...props} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
