import { RouteObject } from "react-router-dom";
import productRoutes from "./product";
import orderRoutes from "./order";
import articleRoutes from "./article";
import categoryRoutes from "./category";
import templateRoutes from "./template";
import emailRoutes from "./email";
import fileRoutes from "./file";
import workflowRoutes from "./workflow";
import operatorRoutes from "./operator";
import tinyCodeRoutes from "./tinyCode";
import bizTypeRoutes from "./bizType";
import authorRoutes from "./author";
import poetryRoutes from "./poetry";
import faviconRoutes from "./favicon";
import tenantRoutes from "./tenant";
import interviewQuestionRoutes from "./interviewQuestion";
// import {isProd} from "@src/utils";

const CustomeRoutes:RouteObject[] = [
  // ...orderRoutes,
  ...articleRoutes,
  ...categoryRoutes,
  // ...workflowRoutes,
  ...operatorRoutes,
  ...tinyCodeRoutes,
  // ...productRoutes,
  // ...templateRoutes,
  // ...emailRoutes,
  // ...fileRoutes,
  ...bizTypeRoutes,
  ...authorRoutes,
  ...poetryRoutes,
  ...faviconRoutes,
  ...tenantRoutes,
  ...interviewQuestionRoutes,
  // ...(isProd ? [...productRoutes, ...templateRoutes, ...emailRoutes, ...fileRoutes] : []),
]

// 设置首页
// if (articleRoutes[articleRoutes.length - 1]) {
//   const item = articleRoutes[articleRoutes.length - 1]
//   CustomeRoutes.push(<Route {...item} index></Route>)
// }

export default CustomeRoutes;
