import { BizTypeFormPresenter, BizTypeListPresenter } from "@src/pages/bizType";
import { bizFormRoute } from "../bizFormRouter";
import { bizListRouter } from "../bizListRouter";
import { ENUM_Entity_BizType } from "@packages/gen/constants/enums/entityName";

export default [
  ...bizFormRoute({
    entityName: ENUM_Entity_BizType,
    createPresenter: (options: any) => {
      // const { ProductFormPresenter } = await import(
      //   "@src/pages/product/productFormPresenter"
      // );
      // return new ProductFormPresenter(options);

      return new BizTypeFormPresenter(options);
    },
  }),
  ...bizListRouter({
    entityName: ENUM_Entity_BizType,
    createPresenter: (options: any) => {
      return new BizTypeListPresenter(options);
    },
  })
];
