import { IconQuanping, IconTupian} from "@src/icons";
import {EditorTool} from "../types";

const config: EditorTool = {
  id: "fullScreen",
  icon: <IconQuanping />,
  onClick: (options) => {
    const isFullscreen = !!document.fullscreenElement;
    if (isFullscreen) {
      document.exitFullscreen();
    } else {
      options.container.requestFullscreen();
    }
  },
};

export default config;
