import React from 'react';
import './style.scss';

export interface BetweenWrapperProps {
  value: Array<any>;
  onChange: (index: number, val: number) => void;
  component: any;
}

export const BetweenWrapper = ({value, onChange, component: Comp, ...reset}: BetweenWrapperProps) => {
  return (
    <div className="between-wrapper">
      <div className="between-item">
        <Comp {...reset} value={value[0]} onChange={(val: any) => onChange(0, val)} />
      </div>
      <span className="between-item-divider">-</span>
      <div className="between-item">
        <Comp {...reset} value={value[1]} onChange={(val: any) => onChange(1, val)} />
      </div>
    </div>
  );
};
